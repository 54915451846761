import { observer } from 'mobx-react-lite'
import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import Layout from 'shared/layout/Layout/ui/Layout'
import { initPosthog } from 'entities/Posthog'
import { initCanny } from 'entities/Canny'
import { eventHeartbeat } from 'entities/DataLog/events/EventHeartbeatLog'
import { AuthRoutesEnum, authStore } from 'entities/Auth'
import { initIntercom } from 'entities/Intercom'
import { remindersStore } from 'widgets/Reminders'
import { Sidebar } from 'widgets/Sidebar'
import { getNumberModalStore } from 'widgets/GetNumberModal'
import { messagingRegionModalStore } from 'widgets/MessagingRegionModal'
import { connectTwilioModalStore } from 'widgets/connectTwilioModal'
import { getAppData } from 'app/lib/loader'
import LayoutWithSidebar from 'app/layouts/layoutWithSidebar'

type IAuthLayoutVueProps = {
  type?: 'default' | 'sidebar'
}

const AuthLayoutVue: FC<IAuthLayoutVueProps> = observer(({ type }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (!authStore.isLoggedIn) {
      return
    }

    if (!authStore.hasOid) {
      if (pathname.includes('auth/login')) return
      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.registerMultiOrg}`,
      })
      return
    }

    getAppData()
    initPosthog()
    initCanny()
    initIntercom(true)
    getNumberModalStore.init()
    messagingRegionModalStore.init()
    connectTwilioModalStore.init()
    remindersStore.init()
    eventHeartbeat()
  }, [authStore.isLoggedIn])

  if (!authStore.isLoggedIn) return null
  if (type === 'sidebar') {
    return <Sidebar />
  }

  if (type === 'default') {
    return <Layout />
  }

  return <LayoutWithSidebar />
})

export default AuthLayoutVue
