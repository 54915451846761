import { observer } from 'mobx-react-lite'
import { Button, Icon } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBaseView/context/knowledgeBaseViewContext'
import styles from './styles.module.scss'

export const DocumentsTableSearch = observer(() => {
  const { search, onSearchChange, hasSearchParams, resetSearch } = useKnowledgeBaseViewContext()

  return (
    <div className={styles.searchContainer}>
      <Icon icon='search' color='secondary' fontSize={20} padding={6} tertiary />
      <TextField
        className={styles.search}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={onSearchChange}
        value={search}
      />
      {hasSearchParams && (
        <Button
          typeBtn={'text'}
          size={'medium'}
          onClick={resetSearch}
          contained={'secondary'}
          intent={'passive'}
          tabIndex={-1}
          text={'Clear'}
        />
      )}
    </div>
  )
})
