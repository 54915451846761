import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { contactsStore } from 'entities/Contacts'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { Tabs } from './Tabs'
import { Deals } from './Deals/Deals'
import { Organization } from './Organization/Organization'
import { Properties } from './Properties/Properties'
import styles from './styles.module.scss'

const PipedriveIntegration = observer(() => {
  const store = useContactsDetailsContext()
  const [activeTab, setActiveTab] = useState<EnumIntegrationTabs>(
    store.integrationTab || EnumIntegrationTabs.Contact
  )

  const contact = contactsStore.getItem(store.selectedContact?.id)

  const handleActiveTab = (tab: EnumIntegrationTabs) => {
    setActiveTab(tab)
    store.handleIntegrationTab(tab)
  }

  useEffect(() => {
    integrationsStore.fetchIntegrations()
  }, [])

  if (!contact) return null

  const integration = integrationsStore.getIntegration(IntegrationKey.pipedrive)

  if (!integration?.isConnected)
    return (
      <div className={styles.reconnectWrapper}>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'primary'}
          fullWidth
          text='Reconnect'
          onClick={() => {
            uiStore.changeRoute({
              path: '/settings/integrations',
              type: 'vue',
            })
          }}
        />
      </div>
    )

  const loading =
    integrationsStore.contactIntegrationsInfo.loading ||
    integrationsStore.contactIntegrationsInfo.unlinking

  const info =
    integration.key === IntegrationKey.pipedrive
      ? integrationsStore.getContactIntegrationPipedriveInfo(contact.id)
      : null

  if (loading)
    return (
      <div className={classnames(styles.loaderWrap)}>
        <SpinnerLoader size={24} />
      </div>
    )

  const ActiveTab = () => {
    if (!info) return null

    if (activeTab === EnumIntegrationTabs.Deals)
      return <Deals deals={info.deals || []} contact={contact} />
    if (activeTab === EnumIntegrationTabs.Organization)
      return <Organization organization={info.organization} contact={contact} />
    if (activeTab === EnumIntegrationTabs.Contact)
      return <Properties properties={info.properties || null} contact={contact} />

    return null
  }

  return (
    <div className={classnames(styles.wrap)}>
      <Tabs
        handleTab={handleActiveTab}
        activeTab={activeTab}
        count={{
          [EnumIntegrationTabs.Deals]: info?.deals?.length || 0,
        }}
      />
      <ActiveTab />
    </div>
  )
})

export { PipedriveIntegration }
