import { observer } from 'mobx-react-lite'
import { EmptyState, Table } from 'shared/ui'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBaseView/context/knowledgeBaseViewContext'
import { useDocumentsWithActionsRows } from './ui/useDocumentsWithActionsRows'
import { DocumentsBulkActions } from './ui/DocumentsBulkActions'
import { columns } from './ui/columns'
import styles from './styles.module.scss'

export const DocumentsTable = observer(() => {
  const {
    page,
    limit,
    total,
    isKnowledgeBaseDocumentsLoading,
    onPaginationChange,
    isNoSearchResults,
    tableStore,
  } = useKnowledgeBaseViewContext()

  if (isNoSearchResults) {
    return <EmptyState text='No results found' />
  }

  const rows = useDocumentsWithActionsRows()

  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        rows={rows}
        initPage={page}
        limit={limit}
        store={tableStore}
        isLoading={isKnowledgeBaseDocumentsLoading}
        pinnedColumnNames={['name']}
        rowsCount={total}
        onChangePagination={onPaginationChange}
        startPaginationSize={10}
        headerLeftContentProps={{
          actionsContent: <DocumentsBulkActions />,
        }}
        withCheckbox
        withSelectAll
      />
    </div>
  )
})
