import { observer } from 'mobx-react-lite'
import { InputCheckbox, Typography, TextareaWithLabel, Button } from 'shared/ui'
import { Label } from 'shared/ui/Label'

import { links } from 'shared/constants/links'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'

import styles from './styles.module.scss'
import { sampleMessageMaxTextLength, sampleMessageMinTextLength } from '../store/MessagesStore'

export const Messages = observer(() => {
  const { messagesStore, stepLoading } = useLocalNumbersContext()
  const {
    includeEmbeddedLinks,
    includePhoneNumbers,
    includeAgeGatedContent,
    sampleMessage1,
    sampleMessage2,
    isStep2Disabled,
    campaignMessagesNextClick,
    setIncludeEmbeddedLinks,
    setIncludePhoneNumbers,
    setIncludeAgeGatedContent,
    setSampleMessage1,
    setSampleMessage2,
    openSampleMessageModal,
  } = messagesStore

  return (
    <div className={styles.contentWrap}>
      <Typography
        variant='body-md-regular'
        ariaLabel='Business_info_line'
        className={styles.pageDesc}
      >
        Provide 2 examples of messages that you will be sending with this campaign. This sample will
        be used by carriers to help identify your traffic.
        <a
          href={links.localNumbersContainerLearnMoreLink}
          target='_blank'
          rel='noreferrer'
          className={styles.learnMoreLink}
        >
          <Typography
            ariaLabel='TermsAndPrivacy_link'
            variant='body-md-regular'
            color='var(--content-brand-primary)'
            className={styles.learnMoreLink}
          >
            {' Learn more'}
          </Typography>
        </a>
      </Typography>
      <div className={styles.messagesCheckboxesWrap}>
        <Label label={{ text: 'Message contents (select if applicable)' }} />
        <InputCheckbox
          label='Messages will include embedded links.'
          checked={includeEmbeddedLinks}
          onChecked={setIncludeEmbeddedLinks}
          questionTooltipProps={{
            width: 258,
            type: 'description',
            label: 'If checked, make sure to include a link in the sample message.',
            desc: 'Failure to do so may prevent the campaign from being approved.',
          }}
        />
        <InputCheckbox
          label='Messages will include phone numbers.'
          checked={includePhoneNumbers}
          onChecked={setIncludePhoneNumbers}
          questionTooltipProps={{
            width: 258,
            type: 'description',
            label: 'If checked, make sure to include a phone number in the sample message.',
            desc: 'Failure to do so may prevent the campaign from being approved.',
          }}
        />
        <InputCheckbox
          label={
            <div>
              Messages include age-gated content.
              <a
                href={links.CarrierAndCTIAGuidelines}
                target='_blank'
                rel='noreferrer'
                className={styles.guidelinesLink}
              >
                <Typography
                  ariaLabel='TermsAndPrivacy_link'
                  variant='body-md-regular'
                  color='var(--content-brand-primary)'
                >
                  See Carrier and CTIA guidelines
                </Typography>
              </a>
            </div>
          }
          checked={includeAgeGatedContent}
          onChecked={setIncludeAgeGatedContent}
        />
      </div>

      <TextareaWithLabel
        className={styles.sampleMessageDesc}
        value={sampleMessage1}
        limit={sampleMessageMaxTextLength}
        minLimit={sampleMessageMinTextLength}
        errorType='bottomText'
        placeholder='Enter the most common message you send to your contacts'
        onChange={setSampleMessage1}
        labelProps={{
          label: {
            text: 'Sample message #1',
            rightText: 'See example',
            rightNode: (
              <Button
                onClick={openSampleMessageModal}
                text={'See example'}
                size='small'
                typeBtn='informative'
              />
            ),
          },
        }}
      />

      <TextareaWithLabel
        className={styles.sampleMessageDesc}
        value={sampleMessage2}
        limit={sampleMessageMaxTextLength}
        minLimit={sampleMessageMinTextLength}
        errorType='bottomText'
        placeholder='Enter the second most common message you send to your contacts'
        onChange={setSampleMessage2}
        labelProps={{
          label: {
            text: 'Sample message #2',
            rightNode: (
              <Button
                onClick={openSampleMessageModal}
                text={'See example'}
                size='small'
                typeBtn='informative'
              />
            ),
          },
        }}
      />

      <Button
        fullWidth
        loading={stepLoading}
        disabled={isStep2Disabled || stepLoading}
        className={styles.nextButton}
        text='Next'
        onClick={campaignMessagesNextClick}
      />
    </div>
  )
})
