import React from 'react'
import { observer } from 'mobx-react-lite'
import { DocumentsTable } from './DocumentsTable'
import { DocumentsTableSearch } from './DocumentsTableSearch'

export const DocumentsList = observer(() => {
  return (
    <>
      <DocumentsTableSearch />
      <DocumentsTable />
    </>
  )
})
