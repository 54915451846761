import { IMenuItemNavProps } from 'shared/ui/Menu/MenuItem/MenuItemNav/MenuItemNav'
import { Typography } from 'shared/ui'
import { EnumUserRoles } from 'entities/Users'
import { SettingsRoutesEnum } from '../route/type'
import styles from './styles.module.scss'

export const getWorkspaceAllItems = (
  canManageMembers: boolean,
  canManageBillingSettings: boolean,
  canAddSubscription: boolean
): IMenuItemNavProps[] => {
  const items: IMenuItemNavProps[] = [
    {
      type: 'nav',
      to: SettingsRoutesEnum.general,
      label: 'General',
      icon: 'building2',
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.inboxes,
      label: 'Inboxes',
      icon: 'inboxOpen',
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.numbers,
      label: 'Numbers',
      icon: 'phone',
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.teams,
      label: 'Teams',
      icon: 'user2',
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.integrations,
      label: 'Integrations',
      icon: 'plug',
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.compliance,
      label: 'Compliance',
      icon: 'shield',
    },
  ]

  if (canManageMembers) {
    items.splice(1, 0, {
      type: 'nav',
      to: SettingsRoutesEnum.members,
      label: 'Members',
      icon: 'users',
    })
  }

  if (canManageBillingSettings) {
    items.push({
      type: 'nav',
      to: SettingsRoutesEnum.planAndBilling,
      label: 'Plan & Billing',
      icon: 'card',
    })
  }

  if (canAddSubscription) {
    items.push({
      type: 'nav',
      to: SettingsRoutesEnum.addSubscription,
      label: 'Add subscription',
      icon: 'card',
    })
  }

  return items
}

export const getProductAllItems = (): IMenuItemNavProps[] => [
  {
    type: 'nav',
    to: SettingsRoutesEnum.tags,
    label: 'Tags',
    icon: 'tag',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.customFields,
    label: 'Custom Fields',
    icon: 'textInput2',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.urlShortener,
    label: 'URL Shortener',
    icon: 'link',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.websiteChatWidget,
    label: 'Website Chat Widget',
    icon: 'messageTextCircle02',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.knowledgeBase,
    label: (
      <Typography
        variant={'body-md-regular'}
        className={styles.knowledgeBaseLabel}
        ariaLabel={'AI knowledge base'}
      >
        AI knowledge base <span className={styles.badge}>Beta</span>
      </Typography>
    ),
    icon: 'bulb',
  },
]

export const getPersonalAllItems = (): IMenuItemNavProps[] => [
  {
    type: 'nav',
    to: SettingsRoutesEnum.account,
    label: 'Account',
    icon: 'userFilled',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.referrals,
    label: 'Referrals',
    icon: 'dollar',
  },
]

export const getDeveloperItems = (): IMenuItemNavProps[] => [
  {
    type: 'nav',
    to: SettingsRoutesEnum.oauthApplications,
    label: 'OAuth Applications',
    icon: 'container',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.personalAccessTokens,
    label: 'Personal Access Tokens',
    icon: 'container',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.publicApiTokens,
    label: 'Public API Tokens',
    icon: 'container',
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.authorizedApps,
    label: 'Authorized Apps',
    icon: 'container',
  },
]

export const workspaceRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.integrations],
  member: [SettingsRoutesEnum.inboxes, SettingsRoutesEnum.numbers, SettingsRoutesEnum.integrations],
  manager: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.inboxes,
    SettingsRoutesEnum.numbers,
    SettingsRoutesEnum.integrations,
  ],
  admin: [],
  owner: [],
}

export const canceledWorkspaceRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.integrations],
  member: [SettingsRoutesEnum.inboxes, SettingsRoutesEnum.numbers, SettingsRoutesEnum.integrations],
  manager: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.inboxes,
    SettingsRoutesEnum.numbers,
    SettingsRoutesEnum.integrations,
  ],
  admin: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.compliance,
    SettingsRoutesEnum.addSubscription,
  ],
  owner: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.compliance,
    SettingsRoutesEnum.addSubscription,
  ],
}

export const productRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  member: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  manager: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  admin: [],
  owner: [],
}

export const canceledProductRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  member: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  manager: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  admin: [SettingsRoutesEnum.urlShortener, SettingsRoutesEnum.websiteChatWidget],
  owner: [SettingsRoutesEnum.urlShortener, SettingsRoutesEnum.websiteChatWidget],
}

export const personalRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.account],
  member: [SettingsRoutesEnum.account],
  manager: [SettingsRoutesEnum.account],
  admin: [],
  owner: [],
}

export const canceledPersonalRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.account],
  member: [SettingsRoutesEnum.account],
  manager: [SettingsRoutesEnum.account],
  admin: [SettingsRoutesEnum.account, SettingsRoutesEnum.referrals],
  owner: [SettingsRoutesEnum.account, SettingsRoutesEnum.referrals],
}

export const developerRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [],
  member: [],
  manager: [],
  admin: [],
  owner: [],
}

export const canceledDeveloperRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  member: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  manager: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  admin: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  owner: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
}

const reachItems = (items: IMenuItemNavProps[]) =>
  items.map((item) => ({
    ...item,
    to: `/${SettingsRoutesEnum.root}/${item.to}`,
    checkActive: (pathname: string) => pathname.includes(item.to),
  }))

export const getMenuItemsForRole = (
  role: EnumUserRoles,
  items: IMenuItemNavProps[],
  roleMapping: Record<EnumUserRoles, string[]>
): IMenuItemNavProps[] => {
  const allowedRoutes = roleMapping[role]

  const filteredItems = items.filter((item) => {
    return allowedRoutes.includes(item.to)
  })

  if (allowedRoutes.length === 0) return reachItems(items)

  return reachItems(filteredItems)
}
