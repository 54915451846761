import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { ILocalNumbersRoutesEnum } from '../types'

export class ForbiddenMessageStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _forbiddenMessageAgreed = false

  get forbiddenMessageAgreed() {
    return this._forbiddenMessageAgreed
  }

  get isNextStepDisabled() {
    return !this._forbiddenMessageAgreed
  }

  setForbiddenMessageAgreed = () => {
    this._forbiddenMessageAgreed = !this._forbiddenMessageAgreed
  }

  increaseStep = () => {
    uiStore.changeRoute({
      path: `/settings/organization/compliance/local-numbers/${ILocalNumbersRoutesEnum.package}`,
    })
  }
}
