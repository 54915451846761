import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import { Avatar, IconButton, Typography } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { CallPeople } from 'entities/Call/model/CallPeople'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

type ICallListItemProps = {
  search?: string
  item: CallPeople
  onChange?: (item: CallPeople) => void
}

export const CallListItem: React.FC<ICallListItemProps> = observer(
  ({ search = '', item, onChange }) => {
    const store = useCallPopUpDefaultContext()
    const { innerRefTooltipCurrent, handleChangeHoldParticipant, handleDeleteParticipant } =
      store as CallPopUpDefaultStore

    return (
      <div
        key={item.id}
        className={classnames(styles.listItem, {
          [styles['listItem--disabled']]: item.disabled,
        })}
        onClick={() => {
          if (item.disabled) return

          onChange?.(item)
        }}
      >
        <div className={styles.listItem__left}>
          <div
            className={classnames(styles.listItem__icon, {
              [styles['listItem__icon--rect']]: item.type === 'number',
            })}
          >
            <Avatar {...item.avatarProps} />
          </div>
          <div className={styles.listItem__info}>
            <div className={styles.listItem__title}>
              <Typography
                ellipsis
                tag={'div'}
                ariaLabel='callItemName'
                tooltipProps={{ label: item.name, zIndex: 5001 }}
              >
                <Highlighter
                  highlightClassName={styles.highlighter}
                  searchWords={[search]}
                  autoEscape={true}
                  textToHighlight={item.name || ''}
                />
              </Typography>
            </div>
            {item.label && (
              <div
                className={classnames(styles.listItem__number, {
                  [styles.listItem__hold]: item.isHold,
                })}
              >
                <Highlighter
                  highlightClassName={styles.highlighter}
                  searchWords={[search]}
                  autoEscape={true}
                  textToHighlight={
                    (item.label && getLabelAsNumberInternationalFormat(item.label)) || ''
                  }
                />
              </div>
            )}
          </div>
        </div>

        {item.is_participant && item.status !== 'calling' && (
          <div className={styles.actions}>
            <div className={styles.action}>
              <IconButton
                iconProps={{
                  fontSize: 16,
                  icon: item.isHold ? 'play1' : 'pause1',
                }}
                tooltipProps={{
                  zIndex: 5001,
                  label: item.isHold ? 'Resume' : 'Put on hold',
                  placement: 'top',
                  PopperProps: {
                    popperOptions: {
                      modifiers: [
                        {
                          name: 'preventOverflow',
                          options: {
                            boundary: innerRefTooltipCurrent,
                          },
                        },
                      ],
                    },
                  },
                }}
                loading={item.isHoldLoading}
                disabled={item.isHoldLoading || item.isHoldDisabled}
                spinnerLoaderProps={{
                  determinatecolor: 'var(--gray-30)',
                  indeterminatecolor: 'var(--gray-70)',
                }}
                variant={'icon'}
                color={'secondary'}
                size={'small'}
                ariaLabel={`CallListItem_${item.isHold ? 'resume' : 'putOnHold'}`}
                onClick={() => {
                  handleChangeHoldParticipant(item)
                }}
              />
            </div>

            <div className={styles.action}>
              <IconButton
                iconProps={{
                  fontSize: 16,
                  icon: 'phoneHangUp',
                }}
                tooltipProps={{
                  label: 'Remove from call',
                  zIndex: 5001,
                  placement: 'top',
                  PopperProps: {
                    popperOptions: {
                      modifiers: [
                        {
                          name: 'preventOverflow',
                          options: {
                            boundary: innerRefTooltipCurrent,
                          },
                        },
                      ],
                    },
                  },
                }}
                loading={item.isDeleteLoading}
                disabled={item.isDeleteLoading}
                spinnerLoaderProps={{
                  determinatecolor: 'var(--gray-30)',
                  indeterminatecolor: 'var(--gray-70)',
                }}
                variant={'icon'}
                color={'secondary'}
                size={'small'}
                onClick={() => {
                  handleDeleteParticipant(item)
                }}
                ariaLabel='CallListItem_removeFromCall'
              />
            </div>
          </div>
        )}
      </div>
    )
  }
)
