import classNames from 'classnames'
import { KeyboardEvent } from 'react'
import { Label } from '../Label'
import {
  makeInputPattern,
  makeTextFieldElement,
  makeTextFieldHighlight,
  makeTextFieldIcon,
  TextField,
  TextFieldPlaceholder,
} from '../TextField'
import styles from './styles.module.scss'
import { Dropdown, IDropdownItem } from '../Dropdown'
import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { Error } from '../Error'
import type { ITooltipProps } from '../Tooltip'

const InputPhone = makeInputPattern({
  format: '(###) ###-####',
  placeholder: '(555) 800-8000',
})

interface IPhoneWithLabelInputProps {
  items: IDropdownItem[]
  number: string
  loading?: boolean
  numberError: string | null
  item: IDropdownItem
  numberRequestError: string | null
  isValidNumber: boolean
  label?: string
  questionTooltipProps?: ITooltipProps
  triggerNumberValidation: () => void
  setNumber: (value: string) => void
  setCountry: (item: IDropdownItem, child?: IDropdownItem) => void
}

export const PhoneWithLabelInput = ({
  items,
  numberError,
  numberRequestError,
  number,
  loading = false,
  item,
  label = 'Mobile phone number',
  isValidNumber,
  questionTooltipProps,
  setCountry,
  setNumber,
  triggerNumberValidation,
}: IPhoneWithLabelInputProps) => {
  const onKeyDownNumber = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerNumberValidation()
      if (isValidNumber && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }
  return (
    <>
      <Label
        label={{
          text: label,
          className: styles.inputLabel,
        }}
        questionTooltipProps={questionTooltipProps}
      />
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: numberError || numberRequestError,
        })}
        Input={InputPhone}
        InputProps={{ onKeyDown: onKeyDownNumber }}
        error={!!numberRequestError}
        variant='stroke'
        size='medium'
        value={number}
        disabled={loading}
        onChange={setNumber}
        onBlur={triggerNumberValidation}
        leftActions={[
          makeTextFieldElement({
            element: (
              <div className={styles.leftContentNumber}>
                <Dropdown
                  placement={'bottom-start'}
                  width={191}
                  margin={-28}
                  marginLeft={-12}
                  items={items}
                  onChange={setCountry}
                  triggerComponent={() => (
                    <TextFieldPlaceholder
                      rightActions={[
                        makeTextFieldIcon({
                          icon: 'chevronDown',
                        }),
                      ]}
                      placeholder={<Icon icon={item.iconL} />}
                      variant={'integrated'}
                      size={'small'}
                      className={styles.countryPicker}
                    />
                  )}
                />
                <div className={styles.verticalDivider}></div>
                <Typography variant={'text-input-field'} ariaLabel={'numberPrefix'}>
                  +1
                </Typography>
              </div>
            ),
          }),
        ]}
        mainActions={[makeTextFieldHighlight({ mode: 'error', highlight: !!numberError })]}
      />
      <Error type={'text'} error={numberError || numberRequestError} />
    </>
  )
}
