// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fq965OVwQOt3bkashZWk{padding:0 44px;overflow-y:auto;overflow-x:hidden}.fOECjYEd0CySsI6SxbsL{width:620px;margin:0 auto}@media(min-width: 992px){.fOECjYEd0CySsI6SxbsL{width:760px}}.fOECjYEd0CySsI6SxbsL header{display:flex;justify-content:space-between;align-items:center;padding:24px 20px 0}.oAwesb0V9b0Y2WdokAcm{display:flex;flex-grow:1;flex-direction:column;margin-left:16px;margin-right:20px}.Z1pxRQGGQvAi1ToaC16y{display:flex;align-items:center;gap:8px}.gwKNuEkfA4jN9PQgZvTS{display:flex;gap:8px}.qR42BhmMbfjS6Z1jNQ7G{text-decoration:none}.vZ90UfEdCe2cAdmSHQ0o{padding:32px 20px 16px;margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/layout/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,aAAA,CAEA,yBAJF,sBAKI,WAAA,CAAA,CAGF,6BACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,mBAAA,CAIJ,sBACE,YAAA,CACA,WAAA,CACA,qBAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,oBAAA,CAGF,sBACE,sBAAA,CACA,iBAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\n.container {\n  padding: 0 44px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.content {\n  width: 620px;\n  margin: 0 auto;\n\n  @media (min-width: $breakpoint-s) {\n    width: 760px;\n  }\n\n  header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 24px 20px 0;\n  }\n}\n\n.integrationDetailsHeader {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  margin-left: 16px;\n  margin-right: 20px;\n}\n\n.title {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.actions {\n  display: flex;\n  gap: 8px;\n}\n\n.docsButton {\n  text-decoration: none;\n}\n\n.navBar {\n  padding: 32px 20px 16px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "fq965OVwQOt3bkashZWk",
	"content": "fOECjYEd0CySsI6SxbsL",
	"integrationDetailsHeader": "oAwesb0V9b0Y2WdokAcm",
	"title": "Z1pxRQGGQvAi1ToaC16y",
	"actions": "gwKNuEkfA4jN9PQgZvTS",
	"docsButton": "qR42BhmMbfjS6Z1jNQ7G",
	"navBar": "vZ90UfEdCe2cAdmSHQ0o"
};
export default ___CSS_LOADER_EXPORT___;
