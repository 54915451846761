import { makeAutoObservable, runInAction, reaction } from 'mobx'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { numbersStore } from 'entities/Phone'
import { organizationStore } from 'entities/Organization'
import { IntegrationsApi } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { ConnectTwilioModalContent, ConnectTwilioModalActions } from 'widgets/connectTwilioModal'

export class ConnectTwilioModalStore {
  idModal = 'connectTwilioModal'
  accountSid = ''
  isPrefilled = false
  authToken = ''
  error: string | null = null
  loading = false

  constructor() {
    makeAutoObservable(this)

    this.reactionInit()
    this.reactionChange()
  }

  get hasError() {
    return Boolean(this.error)
  }

  get disabled() {
    return !this.accountSid || !this.authToken || this.hasError
  }

  setAccountSid = (accountSid: string) => {
    this.accountSid = accountSid
  }

  setAuthToken = (authToken: string) => {
    this.authToken = authToken
  }

  init = async () => {
    const accountSid = new URLSearchParams(window.location.search).get('accountSid')
    if (accountSid) {
      this.accountSid = accountSid
      this.isPrefilled = true
    }

    await Promise.all([
      usersStore.initPromise,
      organizationStore.initPromise,
      numbersStore.initFetchOrganizationNumberPromise,
      numbersStore.initFetchNumberVendorIntegrationsPromise,
    ])

    if (organizationStore.isTwilioNumberVendor && !numbersStore.hasTwilioNumberVendorIntegration) {
      modalStore.addModal({
        id: this.idModal,
        showCloseIcon: false,
        showCloseButton: false,
        disabledOnAllClose: true,
        disabledOverlayClose: true,
        disabledOnEscClose: true,
        title: 'Twilio integration',
        paddingContent: '8px 24px 0 24px',
        width: 480,
        ModalActions: ConnectTwilioModalActions,
        ModalContent: ConnectTwilioModalContent,
        onClose: this.closeModal,
      })
    } else {
      this.reset()
      this.closeModal()
    }
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this.idModal)
  }

  reset = () => {
    this.authToken = ''
    this.accountSid = ''
    this.error = null
    this.loading = false
  }

  handleSubmit = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      await IntegrationsApi.connectTwilio({
        account_sid: this.accountSid,
        auth_token: this.authToken,
      })

      if (window.StoreVue.refetchMe) await window.StoreVue.refetchMe()
      await Promise.all([organizationStore.init(), numbersStore.fetchNumberVendorIntegrations()])

      this.closeModal()
      uiStore.changeRoute({
        path: '/settings/organization/numbers?unassigned',
        type: 'vue',
      })
    } catch (e) {
      console.error(e)
      if (e instanceof AxiosError) {
        this.error = e.response?.data?.error || 'Something went wrong, please try again.'
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  reactionInit = () => {
    reaction(
      () => [
        organizationStore.isTwilioNumberVendor,
        numbersStore.hasTwilioNumberVendorIntegration,
        numbersStore.loading,
        organizationStore.loading,
      ],
      () => {
        this.init()
      }
    )
  }

  reactionChange = () => {
    reaction(
      () => [this.accountSid, this.authToken],
      () => {
        this.error = null
      }
    )
  }
}

export const connectTwilioModalStore = new ConnectTwilioModalStore()
