import type { ActionItem, IActionsProps } from 'shared/ui'
import type { KnowledgeBaseDocument } from 'entities/KnowledgeBase/model/KnowledgeBaseDocument'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBaseView/context/knowledgeBaseViewContext'

type IDocumentWithActionsRow = KnowledgeBaseDocument & {
  actionsProps: IActionsProps
}

type IUseDocumentsWithActionsRows = () => IDocumentWithActionsRow[]

export const useDocumentsWithActionsRows: IUseDocumentsWithActionsRows = () => {
  const { documents, onDeleteDocuments, onDownloadDocument } = useKnowledgeBaseViewContext()

  const makeDownloadAction = (item: KnowledgeBaseDocument): ActionItem | null => {
    return {
      icon: 'download',
      text: 'Download',
      onAction: () => onDownloadDocument(item.id),
    }
  }

  const makeDeleteAction = (item: KnowledgeBaseDocument): ActionItem | null => {
    return {
      icon: 'delete',
      text: 'Delete',
      onAction: () => onDeleteDocuments([item.id]),
    }
  }

  return documents.map((item) => {
    const actions = [makeDownloadAction(item), makeDeleteAction(item)].filter(
      Boolean
    ) as ActionItem[]

    return { ...item, actionsProps: { actions } }
  })
}
