import { observer } from 'mobx-react-lite'

import classNames from 'classnames'
import { ReactNode } from 'react'
import optInOnlineFormRejected from 'shared/assets/images/optInOnlineFormRejected.png'
import { Alert, Button, ButtonCopy, Icon, Typography, UploadDoc } from 'shared/ui'
import optInOnlineFormApproved from 'shared/assets/images/optInOnlineFormApproved.png'
import { getCDNAssetURL } from 'shared/lib'
import styles from './styles.module.scss'

interface IBlockInfo {
  title: string
  desc: string
  copyTitle?: string
  copyText: string
}

interface IOptInConsentProps {
  children?: ReactNode
  stepLoading: boolean
  isNextDisabled: boolean
  attachmentError: { title?: string; desc?: string } | null
  firstBlock: IBlockInfo
  secondBlock: IBlockInfo
  increaseStep: () => void
  clearAttachmentError: () => void
  seeApprovedExampleClick: () => void
  approvedExampleClick: () => void
  rejectedExampleClick: () => void
  uploadOptInImageAttachment: (files: File[]) => void
}

export const OptInConsent = observer(
  ({
    children,
    stepLoading,
    isNextDisabled,
    attachmentError,
    firstBlock,
    secondBlock,
    increaseStep,
    clearAttachmentError,
    seeApprovedExampleClick,
    approvedExampleClick,
    rejectedExampleClick,
    uploadOptInImageAttachment,
  }: IOptInConsentProps) => {
    return (
      <div>
        <div style={{ paddingLeft: '50px' }}>
          <Alert
            item={{
              type: 'infoBlue',
              title: 'Did you know?',
              desc: 'Most registration rejections occur due to incorrect information submitted in the “Opt-in consent” step.',
            }}
            rightAction={
              <Button
                text='Learn more'
                typeBtn='outlined'
                size='small'
                contained='secondary'
                className={styles.learnMore}
              />
            }
          />

          <div className={styles.infoBlock}>
            <div className={styles.blockNumber}>1</div>
            <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
              {firstBlock.title}
            </Typography>
            <Typography
              ariaLabel='infoBlockTitle'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
            >
              {firstBlock.desc}
            </Typography>

            <div className={styles.copyBlock}>
              <div className={styles.copyBlockLine}>
                <Typography ariaLabel='infoBlockTitle' variant='body-md-semibold'>
                  {firstBlock.copyTitle}
                </Typography>
                <Button
                  text='See approved example'
                  size='small'
                  typeBtn='informative'
                  onClick={seeApprovedExampleClick}
                />
              </div>
              <Typography
                ariaLabel='infoBlockTitle'
                variant='body-md-regular'
                color='var(--content-primary-secondary)'
              >
                {firstBlock.copyText}
              </Typography>

              <ButtonCopy value={firstBlock.copyText} />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.blockNumber}>2</div>
            <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
              {secondBlock.title}
            </Typography>
            <Typography
              ariaLabel='infoBlockTitle'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
            >
              {secondBlock.desc}
            </Typography>

            <div className={styles.copyBlock}>
              <Typography
                ariaLabel='infoBlockTitle'
                variant='body-md-regular'
                color='var(--content-primary-secondary)'
              >
                {secondBlock.copyText}
              </Typography>

              <ButtonCopy value={secondBlock.copyText} />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.blockNumber}>3</div>
            <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
              Upload your opt-in image
            </Typography>

            <Typography
              ariaLabel='infoBlockTitle'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
            >
              Provide an image of the opt-in process where the customer entered their phone number
              and agreed to receive SMS. Ensure it meets the above criteria.
            </Typography>

            <div className={styles.imagesWrap}>
              <div className={styles.imageBlock}>
                <div className={classNames(styles.conditionField, styles.conditionFieldRejected)}>
                  <Icon
                    icon='cancelFilled'
                    color='var(--action-outlined-secondary-normal)'
                    fontSize={18}
                  />
                  <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
                    Rejected example
                  </Typography>
                </div>
                <img
                  src={getCDNAssetURL(optInOnlineFormRejected)}
                  alt='optInOnlineFormRejected'
                  aria-hidden={true}
                  className={styles.image}
                  onClick={approvedExampleClick}
                />
              </div>
              <div className={styles.imageBlock}>
                <div className={classNames(styles.conditionField, styles.conditionFieldApproved)}>
                  <Icon
                    icon='cancelFilled'
                    color='var(--action-outlined-secondary-normal)'
                    fontSize={18}
                  />
                  <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
                    Approved example
                  </Typography>
                </div>
                <img
                  src={getCDNAssetURL(optInOnlineFormApproved)}
                  aria-hidden={true}
                  alt='optInOnlineFormApproved'
                  className={styles.image}
                  onClick={rejectedExampleClick}
                />
              </div>
            </div>

            {attachmentError && (
              <Alert
                item={{ title: attachmentError.title, desc: attachmentError.desc, type: 'error' }}
                onClose={clearAttachmentError}
                marginBottom={16}
              />
            )}

            <UploadDoc
              uploadTypeText='Acceptable file types: pdf, jpg, png'
              onChange={(files) => uploadOptInImageAttachment(files as File[])}
            />
          </div>
          {children}
          <Button
            fullWidth
            loading={stepLoading}
            disabled={isNextDisabled || stepLoading}
            className={styles.nextButton}
            text='Next'
            onClick={increaseStep}
          />
        </div>
      </div>
    )
  }
)
