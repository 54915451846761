import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { IAlertProps } from 'shared/ui/Alert/types'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'

type ITrialAlertTenLimitConversationsProps = Partial<IAlertProps> & {
  onCloseTrialAlert: () => void
}

export const TrialAlertTenLimitConversations: FC<ITrialAlertTenLimitConversationsProps> = observer(
  (props) => {
    if (!subscriptionStore.isTrial) {
      return null
    }
    if (usersStore.user?.isAdminOrOwnerRole) {
      return (
        <Alert
          item={{
            type: 'infoLight',
            title: 'During trial, you are limited to 10 conversations',
            desc: 'To start a new conversation, please upgrade',
          }}
          rightAction={
            <Button
              text={'Upgrade'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={() =>
                uiStore.changeRoute({
                  path: '/settings/billing/overview/upgrade',
                  type: 'vue',
                })
              }
              data-type={'upgrade-button'}
            />
          }
          {...props}
        />
      )
    }

    return (
      <Alert
        item={{
          type: 'infoLight',
          title: 'During trial, you are limited to 10 conversations',
          desc: 'To start a new conversation, please ask one of your admins to upgrade the account',
        }}
        onClose={props.onCloseTrialAlert}
        {...props}
      />
    )
  }
)
