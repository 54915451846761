import type { AxiosResponse } from 'axios'
import type { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type { IResponseFilters } from 'entities/Contacts/api/filterTypes'
import type { IParamsGetKeywords, IResponseGetKeywords } from './type'

class Api {
  getKeywords(
    params: IParamsGetKeywords,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetKeywords>> {
    return baseApi.post('keywords/all', params, { ...options })
  }

  getFilters(): Promise<AxiosResponse<IResponseFilters>> {
    return baseApi.get('keywords/filters')
  }
}

export const KeywordsApi = new Api()
