import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { ReactNode } from 'react'
import { dropDownStore, IDropdownItem, IIconsVector } from 'shared/ui'
import { links } from 'shared/constants/links'
import { numbersStore } from 'entities/Phone'
import { InboxesApi } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { Phone } from 'entities/Phone/model/Phone'
import { usersStore } from 'entities/Users'
import { PhoneShortCode } from 'entities/Phone/model/PhoneShortCode'
import { IParamsSendFrom, IParamsSendFromType } from 'entities/Inbox/api/types'
import type { Integration } from 'entities/Integrations/model/Integration'
import { IIntegrationKey, IntegrationsApi } from 'entities/Integrations'
import { SendFromScaleLabel } from '../ui/SendFromScaleLabel'
import {
  IDropdownItemSendFrom,
  IDropdownItemSendFromId,
  ISendFromGroup,
  ISendFromGroupKey,
} from './types'

type SendFromDropdownStoreProps = {
  clearError?: () => void
}

export class SendFromDropdownStore {
  private _name = ''
  private _label = ''
  private _search = ''
  private _loading = true
  private _error: ReactNode = null
  private _onChange: ((params: IParamsSendFrom) => void) | null = null
  private _dropdownId: string | null = null
  private _inboxesDropdownMap: Map<number, IDropdownItem> = new Map()
  private _shortcodeDropdownMap: Map<number, IDropdownItemSendFrom> = new Map()
  private _integrationSenders: IDropdownItem[] = []
  private _integrationSenderKey: IIntegrationKey | null = null

  private _iconField: IIconsVector = 'inboxOpen'
  private _numberType: IParamsSendFromType | number | null = null
  private _ownerProperty: string | null = null
  private _sendFrom: IParamsSendFrom | null = null
  private _teamId: number | null = null
  private _isAircall = false

  private _disposeParams: IReactionDisposer | null = null
  clearError: (() => void) | null

  constructor({ clearError }: SendFromDropdownStoreProps) {
    makeAutoObservable(this)

    this.clearError = clearError || null
    this.reactionParams()
  }

  reactionParams = () => {
    this._disposeParams?.()
    this._disposeParams = reaction(
      () => this.params,
      (params) => {
        if (params) {
          this._onChange?.(params)
        }
      }
    )
  }

  init = async (params?: IParamsSendFrom | null) => {
    runInAction(() => {
      this._loading = true
      if (params) {
        this._sendFrom = params
      }
    })

    await Promise.all([this.fetchShortCodes(), this.fetchAllInboxes()])

    this.initValue()

    runInAction(() => {
      this._loading = false
    })
  }

  initValue = () => {
    if (!this._sendFrom) return
    if (this._sendFrom.type === 'from_integration') {
      const integrationSenderItem = this._integrationSenders[0]
      if (this._integrationSenderKey === 'infusionsoft') {
        this.setActive({
          numberId: 0,
          typeId: this._integrationSenderKey,
          name: integrationSenderItem.label,
        })
      }
      if (this._integrationSenderKey === 'hubspot') {
        const hubspotOwnerProperty = integrationSenderItem?.children?.find(
          (child) => child.id === this._sendFrom?.options.owner_property
        )

        this.setActive({
          numberId: 0,
          typeId: this._integrationSenderKey,
          name: integrationSenderItem.label,
          owner_property: hubspotOwnerProperty?.value,
          label: hubspotOwnerProperty?.label,
        })
      }
      return
    }

    const number_id = this._sendFrom.options.number_id
    const inbox = this.getInboxByNumber(number_id)
    const teamId = inbox?.id || this._sendFrom.options.team_id
    const smartOption = this._sendFrom.options.smart_option

    this.setActive({
      typeId: smartOption || number_id,
      numberId: number_id,
      teamId: teamId,
    })
  }

  reset = () => {
    this._disposeParams?.()
  }

  fetchAllInboxes = async () => {
    try {
      const { data } = await InboxesApi.getTeamsAll()

      data.forEach((item) => {
        numbersStore.addItems(item.numbers)

        const isShortCode = item.numbers
          .map((number) => new Phone(number))
          .some((number) => number.isShortCode)
        const isStatusVerifiedNumbers = item.numbers.filter((item) => {
          const number = new Phone(item)

          return !(number.isTollFree && !number.isStatusVerified)
        }).length

        if (!isStatusVerifiedNumbers) return
        if (isShortCode) return

        this.addItemInboxDropdown(new Inbox(item))
      })
    } catch (e) {
      console.error(e)
    }
  }

  fetchShortCodes = async () => {
    try {
      await numbersStore.fetchNumbersShortCodes()

      numbersStore.itemsShortCodes.forEach((item) => {
        this.addItemPhoneDropdown(item)
      })
    } catch (e) {
      console.error(e)
    }
  }

  updateIntegrationSenders = async (integration?: Integration) => {
    this._integrationSenders = []
    if (this._numberType === 0) {
      this._numberType = null
      this._ownerProperty = ''
      this._name = ''
      this._iconField = 'inboxOpen'
    }

    if (integration) {
      const user = usersStore.user
      if (!user?.isOwnerRole) return
      runInAction(() => {
        this._integrationSenderKey = integration.key
      })

      if (integration.key === 'infusionsoft') {
        this._integrationSenders.push({
          id: integration.key,
          label: 'Lead owner',
          iconL: integration.icon,
          data: integration,
        })
      }

      if (integration.key === 'hubspot') {
        try {
          runInAction(() => {
            this._loading = true
          })
          const { data: fields } = await IntegrationsApi.getIntegrationsByKeyFields(integration.key)
          const ownerFields = fields.filter((field) => field?.referencedObjectType === 'OWNER')
          const ownerFieldsItems = ownerFields.map((field) => ({
            id: field.name,
            label: field.label,
            value: field.name,
            data: field,
          }))

          if (!ownerFieldsItems.length) return

          this._integrationSenders.push({
            id: integration.key,
            label: 'User property',
            iconL: integration.icon,
            data: integration,
            children: ownerFieldsItems,
            iconR: 'chevronRight',
            childrenDropdownProps: {
              width: 292,
              TransitionProps: {
                timeout: 100,
              },
            },
          })
        } catch (e) {
          console.log(e)
        } finally {
          runInAction(() => {
            this._loading = false
          })
        }
      }
    }
  }

  addItemPhoneDropdown = (phone: PhoneShortCode) => {
    const number = numbersStore.getItem(phone.numberId)

    if (!number) return

    const dropdownItem: IDropdownItem = {
      id: phone.id,
      label: `${number.national_number}: ${phone.name}`,
      iconL: 'hash2',
      data: number,
    }

    this._shortcodeDropdownMap.set(phone.id, dropdownItem)
  }

  addItemInboxDropdown = (inbox: Inbox) => {
    const number = numbersStore.getItem(inbox.numberId)
    const widthDropdown = inbox.numberIds.length === 1 ? 162 : 292
    const children: IDropdownItemSendFrom[] = []

    if (inbox.sendingOptions.length) {
      inbox.sendingOptions.forEach((option) => {
        const id = option.key
        const count = option.number_ids.length
        const number = numbersStore.getItem(option.number_ids[0])
        const label = id === 'scaler' ? 'Scaler' : 'Local presence'
        const labelContent = id === 'scaler' ? <SendFromScaleLabel /> : 'Local presence'
        const tooltipLabel =
          id === 'scaler' ? (
            <>
              Sends messages from all your inbox numbers to balance the load <br />
              <a href={`${links.smartInboxes}`} target='_blank' rel='noreferrer'>
                Learn more
              </a>
            </>
          ) : (
            <>
              Sends messages from local numbers by geographic proximity <br />
              <a href={`${links.smartInboxes}`} target='_blank' rel='noreferrer'>
                Learn more
              </a>
            </>
          )

        children.push({
          id: id,
          label: label,
          labelContent: labelContent,
          labelRight: `${count} numbers`,
          data: number,
          tooltipQuestionProps: {
            margin: '10px',
            width: 155,
            type: 'description',
            placement: 'right',
            label: tooltipLabel,
          },
          iconQuestionProps: {
            fontSize: 13,
          },
          value: String(inbox.id),
        })
      })
    } else if (number) {
      children.push({
        id: String(number.id),
        label: number.formatted_number,
        data: number,
        value: String(inbox.id),
      })
    }

    const dropdownItem: IDropdownItem = {
      id: inbox.id,
      label: inbox.name,
      iconL: inbox.icon,
      data: inbox,
      childrenDropdownProps: {
        margin: 0,
        marginTop: -3,
        width: widthDropdown,
        TransitionProps: {
          timeout: 100,
        },
      },
      children: children,
      iconR: 'chevronRight',
    }

    this._inboxesDropdownMap.set(inbox.id, dropdownItem)
  }

  handleChange = (callback: (params: IParamsSendFrom) => void) => {
    this._onChange = callback
  }

  getInboxByNumber = (numberId?: number): Inbox | undefined => {
    if (!numberId) return

    return this.inboxes.find((item) => (item.data as Inbox).numberIds.includes(numberId))
      ?.data as Inbox
  }

  setError = (error: ReactNode) => {
    this._error = error
  }

  setNumberId = (numberId: number | null, type?: 'default' | 'init') => {
    if (!numberId) return

    this._sendFrom = {
      type: 'from_number',
      options: {
        number_id: numberId,
      },
    }

    if (type === 'init') {
      this.initValue()
    }
  }

  setSendFrom = (params: IParamsSendFrom | null, type?: 'default' | 'init') => {
    if (!params) return

    this._sendFrom = params

    if (type === 'init') {
      this.initValue()
    }
  }

  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  setCurrentInboxItem = ({ item }: { item: IDropdownItem }) => {
    const inboxId = Number(item.id)
    const phone = item.children?.[0].data as Phone
    const childrenNumberId = phone.id
    const childrenTypeId = item.children?.[0].id

    if (inboxId && childrenNumberId) {
      this.setActive({ typeId: childrenTypeId, teamId: inboxId, numberId: childrenNumberId })

      this.hideDropDown()
    }
  }

  setItem = ({
    item,
    parentItem,
    key,
  }: {
    item: IDropdownItemSendFrom
    parentItem?: IDropdownItem
    key: ISendFromGroupKey
  }) => {
    if (key === 'short_codes') {
      const shortCode = numbersStore.getItemShortCode(Number(item.id))

      if (shortCode) {
        this.setActive({
          typeId: shortCode.numberId,
          numberId: shortCode.numberId,
        })
      }
    }

    if (key === 'inboxes') {
      const inboxId = Number(parentItem?.id)
      const phone = item.data as Phone
      const numberId = phone.id

      if (inboxId && numberId) {
        this.setActive({ typeId: item.id, teamId: inboxId, numberId: numberId })
      }
    }

    if (key === 'integration_senders') {
      if (parentItem?.id) {
        this.setActive({
          numberId: 0,
          typeId: parentItem?.id,
          owner_property: item.value,
          name: parentItem.label,
          label: item.label,
        })
      } else {
        this.setActive({
          numberId: 0,
          typeId: item?.id,
          name: item.label,
        })
      }
    }

    this.hideDropDown()
  }

  setActive = ({
    typeId,
    numberId,
    teamId,
    name = '',
    label = '',
    owner_property = '',
  }: {
    typeId?: IDropdownItemSendFromId
    numberId?: number
    teamId?: number
    name?: string
    label?: string
    owner_property?: string
  }) => {
    if (!typeId) return

    if (numberId === 0) {
      this._numberType = numberId
      this._ownerProperty = owner_property
      this._name = name
      this._label = label

      if (typeId === 'hubspot') this._iconField = 'hubspot'
      if (typeId === 'infusionsoft') this._iconField = 'infusionsoft'

      this.clearActiveDropdownInbox()
      if (owner_property) {
        const integrationSender = this._integrationSenders[0]
        const child = integrationSender.children?.find((item) => item.value === owner_property)
        if (child) {
          child.activeValue = true
          integrationSender.activeValue = child.label
        }
      }
    }

    if (!teamId && numberId) {
      const shortCode = numbersStore.getShortCodeByNumber(numberId)

      if (shortCode) {
        this._name = shortCode.name
        this._label = shortCode.formatted_number

        this._numberType = numberId
        this._teamId = null
      }
    }

    if (teamId) {
      const inbox = this._inboxesDropdownMap.get(teamId)

      const label = () => {
        if (typeId === 'scaler') return 'Scaler'
        if (typeId === 'local_presence') return 'Local presence'

        return numbersStore.getItem(+typeId)?.formatted_number || ''
      }

      if (inbox) {
        this._isAircall = (inbox.data as Inbox) ? (inbox.data as Inbox).is_aircall : false
        this._name = inbox.label
        this._label = label()

        this._numberType = typeId === 'scaler' || typeId === 'local_presence' ? typeId : +typeId
        this._teamId = +inbox.id

        this.setActiveDropdownInbox(this._teamId, typeId)
      }
    }
  }

  setActiveDropdownInbox = (teamId: number, childId: IDropdownItemSendFromId) => {
    const inbox = this._inboxesDropdownMap.get(teamId)

    this._iconField = 'inboxOpen'

    this.clearActiveDropdownInbox()

    if (inbox) {
      const child = inbox.children?.find((item) => item.id === String(childId))

      if (child) child.activeValue = true
      if (childId === 'scaler') {
        inbox.activeValue = 'Scaler'
      } else if (childId === 'local_presence') {
        inbox.activeValue = 'Local presence'
      } else {
        inbox.activeValue = numbersStore.getItem(+childId)?.formatted_number || ''
      }
    }
  }

  clearActiveDropdownInbox = () => {
    this._integrationSenders.forEach((item) => {
      item.activeValue = ''
      item.children?.forEach((item) => {
        item.activeValue = false
      })
    })
    this._inboxesDropdownMap.forEach((item) => {
      item.activeValue = ''
      item.children?.forEach((item) => {
        item.activeValue = false
      })
    })
  }

  hideDropDown = () => {
    if (this._dropdownId) {
      dropDownStore.hide(this._dropdownId)
    }
  }

  setSearch = (value: string) => {
    this._search = value
  }

  get selectedItem() {
    if (!this._name) return null

    return {
      name: this._name,
      label: this._label,
    }
  }

  get itemsGroups(): ISendFromGroup[] {
    const items: ISendFromGroup[] = []

    if (this.integrationSenders.length) {
      items.push({
        key: 'integration_senders',
        title: '',
        items: this.itemsIntegrationSenders,
      })
    }

    if (this.shortCodes.length) {
      items.push({
        key: 'short_codes',
        title: 'Short codes',
        items: this.itemsShortCodes,
      })
    }

    if (this.inboxes.length) {
      items.push({
        key: 'inboxes',
        title: 'Inboxes',
        items: this.itemsInboxes,
      })
    }

    if (this._search) {
      return items.filter((group) => group.items.length)
    }

    return items
  }

  get itemsShortCodes(): IDropdownItemSendFrom[] {
    return this.shortCodes.filter((item) => {
      const shortCode = numbersStore.getShortCodeByNumber((item.data as Phone).id)

      if (!shortCode) return false

      return shortCode.name.toLowerCase().includes(this._search.toLowerCase())
    })
  }

  get itemsInboxes(): IDropdownItem[] {
    return this.inboxes
      .filter((item) => {
        const inbox = item.data as Inbox

        if (!inbox) return false

        const searchByName = inbox.name.toLowerCase().includes(this._search.toLowerCase())
        const searchByNumbers = inbox.numberIds
          .reduce<Phone[]>((state, current) => {
            const number = numbersStore.getItem(current)

            if (number) state.push(number)

            return state
          }, [])
          .filter((number) => {
            return (
              number.number.toLowerCase().includes(this._search.toLowerCase()) ||
              number.formatted_number.toLowerCase().includes(this._search.toLowerCase()) ||
              number.national_number.toLowerCase().includes(this._search.toLowerCase())
            )
          })

        return Boolean(searchByName || searchByNumbers.length)
      }, [])
      .map((inbox) => {
        return {
          ...inbox,
          isCardClick: !!inbox.children?.length && inbox.children?.length === 1,
        }
      })
  }

  get itemsIntegrationSenders(): IDropdownItem[] {
    return this.integrationSenders.filter((item) => {
      return item.label.toLowerCase().includes(this._search.toLowerCase())
    })
  }

  get params(): IParamsSendFrom | null {
    if (this._numberType === null) return null

    if (this._numberType === 0) {
      return {
        type: 'from_integration',
        options: {
          number_id: this._numberType,
          owner_property: this._ownerProperty || '',
        },
      }
    }

    if (typeof this._numberType === 'number') {
      return {
        type: 'from_number',
        options: {
          number_id: this._numberType,
        },
      }
    }

    if (this._teamId) {
      return {
        type: 'from_smart_inbox',
        options: {
          team_id: this._teamId,
          smart_option: this._numberType,
        },
      }
    }

    return null
  }

  get shortCodes() {
    return Array.from(this._shortcodeDropdownMap.values())
  }

  get inboxes() {
    const inboxes = Array.from(this._inboxesDropdownMap.values())
    const user = usersStore.user

    if (user?.isAdminRole || user?.isOwnerRole) {
      return inboxes
    }

    return inboxes.filter((item) => user?.id && (item.data as Inbox)?.memberIds.includes(user?.id))
  }

  get integrationSenders() {
    return this._integrationSenders
  }

  get itemsCount() {
    return this.shortCodes.length + this.inboxes.length
  }

  get loading() {
    return this._loading
  }

  get search() {
    return this._search
  }

  get isAircall() {
    return this._isAircall
  }

  get iconField() {
    return this._iconField
  }

  get error() {
    return {
      message: this._error,
    }
  }
}
