import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router'
import { useIntegrationDetailsContext } from 'pages/settings/pages/integrations/context/integrationDetailsContext'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'

export const IntegrationDetailsIndexRedirect = observer(() => {
  const { integration } = useIntegrationDetailsContext()

  if (!integration) return null

  return integration.isConnected ? (
    <Navigate to={IntegrationsRoutes.settings} replace />
  ) : (
    <Navigate to={IntegrationsRoutes.description} replace />
  )
})
