import { makeAutoObservable } from 'mobx'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { TenDlcApi } from 'entities/TenDlc'
import { ITenDlcUseCase } from 'entities/TenDlc/api/type'
import { organizationStore } from 'entities/Organization'
import { ComplianceApi } from 'entities/Compliance'
import { OptionItem } from '../ui/OptionItem'
import styles from '../ui/styles.module.scss'
import { ExampleModalContent } from '../ui/ExampleModalContent'
import type { LocalNumbersStore } from './LocalNumbersStore'
import { ILocalNumbersRoutesEnum } from '../types'

const useCaseDescExampleMock = `This campaign sends appointment information - confirmation
   & reminder messages, as well as promotional material for special offers to our 
   customers once they have booked an appointment with us on our website and opted-
   in to receive promotional and notification SMS from us.`

export const useCaseDescMinTextLength = 5 // 40
export const useCaseDescMaxTextLength = 10 // 4096

export class UseCaseStore {
  constructor(private _localNumbersStore: LocalNumbersStore) {
    makeAutoObservable(this)
  }

  private _useCases: ITenDlcUseCase[] = []
  private _useCaseId: string | null = null
  private _useCaseDesc = ''
  private _useCaseDescExample = useCaseDescExampleMock

  get useCases() {
    return this._useCases
  }

  get activeUseCase() {
    return this.useCases.find((useCase) => useCase.id === this.useCaseId)?.name
  }

  get useCaseId() {
    return this._useCaseId
  }

  get useCaseAsItems(): IDropdownItem[] {
    return this.useCases.map((useCase) => ({
      id: useCase.id,
      label: useCase.name || '',
      variant: EnumDropdownItemVariant.Custom,
      className: styles.customDropdown,
      renderOption: () => <OptionItem label={useCase.name} desc={useCase.description} />,
    }))
  }

  get useCaseDesc() {
    return this._useCaseDesc
  }

  get useCaseDescExample() {
    return this._useCaseDescExample
  }

  get isStep1Disabled() {
    const ifWithinLimits =
      this.useCaseDesc.length <= useCaseDescMaxTextLength &&
      this.useCaseDesc.length >= useCaseDescMinTextLength

    return !this.useCaseId || !ifWithinLimits
  }

  loadUseCases = async () => {
    const { data } = await TenDlcApi.getTenDlcUseCases({ filter: 'standard' })

    this._useCases = data
  }

  campaignUseCaseNextClick = () => {
    uiStore.changeRoute({
      path: `/settings/organization/compliance/local-numbers/${ILocalNumbersRoutesEnum.messages}`,
    })
  }

  setUseCase = (useCase: string) => {
    this._useCaseId = useCase
  }

  setUseCaseDesc = (useCaseDesc: string) => {
    this._useCaseDesc = useCaseDesc
  }

  openUseCaseExampleModal = () => {
    const id = 'useCaseExampleModal'

    modalStore.addModal({
      id,
      width: 540,
      title: 'Use case description example',
      ModalContent: () => (
        <ExampleModalContent
          activeUseCase={this.activeUseCase || ''}
          example={this.useCaseDescExample}
        />
      ),
      primaryAction: {
        text: 'Done',
        onAction: () => modalStore.closeModal(id),
      },
      showCloseButton: false,
    })
  }

  setCampaignData1Step = async () => {
    const { numberVendor } = organizationStore

    if (!this._useCaseId || !numberVendor) return

    try {
      this._localNumbersStore.setStepLoading(true)

      ComplianceApi.setCampaignData1Step({
        step: 1,
        useCaseData: {
          useCase: this._useCaseId,
          description: this._useCaseDesc,
        },
        vendorKey: numberVendor?.key,
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._localNumbersStore.setStepLoading(false)
    }
  }
}
