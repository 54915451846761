import { makeAutoObservable } from 'mobx'
import { isIconKey } from 'shared/ui/Icon'
import { API_URL_MICROSERVICE } from 'shared/config'
import type {
  IIntegrationKey,
  IResponseIntegration,
  IResponseUserIntegration,
} from 'entities/Integrations/api/types'
import { UserIntegration } from 'entities/Integrations/model/UserIntegration'

export class Integration {
  id: number
  key: IIntegrationKey
  name: string
  is_active: boolean
  connected: UserIntegration | null
  disconnected: UserIntegration | null
  oauth: boolean

  constructor(item: IResponseIntegration) {
    this.id = item.id
    this.key = item.key
    this.name = item.name
    this.is_active = item.is_active
    this.oauth = item.oauth
    this.connected = item.connected ? new UserIntegration(item.connected) : null
    this.disconnected = item.disconnected ? new UserIntegration(item.disconnected) : null

    makeAutoObservable(this)
  }

  get icon() {
    return isIconKey(this.key) ? this.key : 'salesmsg'
  }

  get isConnected() {
    return this.connected?.is_active && this.connected.connection_error === 0
  }

  get shouldReconnect() {
    return this.connected?.is_active && this.connected.connection_error !== 0
  }

  get connectUrl() {
    if (this.key === 'salesforce') {
      return `${API_URL_MICROSERVICE}integration/connect/${this.key}`
    }

    return `${API_URL_MICROSERVICE}core/integrations/${this.key}/connect`
  }

  get url() {
    return this.connected?.url
  }

  get user_credential() {
    return this.connected?.user_credential
  }

  syncOrigin = (origin: IResponseIntegration) => {
    this.id = origin.id
    this.key = origin.key
    this.name = origin.name
    this.is_active = origin.is_active
    this.oauth = origin.oauth
    this.connected = origin.connected ? new UserIntegration(origin.connected) : null
    this.disconnected = origin.disconnected ? new UserIntegration(origin.disconnected) : null
  }

  syncUserIntegration = (userIntegration: IResponseUserIntegration) => {
    if (userIntegration.is_active) {
      this.connected = new UserIntegration(userIntegration)
    } else {
      this.disconnected = new UserIntegration(userIntegration)
    }
  }
}
