import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { KnowledgeBaseViewStore } from 'pages/settings/pages/knowledgeBaseView/store/knowledgeBaseViewStore'
import { KnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBaseView/context/knowledgeBaseViewContext'
import { KnowledgeBaseView } from 'pages/settings/pages/knowledgeBaseView/ui/KnowledgeBaseView'

export const KnowledgeBaseViewPage = observer(() => {
  const store = useMemo(() => new KnowledgeBaseViewStore(), [])

  useEffect(() => {
    return store.dispose
  }, [])

  return (
    <KnowledgeBaseViewContext.Provider value={store}>
      <KnowledgeBaseView />
    </KnowledgeBaseViewContext.Provider>
  )
})
