import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import type { IComplianceSettingsStore } from 'entities/Compliance'
import { QuestionnaireStore } from '../store/questionnaireStore'

const QuestionnaireStoreContext = createContext<QuestionnaireStore | null>(null)

export const useQuestionnaireContext = () => {
  const context = useContext(QuestionnaireStoreContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with QuestionnaireStore')

  return context
}

export const QuestionnaireProvider = ({
  complianceSettingsStore,
  children,
}: {
  complianceSettingsStore: IComplianceSettingsStore
  children: ReactNode
}) => {
  const store = useMemo(() => new QuestionnaireStore(complianceSettingsStore), [])

  useEffect(() => store.dispose, [])

  return (
    <QuestionnaireStoreContext.Provider value={store}>
      {children}
    </QuestionnaireStoreContext.Provider>
  )
}
