import classNames from 'classnames'

import styles from './styles.module.scss'

export interface ITextareaProps {
  placeholder: string
  error?: boolean | null
  value: string
  rows?: number
  onBlur?: (value: string) => void
  onChange: (value: string) => void
}

export const Textarea = ({
  placeholder,
  error,
  value,
  rows = 4,
  onBlur,
  onChange,
}: ITextareaProps) => {
  return (
    <textarea
      rows={rows}
      className={classNames(styles.textarea, {
        [styles.textareaError]: error,
      })}
      placeholder={placeholder}
      value={value}
      onBlur={({ target }) => onBlur?.(target.value)}
      onChange={({ target }) => onChange(target.value)}
    />
  )
}
