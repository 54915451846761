import { uiStore } from 'shared/store/uiStore'

import styles from './styles.module.scss'

export const UpgradeButton = () => {
  return (
    <div
      className={styles.upgradeButton}
      onClick={() => {
        uiStore.changeRoute({
          path: '/settings/billing/overview/upgrade',
          type: 'vue',
        })
      }}
    >
      Upgrade
    </div>
  )
}
