import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { CancelTokenSource } from 'axios'
import { uiStore } from 'shared/store/uiStore'
import { IComplianceSettingsStore } from 'entities/Compliance'

import { UseCaseStore } from './UseCaseStore'
import { MessagesStore } from './MessagesStore'
import { IOptInMethodTypes, OptInMethodStore } from './OptInMethodStore'
import { OptInConsentStore } from './OptInConsentStore'
import { ILocalNumbersRoutesEnum } from '../types'
import { ForbiddenMessageStore } from './ForbiddenMessageStore'
import { PackageStore } from './PackageStore'

const titleMap: Record<string, string> = {
  [ILocalNumbersRoutesEnum.campaignUseCases]: 'Campaign use case',
  [ILocalNumbersRoutesEnum.messages]: 'Messages',
  [ILocalNumbersRoutesEnum.optInMethod]: 'Opt-in method',
  [ILocalNumbersRoutesEnum.optInOnlineFormContent]: 'Opt-in consent: Online Form',
  [ILocalNumbersRoutesEnum.optInPaperFormContent]: 'Opt-in consent: Paper Form',
  [ILocalNumbersRoutesEnum.optInViaTextContent]: 'Opt-in consent: via Text',
  [ILocalNumbersRoutesEnum.optInQrCodeContent]: 'Opt-in consent: QR Code',
  [ILocalNumbersRoutesEnum.forbiddenMessageTopics]: 'Forbidden message topics',
  [ILocalNumbersRoutesEnum.package]: 'Package',
  [ILocalNumbersRoutesEnum.orderSummary]: 'Order summary',
}

const startUrls = [
  ILocalNumbersRoutesEnum.campaignUseCases,
  ILocalNumbersRoutesEnum.messages,
  ILocalNumbersRoutesEnum.optInMethod,
]

const endPaths = [
  ILocalNumbersRoutesEnum.forbiddenMessageTopics,
  ILocalNumbersRoutesEnum.package,
  ILocalNumbersRoutesEnum.orderSummary,
]

export class LocalNumbersStore {
  constructor(private _complianceSettingsStore: IComplianceSettingsStore) {
    makeAutoObservable(this)
  }

  useCaseStore = new UseCaseStore(this)
  messagesStore = new MessagesStore(this)
  optInMethodStore = new OptInMethodStore()
  optInConsentStore = new OptInConsentStore(this)
  forbiddenMessageStore = new ForbiddenMessageStore()
  packageStore = new PackageStore(this)

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _stepLoading = false

  get complianceSettingsStore() {
    return this._complianceSettingsStore
  }

  get stepLoading() {
    return this._stepLoading
  }

  get allPaths() {
    const optInPaths: ILocalNumbersRoutesEnum[] = this.optInMethodStore.optInMethods
      .map((method) => {
        switch (method) {
          case IOptInMethodTypes.onlineForm:
            return ILocalNumbersRoutesEnum.optInOnlineFormContent
          case IOptInMethodTypes.paperForm:
            return ILocalNumbersRoutesEnum.optInPaperFormContent
          case IOptInMethodTypes.text:
            return ILocalNumbersRoutesEnum.optInViaTextContent
          case IOptInMethodTypes.qrCode:
            return ILocalNumbersRoutesEnum.optInQrCodeContent
          default:
            return null
        }
      })
      .filter(Boolean) as ILocalNumbersRoutesEnum[]

    return [...startUrls, ...optInPaths, ...endPaths]
  }

  get allStepsLength() {
    // We have 6 steps + opt in. In case optInMethodStore.optInMethods.length > 0, opt in is included to the count
    return this.optInMethodStore.optInMethods.length
      ? this.allPaths.length
      : this.allPaths.length + 1
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setStepLoading = (loading: boolean) => {
    this._stepLoading = loading
  }

  getProgressInfoTitle = (pathname: string) => {
    return titleMap[pathname] || ''
  }

  getNextStep = (path: ILocalNumbersRoutesEnum): ILocalNumbersRoutesEnum | '' => {
    const allPaths = this.allPaths
    const currentIndex = allPaths.indexOf(path)
    const noLastPath = currentIndex < allPaths.length - 1

    if (currentIndex !== -1 && noLastPath) {
      return allPaths[currentIndex + 1]
    }

    return path
  }

  getLastPathSegment = (url: string): string => {
    const segments = url.split('/').filter((segment) => segment !== '')

    return segments[segments.length - 1] || ''
  }

  campaignOptInMethodNextClick = (path: ILocalNumbersRoutesEnum) => {
    const step = this.getNextStep(path)

    uiStore.changeRoute({
      path: `/settings/organization/compliance/local-numbers/${step}`,
    })
  }

  getSettingsStep = (path: ILocalNumbersRoutesEnum) => {
    const allPaths = this.allPaths

    return allPaths.indexOf(path) + 1
  }
}
