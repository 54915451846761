import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import optInViaTextApproved from 'shared/assets/images/optInViaTextApproved.png'
import optInViaTextRejected from 'shared/assets/images/optInViaTextRejected.png'
import styles from './styles.module.scss'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { OptInConsent } from './OptInConsent'

export const OptInViaTextContent = observer(() => {
  const { optInConsentStore, stepLoading } = useLocalNumbersContext()
  const {
    isPaperFormNextDisabled,
    optInPaperFormAttachmentError,
    openApprovedExampleModal,
    uploadPaperFormOptInImageAttachment,
    clearPaperFormAttachmentError,
  } = optInConsentStore
  return (
    <div className={classNames(styles.contentWrap, styles.optInConsentWrap)}>
      <OptInConsent
        stepLoading={stepLoading}
        isNextDisabled={isPaperFormNextDisabled}
        attachmentError={optInPaperFormAttachmentError}
        increaseStep={() => {}}
        clearAttachmentError={clearPaperFormAttachmentError}
        uploadOptInImageAttachment={uploadPaperFormOptInImageAttachment}
        seeApprovedExampleClick={() => {
          openApprovedExampleModal(
            [optInViaTextApproved],
            ['Opt-in / Paper Form / Approved example']
          )
        }}
        approvedExampleClick={() =>
          openApprovedExampleModal(
            [optInViaTextApproved, optInViaTextRejected],
            ['Opt-in / Via Text / Approved example', 'Opt-in / Via Text / Rejected example']
          )
        }
        rejectedExampleClick={() =>
          openApprovedExampleModal(
            [optInViaTextRejected, optInViaTextApproved],
            ['Opt-in / Via Text / Rejected example', 'Opt-in / Via Text / Approved example']
          )
        }
        firstBlock={{
          title: 'Include compliance language to your keyword',
          desc: 'If hyperlinking is not possible, include the full URL of your Terms of Service and Privacy Policy in the compliance language (e.g. “https:/example.com/terms-of-service/”).',
          copyTitle: 'Compliance language',
          copyText:
            'I agree to receive recurring automated text messages at the phone number provided. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our Terms of Service and Privacy Policy.',
        }}
        secondBlock={{
          title: 'Include this paragraph to your Privacy Policy',
          desc: 'Sharing data is not allowed. If text messaging data/consent is not shared, include the paragraph below to your Privacy Policy.',
          copyText:
            'Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the Text Message services.',
        }}
      />
    </div>
  )
})
