import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { debounce, isEqual } from 'lodash'
import { nanoid } from 'nanoid'
import React from 'react'
import axios, { CancelTokenSource, isCancel } from 'axios'
import { ActionItem, layoutStore, toastStore } from 'shared/ui'
import { replaceLink } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { PageLayoutStore } from 'shared/layout'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import {
  type IParamsCreateMessage,
  type IParamsDeleteMessagesDraft,
  type IParamsMessagesGetContactsByIdTeamsById,
  type IParamsUpdateMessage,
  type IResponseMessage,
  type IResponseRecordTranscriptStatus,
  type MessageData,
  MessagesApi,
} from 'entities/Message'
import { attachmentStore } from 'entities/Attachment'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { integrationsStore } from 'entities/Integrations'
import { ShortLinkDropdownItem, shortLinkStore } from 'entities/ShortLink'
import {
  IAIActionTypeEnum,
  type IParamsCreateSuggestAnswer,
  type IParamsCreateSummaryAnswer,
} from 'entities/AIAssistant'
import type { IResponseActivity } from 'entities/Activity/api/types'
import { websocket } from 'entities/WebSocket'
import { usersStore } from 'entities/Users'
import type { IResponseEventTyping } from 'entities/Typing/api/types'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { Typing } from 'entities/Typing/model/Typing'
import { Integration } from 'entities/Integrations/model/Integration'
import { logger } from 'entities/EventLog'
import { userSettingsStore } from 'entities/Settings'
import { organizationStore } from 'entities/Organization'
import { inboxesStore } from 'entities/Inbox'
import { Viewing } from 'entities/Viewing/model/Viewing'
import { IResponseEventViewing, IViewingStatus } from 'entities/Viewing'
import { numbersStore } from 'entities/Phone'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { userPermissionsStore } from 'entities/UsersPermissions'
import {
  EnumMessageFieldMode,
  EnumVariantMessageField,
  ShortenLinkAction,
} from 'widgets/MessageField'
import {
  MessageSignatureDropdownItem,
  MessageSignatureIconButton,
  MessageSignatureStore,
} from 'widgets/MessageField/ui/MessageSignature'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { EmojiAction } from 'widgets/MessageField/ui/FieldActions/EmojiAction'
import { MergeFieldsDropdownItem } from 'widgets/MergeField/ui/actions/MergeFieldsDropdownItem'
import { MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'
import { AIAssistantAction, AiAssistantStore } from 'widgets/AIAssistant'
import { IScheduledData } from 'widgets/ConversationSchedule/store/conversationScheduleStore'
import { recentStore } from 'widgets/RecentlySent'
import { CallModalStore } from 'widgets/CallModal'
import { ConversationListStore } from 'widgets/ConversationList'
import { ConversationSearchStore } from 'widgets/ConversationHeaderSearch'
import { ConversationNewSearchStore } from 'widgets/ConversationNew'
import { CallContentStore } from 'widgets/MessageCard/store'
import { IConversationMessagesStoreConfig } from 'widgets/ConversationMessages/store/types'
import { ConversationSavedRepliesAction } from '../ui/ConversationSavedRepliesAction/ConversationSavedRepliesAction'
import { ConversationSendAction } from '../ui/ConversationSendAction/ConversationSendAction'
import { getSendMessageAlert } from '../helpers/messageAlert'

export class ConversationMessagesStore {
  private _callModalStore: CallModalStore | null = null
  private _conversationListStore: ConversationListStore | null = null
  private _conversationSearchStore: ConversationSearchStore | null = null

  private _per_page = 20
  private _page = 1

  private _messageFieldStore: MessageFieldStore
  private _messageSignatureStore: MessageSignatureStore
  private _aiAssistantStore: AiAssistantStore
  private _currentConversationId: number | null = null
  private _itemsMap: Map<string | number, Message | Activity> = new Map()
  private _sendingMessagesIdsMap: Map<number | string, string | number> = new Map()
  private _scrollBottomTrigger = ''

  private _loading = true
  private _loadingConversation = false
  private _loadingPrevious = false
  private _loadingMessages = false
  private _hasNext = true
  private _hasPrevious = false

  private _loadingBeforeId: string | number = 0
  private _isScheduleMessage = false
  private _isEditMessage = false
  private _handleReceiveNewScroll = false
  private _isShowActivity = userSettingsStore.isUserShowActivity
  private _typingsMap: Map<number, Typing> = new Map()
  private _viewingsMap: Map<number, Viewing> = new Map()
  private _cancelTokenSource: CancelTokenSource | null = null
  private _debounceOnUpdateDraft?: ReturnType<typeof debounce>
  private _debounceOnDeleteDraft?: ReturnType<typeof debounce>
  private _isSetDraft = false
  private _isHideTrialAlertNotForOwner = false
  private _isClickToUndo = userSettingsStore.isClickToUndo
  private _warning: string | null = null
  private _isModeNoteObj = {
    value: false,
  }

  private _disposeIsAircall: IReactionDisposer | null = null
  private _disposeOnGroup: IReactionDisposer | null = null
  private _disposeConversationDelete: IReactionDisposer | null = null
  private _disposeConversationCreate: IReactionDisposer | null = null
  private _disposeConversationUpdate: IReactionDisposer | null = null
  private _disposeModeNote: IReactionDisposer | null = null

  private _disposeIsUserShowActivity: IReactionDisposer | null = null
  private _disposeIsClickToUndo: IReactionDisposer | null = null
  private _disposeConversationId: IReactionDisposer | null = null
  private _disposeConversation: IReactionDisposer | null = null
  private _disposeActiveTab: IReactionDisposer | null = null
  private _disposeShowDoubleOptInButton: IReactionDisposer | null = null

  private _callContentStore = new CallContentStore(this)
  private _conversationNewSearchStore: ConversationNewSearchStore | null = null
  private _startSendMessage: IConversationMessagesStoreConfig['startSendMessage'] | null = null
  private _finishSendMessage: IConversationMessagesStoreConfig['finishSendMessage'] | null = null
  private _enableTrialAlertLimitConversations: IConversationMessagesStoreConfig['enableTrialAlertLimitConversations'] =
    false
  private _isCheckInbox: IConversationMessagesStoreConfig['isCheckInbox'] = false
  private _isActiveTab = true
  private _canSendViewing = true
  private _throttleViewingTime = 30000
  private _onViewingTimerId: NodeJS.Timer | null = null

  constructor(private _pageLayoutStore?: PageLayoutStore) {
    makeAutoObservable(this)

    const isCurrentAirCall =
      inboxesStore.currentInbox?.type === 'inbox' && inboxesStore.currentInbox?.is_aircall

    this._debounceOnUpdateDraft = debounce(this._onSetDraft, 100)
    this._debounceOnDeleteDraft = debounce(this._onDeleteDraft, 100)
    this._messageFieldStore = new MessageFieldStore({
      styles: {
        minHeight: layoutStore.isMobileView ? 44 : undefined,
        maxHeight: layoutStore.isMobileView ? 122 : undefined,
        gap: layoutStore.isMobileView ? 0 : undefined,
      },
      isCurrentAirCall,
      showActionsItems: 4,
      makeEvents: () => ({
        onFocus: this.handleRead,
        onClick: this.handleRead,
        onKeyDown: this.onTyping,
        onInit: this.handleSetDraft,
        onCloseEdit: this.onCloseEdit,
      }),
      variant: EnumVariantMessageField.Conversation,
      replaceMergeFieldsOnPaste: true,
      customSendAction: <ConversationSendAction />,
      withTrialText: true,
    })

    this._messageSignatureStore = new MessageSignatureStore({ parent: this._messageFieldStore })
    this._aiAssistantStore = new AiAssistantStore(this._messageFieldStore, {
      actions: [
        IAIActionTypeEnum.SuggestResponse,
        IAIActionTypeEnum.SummarizeConversation,
        IAIActionTypeEnum.Expand,
        IAIActionTypeEnum.MoreFormal,
        IAIActionTypeEnum.MoreFriendly,
        IAIActionTypeEnum.Rephrase,
        IAIActionTypeEnum.Shorten,
      ],
      getParamsCreateSummaryAnswer: this.getParamsCreateSummaryAnswer,
      getParamsCreateSuggestAnswer: this.getParamsCreateSuggestAnswer,
      additionalCreateSummaryAnswerAction: () => {
        this._messageFieldStore.setNoteMode(true)
      },
      isVariantContained: this._isModeNoteObj,
    })
    this._messageFieldStore.setActions(this.getMessageFieldActions(this._messageFieldStore))
  }

  initReactions = () => {
    this.reactionConversationDraftMessage()
    this.reactionOnGroup()
    this.reactionIsAircallInbox()

    this.reactionIsUserShowActivity()
    this.reactionIsClickToUndo()
    this.reactionConversationId()
    this.reactionShowDoubleOptInButton()
    this.reactionConversation()
    this.reactionActiveTab()
  }

  reactionShowDoubleOptInButton = () => {
    this._disposeShowDoubleOptInButton?.()
    this._disposeShowDoubleOptInButton = reaction(
      () => this.showDoubleOptInButton,
      (showDoubleOptInButton) => {
        this._messageFieldStore.setIsDoubleOptInEnabled(showDoubleOptInButton)
      }
    )
  }

  reactionConversationId = () => {
    this._disposeConversationId?.()
    this._disposeConversationId = reaction(
      () => this.conversationId,
      () => this._messageFieldStore.clearSendAlert()
    )
  }

  reactionConversation = () => {
    this._disposeConversation?.()
    this._disposeConversation = reaction(
      () => this.conversation,
      (conversation) => {
        if (conversation?.id) {
          this.onViewing()
        }
      }
    )
  }

  reactionActiveTab = () => {
    this._disposeActiveTab?.()
    this._disposeActiveTab = reaction(
      () => this._isActiveTab,
      (status) => {
        if (status && this._canSendViewing) {
          this.onViewing()
        }
      }
    )
  }

  reactionIsClickToUndo = () => {
    this._disposeIsClickToUndo?.()
    this._disposeIsClickToUndo = reaction(
      () => userSettingsStore.isClickToUndo,
      (value) => {
        this._isClickToUndo = value
      }
    )
  }

  reactionIsUserShowActivity = () => {
    this._disposeIsUserShowActivity?.()
    this._disposeIsUserShowActivity = reaction(
      () => userSettingsStore.isUserShowActivity,
      (value) => {
        this._isShowActivity = value
      }
    )
  }

  reactionIsAircallInbox = () => {
    this._disposeIsAircall?.()
    this._disposeIsAircall = reaction(
      () => [this.currentInbox, this.conversation],
      () => {
        const currentInbox = inboxesStore.getItem(this.conversation?.inbox_id)

        this._messageFieldStore.setIsCurrentAirCall(
          currentInbox?.type === 'inbox' ? currentInbox?.is_aircall : false
        )
      }
    )
  }

  setConfig = (config: IConversationMessagesStoreConfig) => {
    this._callModalStore = config.callModalStore || null
    this._conversationListStore = config.conversationListStore || null
    this._conversationSearchStore = config.conversationSearchStore || null
    this._conversationNewSearchStore = config.conversationNewSearchStore || null
    this._startSendMessage = config.startSendMessage || null
    this._finishSendMessage = config.finishSendMessage || null
    this._enableTrialAlertLimitConversations = config.enableTrialAlertLimitConversations || false
    this._isCheckInbox = config.isCheckInbox || false
  }

  setIsCheckInbox = (value: boolean) => {
    this._isCheckInbox = value
  }

  setEnableTrialAlertLimitConversations = (value: boolean) => {
    this._enableTrialAlertLimitConversations = value
  }

  setFocusMessageField = () => {
    setTimeout(() => {
      this._messageFieldStore.setFocusMessageFieldTrigger()
    })
  }

  isHideInboxAlert = false
  setHideInboxAlert = () => {
    this.isHideInboxAlert = true
  }

  get enableTrialAlertLimitConversations() {
    return this._enableTrialAlertLimitConversations
  }

  get callContentStore() {
    return this._callContentStore
  }

  get currentInbox() {
    if (this.isConversationNew) {
      return inboxesStore.getItem(this._conversationNewSearchStore?.activeInboxId)
    }

    return inboxesStore.currentInbox
  }

  get conversationId() {
    return this._currentConversationId || 0
  }

  get contact() {
    if (this.isConversationNew) return this._conversationNewSearchStore?.items[0] ?? null

    if (!this.conversation?.contact_id) return null

    return contactsStore.getItem(this.conversation.contact_id)
  }

  get integration(): Integration | null {
    if (!this.contact?.integration_key) return null

    // TODO: change key to salesmessage after back
    if (this.contact?.integration_key === 'salesmsg') return null

    return integrationsStore.getIntegration(this.contact.integration_key)
  }

  get conversation() {
    return conversationStore.getItem(Number(this.conversationId))
  }

  get isClosed() {
    return this.conversation?.isClosed
  }

  get isRead() {
    return this.conversation?.isRead
  }

  get isUnread() {
    return this.conversation?.isUnread
  }

  get allMessages() {
    const messages: Array<Message> = []
    Array.from(this._itemsMap.values()).forEach((item) => {
      if (item instanceof Message) {
        messages.push(item)
      }
    })
    return messages
  }

  get sendingMessages() {
    return this.allMessages
      .filter((message) => message.sending)
      .filter((message) => message.conversation_id === this.conversationId)
  }

  get beforeId() {
    return this.items[this.items.length - 1]?.id
  }

  get isGroup() {
    return this.conversation?.is_group
  }

  get items(): Array<Message | Activity> {
    return Array.from(this._itemsMap.values())
      .filter((message) => message instanceof Activity || !message.sending)
      .filter(
        (message) =>
          message instanceof Activity ||
          (message.type === 'call' ? message.record || message.body : true)
      )
      .sort((a, b) =>
        a.sortDateTime === b.sortDateTime
          ? Number(b.id) - Number(a.id)
          : b.sortDateTime - a.sortDateTime
      )
  }
  get scheduledItems() {
    return this.items.filter((message) => message instanceof Message && message.is_schedule)
  }

  get ordinaryItems() {
    return this.items.filter((message) => message instanceof Activity || !message.is_schedule)
  }

  get isEmpty(): boolean {
    return this.items.length === 0
  }

  get messages(): Array<Message> {
    const messages: Array<Message> = []
    this.items.forEach((item) => {
      if (item instanceof Message) {
        messages.push(item)
      }
    })
    return messages
  }

  get isConversationNew() {
    return this._currentConversationId === 0
  }

  get priority() {
    return this.conversation?.priority
  }

  get typings(): Typing[] {
    return Array.from(this._typingsMap.values())
  }

  get typingsCount() {
    return this.typings.length
  }

  get viewings(): Viewing[] {
    return Array.from(this._viewingsMap.values())
  }

  get showDoubleOptInButton() {
    return !this.contact?.isOptedIn && organizationStore.doubleOptInEnabled
  }

  get warningText() {
    return this._warning
  }

  get conversationContact() {
    const contactId = this.conversation?.contact_id
    if (!contactId) return null

    const contact = contactsStore.getItem(contactId)
    if (!contact) return null

    return contact
  }

  get conversationInbox() {
    const inbox = inboxesStore.getItem(this.conversation?.inbox_id)

    if (inbox?.id === 0) return inboxesStore.sortedFirstInbox

    return inboxesStore.currentInbox || inbox
  }

  get conversationInboxNumber() {
    const inbox =
      (this.conversationInbox instanceof Inbox && this.conversationInbox) ||
      (inboxesStore.currentInbox instanceof Inbox && inboxesStore.currentInbox)

    const numberFromCurrentConversation = numbersStore.getItem(this.conversation?.number_id)
    const numberFromInbox =
      inbox instanceof Inbox ? numbersStore.getItem(inbox.numberId) : undefined

    return numberFromCurrentConversation || numberFromInbox
  }

  get isStatusBlockedContact() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.is_blocked)
  }

  get isStatusNumberBlocked() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusBlocked &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusPending() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        !this.conversationInboxNumber?.is_aircall &&
        (this.conversationInboxNumber?.isStatusUnderVerification ||
          this.conversationInboxNumber?.isStatusInternalReview)
    )
  }

  get isStatusBlocked() {
    return Boolean(
      (this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusBlocked &&
        !this.conversationInboxNumber?.is_aircall) ||
        this.isStatusBlockedContact
    )
  }

  get isStatusUnverified() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusUnverified &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusDeclined() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusDeclined &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isMessageLocal() {
    const conversation = this.conversation
    let inbox = inboxesStore.getItem(conversation?.inbox_id)

    if (conversation?.isNew) {
      inbox = inboxesStore.currentInbox
    }

    if (inbox?.type !== 'inbox') return false

    const number = numbersStore.getItem(inbox?.numberId)
    const contact = this.conversationContact

    if (contact?.isOptOutHard) return false
    if (number?.isShortCode) return false

    return this.isOptOut || this.isOptOutCurrentInbox
  }

  get isMessageAll() {
    const conversation = this.conversation
    let inbox = inboxesStore.getItem(conversation?.inbox_id)

    if (conversation?.isNew) {
      inbox = inboxesStore.currentInbox
    }

    if (inbox?.type !== 'inbox') return false

    const number = numbersStore.getItem(inbox?.numberId)
    const contact = this.conversationContact

    if (!contact) return false
    if (number?.isShortCode) return this.isOptOutCurrentInbox
    if (contact?.isOptOutHard) return true

    return Boolean(
      contact?.optItems.length === contact?.optItems.filter((item) => !item.value).length
    )
  }

  get isOptOut() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.isOptOut)
  }

  get isOptOutCurrentInbox() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.isOptOutCurrentInbox)
  }

  setWarningText = (text: string | null) => {
    this._warning = text
  }

  getContactTZ = () => {
    return this.contact?.timezone || ''
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  reset = () => {
    this.setLoading(true)
    this._hasPrevious = false
    this._loadingMessages = false
    this._page = 1
    this._loadingBeforeId = 0
    this._messageFieldStore.reset()
    this._messageFieldStore.handleResetMode()
    this.resetMessage()
    this.setScheduleMessage(false)
    this._isEditMessage = false
    this._typingsMap.clear()
    this._viewingsMap.clear()
    this._canSendViewing = true
  }

  resetReactions = () => {
    this._disposeConversationDelete?.()
    this._disposeConversationCreate?.()
    this._disposeConversationUpdate?.()
    this._disposeModeNote?.()

    this._disposeOnGroup?.()
    this._disposeIsAircall?.()
    this._disposeIsUserShowActivity?.()
    this._disposeIsClickToUndo?.()
    this._disposeConversationId?.()
    this._disposeConversation?.()
    this._disposeActiveTab?.()
    this._disposeShowDoubleOptInButton?.()
  }

  resetCurrentConversationId = () => {
    this._currentConversationId = null
  }

  startSendProcess = async (
    message: IParamsCreateMessage,
    scheduledData?: IScheduledData,
    conversationId?: number,
    withUndo?: boolean
  ) => {
    await this._startSendMessage?.()

    if (this.isConversationNew) {
      this._conversationNewSearchStore?.handleCreateConversation(
        scheduledData,
        false,
        message,
        withUndo
      )
    } else {
      if (this._messageFieldStore.promiseAttachmentLoading) {
        this._messageFieldStore.setLoading(true)
        await this._messageFieldStore.promiseAttachmentLoading
      }

      this.sendMessage({
        message,
        conversationId,
      })

      if (this._currentConversationId === conversationId || !conversationId) {
        this._messageFieldStore.reset()
      }

      this._finishSendMessage?.()
    }
  }

  getMessageToSend = (scheduledData?: IScheduledData) => {
    return { ...this._messageFieldStore?.messageRequestData, ...scheduledData }
  }

  showUndoProcess = (scheduledData?: IScheduledData) => {
    const id = parseInt(nanoid().replace(/\D/g, ''), 10)
    const toastId = String(id)
    const timer = 5000
    const message = this.getMessageToSend(scheduledData)

    const client_id = nanoid()
    const undoPendingMessage: Message = new Message(undefined, {
      requestMessage: {
        media_url: message.media_url,
        body: message.message,
        send_at: new Date().toISOString(),
        sent_at: new Date().toISOString(),
        type: 'sms',
        sending: false,
        undoPending: true,
        conversation_id: this.conversationId,
      },
      client_id,
    })

    this._itemsMap.set(client_id, undoPendingMessage)
    if (this._handleReceiveNewScroll) this.setScrollBottomTrigger()

    const timeoutId = setTimeout(() => {
      this.startSendProcess(message, scheduledData, undoPendingMessage.conversation_id, true)
      this._itemsMap.delete(client_id)
      toastStore.remove(toastId)
    }, timer)

    toastStore.add({
      type: 'info',
      id: toastId,
      title: 'Sending in',
      withCountdown: true,
      timer,
      action: {
        text: 'Undo',
        onAction: () => {
          toastStore.remove(toastId)
          clearInterval(timeoutId)
          this._itemsMap.delete(client_id)
        },
      },
      secondAction: {
        text: 'Instant send',
        onAction: () => {
          this.startSendProcess(message, scheduledData, undoPendingMessage.conversation_id, true)
          this._itemsMap.delete(client_id)
          toastStore.remove(toastId)
          clearInterval(timeoutId)
        },
      },
    })

    this._messageFieldStore.reset()
  }

  onSendAction = async ({ scheduledData }: { scheduledData?: IScheduledData }) => {
    const checkError = this._isCheckInbox ? this._conversationNewSearchStore?.checkError() : false

    if (checkError) return
    if (this._messageFieldStore.disablesSend) return

    this.handleResetDraft()

    const message = this.getMessageToSend(scheduledData)

    const scheduleMessage = message.send_at
    const noteMessage = message.type === 'note'
    const undoMessage = this._isClickToUndo && !scheduleMessage && !noteMessage

    undoMessage && !this.isConversationNew
      ? this.showUndoProcess(scheduledData)
      : this.startSendProcess(message, scheduledData, this.conversationId, undoMessage)
  }

  onCloseEdit = () => {
    this._isEditMessage = false
    this.setScheduleMessage(false)
    this._messageFieldStore.setEditMessage(null)
  }

  onSaveEditMessageAction = () => {
    if (!this._messageFieldStore.editMessage) {
      return
    }

    this?.updateMessage({
      message: this._messageFieldStore?.paramsUpdateMessage,
      messageId: this._messageFieldStore.editMessage?.id,
    })
    this.onCloseEdit()
  }

  getParamsCreateSuggestAnswer = (): IParamsCreateSuggestAnswer => {
    return {
      messages: this.messages
        .filter((message) => (message.type === 'sms' || message.type === 'mms') && message.body)
        .slice(-20)
        .map((message) => ({
          message: message.body,
          first_name: message.first_name,
          direction: message.isOutbound ? 'outbound' : 'inbound',
        })) as IParamsCreateSuggestAnswer['messages'],
    }
  }
  getParamsCreateSummaryAnswer = (): IParamsCreateSummaryAnswer => {
    return {
      messages: this.messages
        .filter(
          (message) =>
            (message.type === 'sms' ||
              message.type === 'mms' ||
              message.type === 'note' ||
              (message.type === 'call' && message.transcriptions)) &&
            message.body
        )
        .slice(-20)
        .map((message) => ({
          message: message.body.slice(0, 1600),
          first_name: message.first_name || message.phone_number,
          direction: message.isOutbound ? 'outbound' : 'inbound',
        })) as IParamsCreateSummaryAnswer['messages'],
    }
  }

  handleRead = async () => {
    try {
      const conversation = this.conversation
      if (!conversation) return

      if (!conversation?.isUnread && !conversation?.isUnreadManual) return

      const { data } = await ConversationsApi.updateByIdRead(conversation.id)
      conversationStore.updateItem(data)
    } catch (e) {
      console.error(e)
    }
  }

  nonGroupItem = <T,>(item: T): T | null => {
    if (this.isGroup) return null

    return item
  }

  getMessageFieldActions = (store: MessageFieldStore) => {
    if (layoutStore.isMobileView) return []
    const actions: (ActionItem | null)[] = [
      {
        iconButtonComponent: <MediaAction />,
      },
      this.nonGroupItem({
        iconButtonComponent: <ConversationSavedRepliesAction noMergeField />,
      }),
      {
        iconButtonComponent: <EmojiAction />,
      },
      {
        iconButtonComponent: <AIAssistantAction aiAssistantStore={this._aiAssistantStore} />,
      },
      {
        dropdownItemComponent: (onCloseMenu) => (
          <ShortLinkDropdownItem
            onOpenModal={() => {
              const messageInnerText = this._messageFieldStore.messageInnerText

              shortLinkStore.setIsMarketing(false)
              shortLinkStore.setMessageText(messageInnerText)
            }}
            onAddShortLink={(link) => {
              store.addContent && store.addContent(replaceLink(link) + '&nbsp;')
            }}
            onCloseMenu={onCloseMenu}
          />
        ),
        iconButtonComponent: <ShortenLinkAction />,
      },
      this.nonGroupItem({
        dropdownItemComponent: () => (
          <MergeFieldsDropdownItem
            onAddMergeField={(filed) => {
              store?.addContent && store?.addContent(filed.value + '&nbsp;')
              return
            }}
            contact={this.contact}
            integration={this.integration}
          />
        ),
      }),
      {
        iconButtonComponent: <MessageSignatureIconButton store={this._messageSignatureStore} />,
        dropdownItemComponent: (onCloseMenu) => (
          <MessageSignatureDropdownItem
            store={this._messageSignatureStore}
            onCloseMenu={onCloseMenu}
          />
        ),
      },
    ]

    return actions.filter((action) => !!action) as ActionItem[]
  }

  reactionOnGroup = () => {
    this._disposeOnGroup?.()
    this._disposeOnGroup = reaction(
      () => this.isGroup,
      () => {
        this._messageFieldStore?.setActions(this.getMessageFieldActions(this._messageFieldStore))
      }
    )
  }

  setConversationId = async (conversationId: number, isLoading = true) => {
    if (this._currentConversationId === conversationId) return
    this._currentConversationId = conversationId
    this.handleSetDraft()

    if (isLoading) {
      this.loadData()
    }

    if (this.showDoubleOptInButton) {
      this._messageFieldStore.setDisabledMessageField(this.showDoubleOptInButton)
    }
  }

  resetMessage = () => {
    this._itemsMap.clear()
  }

  loadConversation = async () => {
    try {
      runInAction(() => {
        this._loadingConversation = true
      })

      const id = Number(this.conversationId)

      if (!id) return

      const conversation = await conversationStore.getById({
        id,
        isModalError: true,
        isFetch: true,
        update: true,
      })

      inboxesStore.fetchInboxes().then(() => {
        if (
          conversation?.inbox_id &&
          inboxesStore.currentInbox?.type !== 'unified-inbox' &&
          conversation?.inbox_id !== inboxesStore.currentInboxId
        ) {
          inboxesStore.handleUpdateTeamInbox(conversation?.inbox_id)
        }
      })

      if (conversation) {
        conversationStore.addItemDraft(conversation.origin.draft_message)
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loadingConversation = false
      })
    }
  }

  loadMessages = async (page?: number) => {
    try {
      if (!this.conversationId) {
        this.setLoading(false)
        return
      }
      if (this._loadingMessages) return
      runInAction(() => {
        this._loadingMessages = true
        this._page = page || 1
      })

      this.initCancelTokenSource()

      const response = await MessagesApi.getMessagesByConversationId(
        {
          conversationId: +this.conversationId,
          page: this._page,
          per_page: this._per_page,
          with_activity_logs: this._isShowActivity ? 1 : 0,
        },
        {
          cancelToken: this._cancelTokenSource?.token,
        }
      )

      if (response?.data) {
        runInAction(() => {
          this.setItems(response.data.data)
          this._hasPrevious = response.data.total > this.allMessages.length
        })
      }

      runInAction(() => {
        this._loadingMessages = false
      })
      this.setLoading(false)
    } catch (e) {
      console.error(e)
      if (isCancel(e) && !this.isConversationNew) {
        runInAction(() => {
          this._loadingMessages = true
        })
        this.setLoading(true)
      } else {
        runInAction(() => {
          this._loadingMessages = false
        })
        this.setLoading(false)
      }
    }
  }

  loadMessagesContact = async ({ before }: { before?: number | string } = {}) => {
    try {
      const selectedContacts = this._conversationNewSearchStore?.items || []
      const selectedInboxId = this._conversationNewSearchStore?.activeInboxId
      let messages: IResponseMessage[] = []
      let hasPrevious = false

      if (this.loading) return
      if (!selectedContacts.length) return
      if (!selectedInboxId) return

      this.setLoading(true)

      if (selectedContacts.length === 1) {
        const contact = selectedContacts[0]
        const params: IParamsMessagesGetContactsByIdTeamsById = {
          grouped_response: true,
          limit: 20,
        }

        if (before) {
          params.before = before
        }

        this.initCancelTokenSource()

        const { data } = await MessagesApi.getMessagesContactsByIdTeamsById(
          contact.id,
          selectedInboxId,
          params,
          {
            cancelToken: this._cancelTokenSource?.token,
          }
        )

        messages = data.messages
        hasPrevious = data.hasPrevious
      } else {
        const { data } = await MessagesApi.getMessagesContacts({
          contacts: selectedContacts.map((item) => item.id),
          teamId: selectedInboxId,
          limit: 20,
          before,
        })

        messages = data
        hasPrevious = data.length === 20
      }

      if (messages) {
        runInAction(() => {
          this.setItems(messages)

          this._hasNext = false
          this._hasPrevious = hasPrevious
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoading(false)
    }
  }

  loadData = async () => {
    if (this.isConversationNew) {
      this.initCancelTokenSource()
      this.setLoading(false)
    } else {
      await this.loadMessages()
    }
  }

  loadPreviousMessages = async (onSuccess?: () => void) => {
    if (!this.hasPrevious) return
    if (this._loadingPrevious) return

    runInAction(() => {
      this._loadingBeforeId = this.beforeId
      this._loadingPrevious = true
    })

    try {
      if (this.isConversationNew) {
        await this.loadMessagesContact({
          before: this._loadingBeforeId,
        })
      } else {
        await this.loadMessages(this._page + 1)
      }

      onSuccess?.()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loadingPrevious = false
      })
    }
  }

  async sendMessage({
    message,
    conversationId,
  }: {
    message: IParamsCreateMessage
    conversationId?: number
  }) {
    const client_id = nanoid()
    const sendingMessage = new Message(undefined, {
      requestMessage: {
        media_url: message.media_url,
        body: message.message,
        send_at: message.send_at,
        type: message.type,
        conversation_id: conversationId || +this.conversationId,
      },
      client_id,
    })

    runInAction(() => {
      this._itemsMap.set(client_id, sendingMessage)
    })

    if (this._handleReceiveNewScroll) this.setScrollBottomTrigger()

    try {
      const payload: IParamsCreateMessage = {
        ...message,
        client_id,
      }

      const { data } = await MessagesApi.createMessage(
        conversationId || +this.conversationId,
        payload
      )

      runInAction(() => {
        this._sendingMessagesIdsMap.set(data.id, client_id)
        this._messageFieldStore.clearSendAlert()
      })

      if (data) {
        const { data: updatedConversation } = await ConversationsApi.updateByIdRead(
          conversationId || this.conversationId
        )

        conversationStore.updateItem(updatedConversation)

        if (conversationStore.currentItemId === data.conversation_id) {
          this.setItems([{ ...data, client_id }])
          if (this._handleReceiveNewScroll) this.setScrollBottomTrigger()
        }
      }
    } catch (error) {
      runInAction(() => {
        const alert = getSendMessageAlert(error)
        this._messageFieldStore.setSendAlert(alert)
        this._itemsMap.delete(client_id)
        console.error(error)
      })
    }
  }

  async updateMessage({
    message,
    messageId,
  }: {
    message: IParamsUpdateMessage
    messageId: number
  }) {
    try {
      runInAction(() => {
        const clientId = this._sendingMessagesIdsMap.get(messageId) || messageId
        const oldMessage = this._itemsMap.get(clientId)
        if (oldMessage && oldMessage instanceof Message) {
          oldMessage.sending = true
          this._itemsMap.set(clientId, oldMessage)
        }
      })
      const { data } = await MessagesApi.updateMessage(messageId, message)
      if (data) {
        this.setItems([data])
        this.setScrollBottomTrigger()
      }
    } catch (e) {
      console.error(e)
    }
  }

  setItems = (items: Array<IResponseMessage | IResponseActivity>) => {
    items.forEach((message) => {
      if (message.item_type === 'message' && message.client_id) {
        this._sendingMessagesIdsMap.set(message.id, message.client_id)
      }
    })

    const iItems = items.map((item) => {
      if (item.item_type === 'activity_log') {
        return new Activity(item)
      }
      const client_id = this._sendingMessagesIdsMap.get(item.id) || item.id
      return this.addItem(item, client_id)
    })

    let links: string[] = []

    try {
      iItems.forEach((item) => {
        if (item instanceof Message) {
          links = links.concat(item.links)
          this._itemsMap.set(item.client_id, item)
        } else {
          this._itemsMap.set(item.id, item)
        }
      })
    } catch (e) {
      console.error(e)
    }

    if (this._handleReceiveNewScroll) {
      this.setScrollBottomTrigger()
    }
    attachmentStore.checkPreviousAttachment(links)
  }

  setHandleReceiveNewScroll = (value: boolean) => {
    this._handleReceiveNewScroll = value
  }

  updateTranscript = (item: IResponseMessage) => {
    const message = this._itemsMap.get(item.id)
    if (message instanceof Message) {
      message.setTranscript(item)
    }
  }

  updateTranscriptStatus = (id: number, status: string) => {
    const message = this._itemsMap.get(id)
    if (message instanceof Message) {
      message.setTranscriptStatus(status)
    }
  }

  updateRecordTranscriptStatus = (id: number, status: IResponseRecordTranscriptStatus) => {
    const message = this._itemsMap.get(id)
    if (message instanceof Message) {
      message.setRecordTranscriptStatus(status)
    }
  }

  addItem = (item: IResponseMessage, client_id: string | number) => {
    const message = new Message({ ...item, client_id })

    if (Array.isArray(item.pending_mentions)) {
      item.pending_mentions.forEach((item) => {
        usersStore.addItem(item)
      })
    }

    return message
  }

  async retry(id: number | string) {
    try {
      const { data } = await MessagesApi.retry(id)
      this.setItems([data])
    } catch (e) {
      console.error(e)
    }
  }

  async delete(id: number) {
    try {
      await MessagesApi.deleteMessage(id)
      const clientId = this._sendingMessagesIdsMap.get(id) || id
      this._itemsMap.delete(clientId)
      if (!this.isConversationNew) this.handleRead()
    } catch (e) {
      console.error(e)
    }
  }

  setScrollBottomTrigger = () => {
    this._scrollBottomTrigger = nanoid()
  }

  setScheduleMessage = (value: boolean) => {
    this._isScheduleMessage = value
  }

  setIsEditMessage = (isEditMessage: boolean) => {
    this._isEditMessage = isEditMessage
  }

  onToggleActivity = () => {
    this.reset()

    this._isShowActivity = !this._isShowActivity

    userSettingsStore.updateSetting({
      value: this._isShowActivity,
      featureKey: 'conversation',
      settingsKey: 'show_activity',
    })
    this.loadData()
  }

  onToggleUndoMessage = () => {
    this._isClickToUndo = !this._isClickToUndo

    userSettingsStore.updateSetting({
      value: this._isClickToUndo,
      featureKey: 'message-form',
      settingsKey: 'click_to_undo',
    })
  }

  private _canSendTyping = true
  private _throttleTime = 1000

  onTyping = () => {
    if (this._canSendTyping) {
      websocket.sendEvent(
        `private-inbox.${this.conversation?.inbox_id}.conversation`,
        'client-typing',
        {
          id: usersStore.user?.id,
          first_name: usersStore.user?.first_name,
          last_name: usersStore.user?.last_name,
          conversation_id: this.conversation?.id,
        }
      )
      this._canSendTyping = false
      setTimeout(() => {
        this._canSendTyping = true
      }, this._throttleTime)
    }
  }

  setIsActiveTab = (value: boolean) => {
    this._isActiveTab = value
  }

  onViewing = () => {
    if (this._onViewingTimerId) clearTimeout(this._onViewingTimerId)
    if (this._canSendViewing && this._isActiveTab && !!this.conversation?.id) {
      websocket.sendEvent(
        `private-inbox.${this.conversation?.inbox_id}.conversation`,
        'client-viewing',
        {
          id: usersStore.user?.id,
          first_name: usersStore.user?.first_name,
          last_name: usersStore.user?.last_name,
          conversation_id: this.conversation?.id,
        }
      )
      this._canSendViewing = false

      this._onViewingTimerId = setTimeout(() => {
        this._canSendViewing = true
        this.onViewing()
      }, this._throttleViewingTime)
    }
  }

  handleTypingEvent = (data: IResponseEventTyping) => {
    if (data.conversation_id !== Number(this.conversationId)) return

    const timerId = setTimeout(() => {
      this._typingsMap.delete(data.id)
      this.updateViewingItemStatus(data.id, 'viewing')
    }, 2000)
    const newTyping = new Typing(data, timerId)
    const oldTyping = this._typingsMap.get(newTyping.userId)
    if (oldTyping) {
      clearTimeout(oldTyping.timerId)
    }
    this._typingsMap.set(newTyping.userId, newTyping)
    this.updateViewingItemStatus(data.id, 'typing')
  }

  handleViewingEvent = (data: IResponseEventViewing) => {
    if (data.conversation_id !== Number(this.conversationId)) return

    const timerId = setTimeout(() => {
      this._viewingsMap.delete(data.id)
    }, 35000)
    const status: IViewingStatus = this._typingsMap.get(data.id) ? 'typing' : 'viewing'

    const newViewing = new Viewing(data, timerId, status)
    const oldViewing = this._viewingsMap.get(newViewing.userId)
    if (oldViewing) {
      clearTimeout(oldViewing.timerId)
    }
    this._viewingsMap.set(newViewing.userId, newViewing)
  }

  updateViewingItemStatus = (id: number, status: IViewingStatus) => {
    const current = this._viewingsMap.get(id)
    if (current) {
      current.status = status
    }
  }

  // draft
  get stateDraft() {
    const draft = conversationStore.getItemDraft(this.conversationId)
    const draftMessage = draft?.message
    const formDraftMessage = this._messageFieldStore.messageDraftData
    const isDifferenceDataMessage = !isEqual(draftMessage, formDraftMessage)
    const isLocalDraft = !document.hidden

    if (this._messageFieldStore.mode === 'note') return false
    if (this._loadingConversation) return false
    if (isLocalDraft && this._messageFieldStore.isEmptyDraft && !this._isSetDraft && draft)
      return 'delete'
    if (isLocalDraft && !this._messageFieldStore.isEmptyDraft && isDifferenceDataMessage)
      return 'create'
    if (!isLocalDraft && draft) return 'update'

    return 'pending'
  }

  reactionConversationDraftMessage = () => {
    this._disposeConversationDelete?.()
    this._disposeConversationCreate?.()
    this._disposeConversationUpdate?.()
    this._disposeModeNote?.()

    this._disposeConversationDelete = reaction(
      () => this._messageFieldStore.isEmptyDraft,
      () => {
        if (this.stateDraft === 'delete') {
          this.handleDeleteDraft()
        }
      }
    )

    this._disposeConversationCreate = reaction(
      () => this._messageFieldStore.messageDraftData,
      () => {
        if (this.stateDraft === 'create') {
          this.handleCreateDraft()
        }
      }
    )

    this._disposeConversationUpdate = reaction(
      () => conversationStore.getItemDraft(this.conversationId),
      async () => {
        if (this.stateDraft === 'update') {
          this.handleUpdateDraft()
        }
      }
    )

    this._disposeModeNote = reaction(
      () => this._messageFieldStore.mode,
      async (value) => {
        this._isModeNoteObj.value = value === EnumMessageFieldMode.Note
        if (value === 'note') {
          this.handleResetDraft()
        }
      }
    )
  }

  handleCreateDraft = () => {
    if (!this.conversation?.id) return

    if (this._messageFieldStore.isEmptyDraft || this._isScheduleMessage) {
      this.conversation?.debounceOnCreateDraft?.cancel()

      return
    }

    this.conversation?.debounceOnCreateDraft?.(this._messageFieldStore.messageDraftData)
  }

  handleUpdateDraft = () => {
    this._debounceOnUpdateDraft?.()
  }

  handleSetDraft = () => {
    this._onSetDraft()
  }

  handleDeleteDraft = () => {
    this.conversation?.debounceOnCreateDraft?.cancel()

    this._debounceOnDeleteDraft?.()
  }

  handleResetDraft = () => {
    this.conversation?.debounceOnCreateDraft?.cancel()
    this._debounceOnUpdateDraft?.cancel()
    this._debounceOnDeleteDraft?.cancel()

    this._onDeleteDraft()
  }

  private _onDeleteDraft = () => {
    const draft = conversationStore.getItemDraft(this.conversationId)

    if (draft) {
      logger.info('DELETE [Draft]')

      const params: IParamsDeleteMessagesDraft = {
        conversation_id: draft.conversation_id,
        inbox_id: draft.inbox_id,
        inbox_type: draft.inbox_type,
      }

      conversationStore.deleteItemDraft(draft.conversation_id)
      MessagesApi.deleteMessagesDraft(params)
    }
  }

  private _onSetDraft = async () => {
    const draft_message = conversationStore.getItemDraft(this.conversationId)

    if (draft_message) {
      logger.info('UPDATE [Draft]')

      runInAction(() => {
        this._isSetDraft = true
      })

      if (!recentStore.recentAttachments.length) await recentStore.checkParamsAndLoadRecent()

      const attachments = draft_message.attachments

      const messageData: MessageData = {
        message: draft_message.body,
        attachments: attachments,
        isReset: true,
        isFocus: !uiStore.isFocusingMode && !layoutStore.isMobileView,
        isReplace: true,
      }

      await this._messageFieldStore.setMessageData(messageData)

      runInAction(() => {
        this._isSetDraft = false
      })
    }
  }

  handleContactOptInRequest = async () => {
    try {
      this._messageFieldStore.setLoading(true)

      const conversationId = conversationStore.currentItem?.id

      if (!conversationId) return

      const { data } = await ContactsApi.updateContactOptInRequestById(conversationId)

      contactsStore.updateItem(data.contact)
      this.handleRead()
    } catch (e) {
      console.error(e)
    } finally {
      this._messageFieldStore.setLoading(false)
    }
  }

  get disabledDoubleOptIn() {
    const number_id = this.conversation?.number_id

    return Boolean(
      this.loading ||
        !userPermissionsStore.getItem('can_manage_contacts') ||
        this.contact?.getReceivedOptInRequestFromNumber(number_id) ||
        this.contact?.opt_out
    )
  }

  get isSmallAirCallAttach() {
    return this._messageFieldStore.isSmallAirCallAttach
  }

  setHideTrialAlertNotForOwner = () => {
    this._isHideTrialAlertNotForOwner = true
  }

  get callModalStore() {
    return this._callModalStore
  }

  get conversationListStore() {
    return this._conversationListStore
  }

  get conversationSearchStore() {
    return this._conversationSearchStore
  }

  get conversationNewSearchStore() {
    return this._conversationNewSearchStore
  }

  get hasPrevious() {
    return this._hasPrevious
  }

  get loading() {
    return this._loading
  }

  get loadingPrevious() {
    return this._loadingPrevious
  }

  get loadingMessages() {
    return this._loadingMessages
  }

  get loadingConversation() {
    return this._loadingConversation
  }

  get messageFieldStore() {
    return this._messageFieldStore
  }

  get scrollBottomTrigger() {
    return this._scrollBottomTrigger
  }

  get isShowActivity() {
    return this._isShowActivity
  }

  get isHideTrialAlertNotForOwner() {
    return this._isHideTrialAlertNotForOwner
  }

  get currentConversationId() {
    return this._currentConversationId
  }

  get loadingBeforeId() {
    return this._loadingBeforeId
  }

  get messageSignatureStore() {
    return this._messageSignatureStore
  }

  get isEditMessage() {
    return this._isEditMessage
  }

  get isClickToUndo() {
    return this._isClickToUndo
  }

  isHideAvatars = false
  setHideAvatars = (value: boolean) => {
    this.isHideAvatars = value
  }

  onBack = () => {
    if (this._conversationSearchStore?.hasSearchParams) {
      this._conversationSearchStore.setHide(false)
    }
    this._pageLayoutStore?.toStartContainer()
    this._messageFieldStore.setBlurMessageFieldTrigger()
  }
}
