import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import { getTrialLimitDisabledLabel } from 'widgets/ContactsTable/helpers/helpers'
import { ContactsTableStore } from 'widgets/ContactsTable'

type ITrialAlertProps = {
  contactsTableStore: ContactsTableStore
}

export const TrialAlert: FC<ITrialAlertProps> = observer(({ contactsTableStore }) => {
  if (
    !subscriptionStore.isTrial ||
    contactsTableStore.totalAmount <= subscriptionStore.trialActiveContactsCount
  ) {
    return null
  }

  const upgradeAllowedRole = usersStore.user?.isAdminOrOwnerRole

  return (
    <Alert
      marginInline={20}
      item={{
        type: 'warning',
        desc: getTrialLimitDisabledLabel(),
      }}
      action={
        upgradeAllowedRole ? (
          <Button
            typeBtn='action'
            size='mini'
            text='Upgrade'
            onClick={() => {
              uiStore.changeRoute({
                path: '/settings/billing/overview/upgrade',
                type: 'vue',
              })
            }}
          />
        ) : null
      }
    />
  )
})
