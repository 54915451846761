import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { KeywordPageStore } from 'pages/keywords'
import { KeywordsPageContext } from '../context/keywordsPageContext'

const items: IMenuItemProps[] = [
  {
    type: 'nav',
    to: '/keywords',
    label: 'All keywords',
    icon: 'keywords',
  },
]

export const KeywordsPageLayout = observer(() => {
  const [keywordsStore] = useState(() => new KeywordPageStore())

  return (
    <KeywordsPageContext.Provider value={keywordsStore}>
      <PageLayout
        leftContent={<Menu title='Keywords' menuItems={items} />}
        store={keywordsStore.pageLayoutStore}
      />
    </KeywordsPageContext.Provider>
  )
})
