import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import styles from './styles.module.scss'

export const KnowledgeBaseActions = observer(() => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  return (
    <div className={styles.actionsContainer}>
      <Button
        text='Upload files'
        iconProps={{ icon: 'upload' }}
        size='medium'
        onClick={handleUploadClick}
      />
      <input type={'file'} className={styles.fileInput} ref={inputRef} multiple />
      <Button
        tag='a'
        text='Help guide'
        size='medium'
        contained='tertiary'
        className={styles.helpButton}
        href={links.knowledgeBaseHelpGuide}
        target='_blank'
        rel='noreferrer'
      />
    </div>
  )
})
