import { createContext, useContext } from 'react'
import type { KnowledgeBaseViewStore } from 'pages/settings/pages/knowledgeBaseView/store/knowledgeBaseViewStore'

export const KnowledgeBaseViewContext = createContext<KnowledgeBaseViewStore | null>(null)

export const useKnowledgeBaseViewContext = () => {
  const context = useContext(KnowledgeBaseViewContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with KnowledgeBaseViewContext')

  return context
}
