import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import type { IComplianceSettingsStore } from 'entities/Compliance'
import { BusinessProfileContainer } from './ui/BusinessProfileContainer'
import {
  BusinessProfileProvider,
  useBusinessProfileContext,
} from './context/businessProfileContext'

interface IBusinessProfileProps {
  complianceSettingsStore: IComplianceSettingsStore
}
const BusinessProfileContent = observer(() => {
  const { complianceSettingsStore, setDataFromBusinessProfileRequest, setErrors } =
    useBusinessProfileContext()
  const {
    businessProfileFormStep,
    businessInformation,
    pointsOfContact,
    businessProfile,
    businessProfileDataLoading,
  } = complianceSettingsStore
  const { setStep } = complianceSettingsStore.stepsStore

  useEffect(() => {
    setStep(businessProfileFormStep === 0 ? 1 : businessProfileFormStep + 2)
  }, [businessProfileFormStep])

  useEffect(() => {
    businessInformation &&
      setDataFromBusinessProfileRequest({ businessInformation, pointsOfContact })
  }, [businessInformation, pointsOfContact])

  useEffect(() => {
    setErrors(businessProfile?.twilio_service_errors)
  }, [businessProfile?.twilio_service_errors])

  if (businessProfileDataLoading) {
    return <SpinnerLoader />
  }

  return <BusinessProfileContainer />
})

export const BusinessProfile = ({ complianceSettingsStore }: IBusinessProfileProps) => {
  return (
    <BusinessProfileProvider complianceSettingsStore={complianceSettingsStore}>
      <BusinessProfileContent />
    </BusinessProfileProvider>
  )
}
