import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponseIntegrationDisconnected } from 'entities/Integrations'
import { integrationsStore } from 'entities/Integrations/store/integrations'

const IntegrationDisconnected = (data: IResponseIntegrationDisconnected) => {
  integrationsStore.syncUserIntegration(data.userIntegration)
}

export const useEventIntegrationDisconnected = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.IntegrationDisconnected, IntegrationDisconnected)
    }
  }, [websocket.isConnected])
}
