import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import { KeywordsRoutesEnum } from 'entities/Keywords'

import styles from './styles.module.scss'

export const KeywordNewButton = observer(() => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/${KeywordsRoutesEnum.root}/${KeywordsRoutesEnum.create}`)
  }

  return (
    <Button text='New keyword' iconProps={{ icon: 'add' }} size='medium' onClick={handleClick} />
  )
})

export const KeywordsHelpGuideButton = () => (
  <Button
    tag='a'
    text='Help guide'
    size='medium'
    contained='tertiary'
    className={styles.helpButton}
    href={links.keywordsHelpGuide}
    target='_blank'
    rel='noreferrer'
    iconProps={{ icon: 'info' }}
  />
)
