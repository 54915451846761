import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { toastStore } from 'shared/ui'
import {
  BroadcastOneTime,
  BroadcastRecurring,
  BroadcastRecurringChild,
  BroadcastRoutesEnum,
  IBroadcast,
} from 'entities/Broadcast'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { IParamsTestBroadcast } from 'entities/Broadcast/api/types'
import { IContactsStatisticFilter } from 'entities/ContactStatistic'
import { BroadcastDetailModal } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/BroadcastDetailModal'
import { exportStore } from 'widgets/Export/store/ExportStore'
import { RenameModalStore } from 'widgets/RenameModal'
import { StopRecurringChildActions } from 'widgets/BroadcastActions/ui/StopRecurringChild/StopRecurringChildActions'

class BroadcastActionsStore {
  constructor() {
    makeAutoObservable(this)
  }
  onDetails = (broadcast: BroadcastOneTime) => {
    modalStore.addModal({
      id: nanoid(),
      width: 540,
      position: 'right',
      type: ModalTypeList.DEFAULT,
      ModalContentProps: { broadcast },
      ModalContent: BroadcastDetailModal,
      title: 'Details',
      pureContent: true,
      fullHeight: true,
    })
  }

  onEditRecurringChild = (broadcast: BroadcastRecurringChild) => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.INFO,
      title: 'Edit broadcast',
      desc: 'You can edit just this occurrence or modify the whole series.',
      primaryAction: {
        text: 'Edit all future broadcasts',
        onAction: () => {
          modalStore.closeModal(modalId)
          uiStore.changeRoute({
            path: `/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.edit}/${broadcast.parentId}`,
          })
        },
      },
      additionalSecondaryAction: {
        text: 'Edit only this broadcast',
        onAction: () => {
          modalStore.closeModal(modalId)
          uiStore.changeRoute({
            path: `/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.edit}/${broadcast.id}`,
          })
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(modalId)
        },
      },
    })
  }

  onTestSMSBroadcast = (params: IParamsTestBroadcast) => BroadcastApi.createTest(params)

  onPause = async (broadcast: IBroadcast) => {
    try {
      if (broadcast instanceof BroadcastOneTime) {
        const { data } = await BroadcastApi.pauseOneTime(broadcast.id)
        broadcast.syncOrigin(data)
      } else {
        const { data } = await BroadcastApi.pauseRecurring(broadcast.id)
        broadcast.syncOrigin(data)
      }
      toastStore.add({
        type: 'info',
        title: 'Broadcast paused',
      })
    } catch (e) {
      console.error(e)
    }
  }
  onResume = async (broadcast: IBroadcast) => {
    try {
      if (broadcast instanceof BroadcastOneTime) {
        const { data } = await BroadcastApi.resumeOneTime(broadcast.id)
        broadcast.syncOrigin(data)
      } else {
        const { data } = await BroadcastApi.resumeRecurring(broadcast.id)
        broadcast.syncOrigin(data)
      }
      toastStore.add({
        type: 'info',
        title: 'Broadcast resumed',
      })
    } catch (e) {
      console.error(e)
    }
  }
  onStop = async (broadcast: IBroadcast) => {
    try {
      if (broadcast instanceof BroadcastOneTime) {
        const { data } = await BroadcastApi.stopOneTime(broadcast.id)
        broadcast.syncOrigin(data)
      } else {
        const { data } = await BroadcastApi.stopRecurring(broadcast.id)
        broadcast.syncOrigin(data)
      }
      toastStore.add({
        type: 'info',
        title: 'Broadcast stopped',
      })
    } catch (e) {
      console.error(e)
    }
  }

  onStopRecurringChild = (broadcast: BroadcastRecurringChild) => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.INFO,
      title: 'Stop broadcast',
      desc: 'You can edit just this occurrence or modify the whole series.',
      ModalActions: StopRecurringChildActions,
      ModalContentProps: {
        onStopAll: async () => {
          try {
            const { data } = await BroadcastApi.stopAllFutureRecurringChild(broadcast.id)
            broadcast.syncOrigin(data)
            toastStore.add({
              type: 'info',
              title: 'All future broadcasts stopped',
            })
          } catch (e) {
            console.error(e)
          }
        },
        onStopOnly: async () => {
          try {
            const { data } = await BroadcastApi.stopOneTime(broadcast.id)
            broadcast.syncOrigin(data)
            toastStore.add({
              type: 'info',
              title: 'Broadcast stopped',
            })
          } catch (e) {
            console.error(e)
          }
        },
        onCancel: () => modalStore.closeModal(modalId),
      },
    })
  }
  onExport = (broadcast: IBroadcast, filter: IContactsStatisticFilter = 'sent_to') => {
    exportStore.getExport({
      promise: BroadcastApi.getExport(broadcast.id, filter),
    })
  }

  onDelete = (broadcast: IBroadcast, onSuccess?: () => void) => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Delete broadcast?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            if (broadcast instanceof BroadcastOneTime) {
              await BroadcastApi.deleteOneTime(broadcast.id)
            } else {
              await BroadcastApi.deleteRecurring(broadcast.id)
            }
            toastStore.add({
              type: 'success',
              title: 'Broadcast deleted',
            })
            onSuccess && onSuccess()
            modalStore.closeModal(id)
          } catch (e) {
            console.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }

  onRename = (broadcast: BroadcastRecurring) => {
    new RenameModalStore({
      element: 'Broadcast',
      isRenamedToast: true,
      name: broadcast.name,
      onSave: async (name: string) => {
        const { data } = await BroadcastApi.renameRecurring(broadcast.id, name)
        broadcast.syncOrigin(data)
      },
    })
  }
}

export const broadcastActionsStore = new BroadcastActionsStore()
