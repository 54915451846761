import { makeAutoObservable } from 'mobx'

export enum IOptInMethodTypes {
  onlineForm = 'ONLINE_FORM',
  paperForm = 'PAPER_FORM',
  text = 'TEXT',
  qrCode = 'QR_CODE',
}

export class OptInMethodStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _optInMethods: IOptInMethodTypes[] = []

  get optInMethods() {
    return this._optInMethods
  }

  get isStep3Disabled() {
    return !this.optInMethods.length
  }

  setOptInMethods = (value: IOptInMethodTypes) => {
    this.optInMethods.includes(value)
      ? (this._optInMethods = this.optInMethods.filter((item) => item !== value))
      : (this._optInMethods = [...this.optInMethods, value])
  }

  isOptInMethodActive = (value: IOptInMethodTypes) => this.optInMethods.includes(value)
}
