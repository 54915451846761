import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { bannerStore, EnumAlertBannerVariant } from 'shared/ui'
import { storeCache } from 'shared/api/storeCache'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { IS_APP_LIGHT } from 'shared/config'
import {
  type IParamsSecondaryRegister,
  type IParamsAcceptAgencyInvite,
  type IParamsAcceptMemberInvite,
  type IParamsLogin,
  type IParamsRegister,
  type IParamsSendTwoFaCode,
  type IParamsVerifyTwoFaCode,
  type IResponseAuthRefresh,
  type IResponseToken,
} from 'entities/Auth'
import { AuthRoutesEnum } from 'entities/Auth/routes/authRoutesEnum'
import { AuthApi } from 'entities/Auth/api'
import {
  getAssumeToken,
  getToken,
  removeTokenLocalStorage,
  setToken,
} from 'entities/Auth/lib/setToken'
import { websocket } from 'entities/WebSocket'
import { resetPosthog } from 'entities/Posthog'
import { clearSessionIntercom } from 'entities/Intercom'
import { usersStore } from 'entities/Users'
import { sessionManager } from '../lib/sessionManager'
import { GoogleAuthStore } from './googleAuth'
import { IResponseGoogleLogin } from './types'

export class AuthStore {
  private _idOrgModal = 'orgModal'
  private _error = ''
  private _loading = true
  private _isAssume = false
  private _isLoggedIn = false

  constructor() {
    makeAutoObservable(this)
    this.checkAssuming()
    this.reactionOnLoginFromAppLight()
  }

  get isLoggedIn() {
    return this._isLoggedIn
  }

  get hasOid() {
    const token = getToken()
    if (!token) return false

    const [, tokenData] = token.split('.')
    const parsedData = JSON.parse(atob(tokenData))

    return !!parsedData.oid
  }

  socialLoginCallback = (payload: IResponseGoogleLogin) => {
    this.socialLogin(payload.token)
  }

  isNewSession = false
  googleAuthStore: GoogleAuthStore = new GoogleAuthStore(this.socialLoginCallback)

  external_source = ''
  closeAfterLogin = false

  private _disposeReactionOnLoginFromAppLight: IReactionDisposer | null = null

  reactionOnLoginFromAppLight = () => {
    this._disposeReactionOnLoginFromAppLight?.()
    this._disposeReactionOnLoginFromAppLight = reaction(
      () => this.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          if (this.closeAfterLogin) {
            setTimeout(() => {
              window.close()
            }, 100)
          }
        }
      }
    )
  }

  setSuccessAuthToken = (token: string) => {
    this.setToken(token)
    this.setLoggedIn(true)
    this.isNewSession = sessionManager.syncSession(true)
  }

  validateToken = (token: string): boolean => {
    const [, tokenData] = token.split('.')
    const parsedData = JSON.parse(atob(tokenData))

    if (!parsedData.oid) {
      modalStore.addModal({
        id: this._idOrgModal,
        type: ModalTypeList.WARNING,
        title: 'Oh-oh! Looks like the Salesmsg account you were part of is no longer available.',
        primaryAction: {
          text: 'Create a new account',
          onAction: () => {
            modalStore.closeModal(this._idOrgModal)
            uiStore.changeRoute({
              path: `${AuthRoutesEnum.registerMultiOrg}`,
            })
          },
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: () => {
            modalStore.closeModal(this._idOrgModal)
            authStore.logout()
          },
        },
      })
    }

    return !!parsedData.oid
  }

  async login(body: IParamsLogin) {
    const { data } = await AuthApi.login(body)

    if (data.data?.is_2fa) {
      return data.data.settings
    }

    if (data.token) {
      this.validateToken(data.token.access_token)
      this.setSuccessAuthToken(data.token.access_token)
    }
  }

  socialLogin(data: IResponseToken) {
    if (!this.validateToken(data.access_token)) return
    this.setSuccessAuthToken(data.access_token)
  }

  setGoogleAuthStore = (data: IResponseToken) => {
    this.setSuccessAuthToken(data.access_token)
  }

  async register(body: IParamsRegister) {
    const { data } = await AuthApi.register(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  async registerSecondary(payload: IParamsSecondaryRegister) {
    if (this.hasOid) return AuthApi.registerSecondary(payload)
    return AuthApi.registerSecondaryByProfile(payload)
  }

  async acceptMemberInvite(body: IParamsAcceptMemberInvite) {
    const { data } = await AuthApi.acceptMemberInvite(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  async acceptAgencyInvite(body: IParamsAcceptAgencyInvite) {
    const { data } = await AuthApi.acceptAgencyInvite(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  checkAssuming = () => {
    this._isAssume = !!getAssumeToken()

    if (this._isAssume) {
      bannerStore.add({
        id: nanoid(),
        action: {
          text: 'Stop assuming',
          onAction: () => {
            window.close()
          },
        },
        alert: {
          title: 'You have assumed another user’s account',
          variant: EnumAlertBannerVariant.Warning,
          ariaLabel: 'Assuming',
        },
      })
    }
  }

  setToken = (token: string) => {
    setToken(token)
  }
  removeToken = () => {
    removeTokenLocalStorage(this._isAssume)
  }
  setLoggedIn = (value: boolean) => {
    this._isLoggedIn = value
  }
  logout = async () => {
    storeCache.dispose()
    clearSessionIntercom()

    if (this._isAssume) {
      window.close()
      return
    }
    try {
      localStorage.removeItem('showedAwayStatusDate')
      localStorage.removeItem('contactDetailsOpenStatus')
      await AuthApi.logout()
      this.removeToken()

      websocket.disconnect()
      resetPosthog()
      this.setLoggedIn(false)
    } catch (e) {
      console.error(e)
    } finally {
      if (window.location.href.includes('/login') || IS_APP_LIGHT) return

      window.location.href = `/${AuthRoutesEnum.login}`
    }
  }

  refresh = async ({ enableLoader = true, isTryRefreshWithoutToken = false } = {}): Promise<
    IResponseAuthRefresh | undefined
  > => {
    try {
      if (enableLoader) {
        runInAction(() => {
          this._loading = true
        })
      }

      const { data } = await AuthApi.updateAuthRefresh()
      const isLogin = Boolean(data.is_login)

      if (isTryRefreshWithoutToken && !isLogin && getToken()) {
        this.removeToken()
        return await this.refresh()
      }

      runInAction(() => {
        this.setLoggedIn(isLogin)

        if (enableLoader) {
          this.isNewSession = sessionManager.isNewSession()
          sessionManager.syncSession(false)
        }
      })

      return data
    } catch (e) {
      runInAction(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._error = e.stack || 'Error'
      })
    } finally {
      if (enableLoader) {
        runInAction(() => {
          this._loading = false
        })
      }
    }
  }

  sendTwoFaCode = async (params: IParamsSendTwoFaCode) => {
    const { data } = await AuthApi.sendTwoFaCode(params)
    return data
  }

  verifyTwoFaCode = async (params: IParamsVerifyTwoFaCode) => {
    const { data } = await AuthApi.verifyTwoFaCode(params)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  setExternalSource = (external_source: string) => {
    this.external_source = external_source
  }

  setCloseAfterLogin = (value: boolean) => {
    this.closeAfterLogin = value
  }

  get isAssume() {
    return this._isAssume
  }

  get error() {
    return this._error
  }
}

export const authStore = new AuthStore()
