import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import {
  type ActionItem,
  ContainerHeader,
  FullPageWrapper,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBaseView/context/knowledgeBaseViewContext'
import { KnowledgeBaseActions } from 'pages/settings/pages/knowledgeBaseView/ui/KnowledgeBaseActions'
import { DocumentsList } from 'pages/settings/pages/knowledgeBaseView/ui/DocumentsList/DocumentsList'
import { KnowledgeBaseViewEmpty } from './KnowledgeBaseViewEmpty'
import styles from './styles.module.scss'

export const KnowledgeBaseView = observer(() => {
  const { isInitialLoading, isEmpty, loadKnowledgeBaseInitialData } = useKnowledgeBaseViewContext()

  useEffect(() => {
    void loadKnowledgeBaseInitialData()
  }, [])

  const actions: ActionItem[] = useMemo(() => {
    if (isEmpty) {
      return []
    }
    return [
      {
        iconButtonComponent: <KnowledgeBaseActions />,
      },
    ]
  }, [isEmpty])

  return (
    <FullPageWrapper className={styles.container}>
      <ContainerHeader
        customTitle={
          <Typography variant='heading-lg' ariaLabel='KnowledgeBase_title'>
            AI knowledge base
          </Typography>
        }
        paddingTop={64}
        paddingLeft={20}
        paddingBottom={0}
        actions={actions}
      />
      {isInitialLoading ? (
        <SpinnerLoader />
      ) : isEmpty ? (
        <KnowledgeBaseViewEmpty />
      ) : (
        <div className={styles.content}>
          <DocumentsList />
        </div>
      )}
    </FullPageWrapper>
  )
})
