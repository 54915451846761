// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WDENAuCPmFy1MsegVqh5{outline:none;resize:vertical;font-size:13px;padding:8px 12px;border-radius:6px;border:none;box-shadow:inset 0 0 0 1px var(--interaction-neutral-normal);background-color:var(--background-primary-inverted-primary);min-height:80px;max-height:280px}.n_aQPn4VEqccv6PcKObu:not(:focus){box-shadow:inset 0 0 0 1px var(--red-80)}.WDENAuCPmFy1MsegVqh5:focus{box-shadow:inset 0 0 0 1px var(--interaction-brand-active),0 0 0 3px var(--background-brand-subtle)}.WDENAuCPmFy1MsegVqh5::placeholder{color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Textarea/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,WAAA,CACA,4DAAA,CACA,2DAAA,CACA,eAAA,CACA,gBAAA,CAEA,kCACE,wCAAA,CAGF,4BACE,mGAAA,CAIF,mCACE,oCAAA","sourcesContent":[".textarea {\n  outline: none;\n  resize: vertical;\n  font-size: 13px;\n  padding: 8px 12px;\n  border-radius: 6px;\n  border: none;\n  box-shadow: inset 0 0 0 1px var(--interaction-neutral-normal);\n  background-color: var(--background-primary-inverted-primary);\n  min-height: 80px;\n  max-height: 280px;\n\n  &Error:not(:focus) {\n    box-shadow: inset 0 0 0 1px var(--red-80);\n  }\n\n  &:focus {\n    box-shadow: inset 0 0 0 1px var(--interaction-brand-active),\n      0 0 0 3px var(--background-brand-subtle);\n  }\n\n  &::placeholder {\n    color: var(--content-neutral-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "WDENAuCPmFy1MsegVqh5",
	"textareaError": "n_aQPn4VEqccv6PcKObu"
};
export default ___CSS_LOADER_EXPORT___;
