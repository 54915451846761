import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { InputCheckbox, TextFieldWithLabel, Typography } from 'shared/ui'
import optInOnlineFormApproved from 'shared/assets/images/optInOnlineFormApproved.png'
import optInOnlineFormRejected from 'shared/assets/images/optInOnlineFormRejected.png'
import styles from './styles.module.scss'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { OptInConsent } from './OptInConsent'

export const OptInOnlineFormContent = observer(() => {
  const { optInConsentStore, stepLoading } = useLocalNumbersContext()
  const {
    onlineFormUrl,
    noPublishedOnlineFormUrl,
    onlineFormUrlLocalError,
    termsAndPrivacyAgreement,
    similarityDomainsWarning,
    isOnlineFormNextDisabled,
    optInOnlineFormAttachmentError,
    checkEmail,
    setOnlineFormUrl,
    openApprovedExampleModal,
    uploadOnlineFormOptInImageAttachment,
    setTermsAndPrivacyAgreement,
    handleNoPublishedOnlineFormUrl,
    clearOnlineFormAttachmentError,
  } = optInConsentStore
  return (
    <div className={classNames(styles.contentWrap, styles.optInConsentWrap)}>
      <OptInConsent
        stepLoading={stepLoading}
        isNextDisabled={isOnlineFormNextDisabled}
        attachmentError={optInOnlineFormAttachmentError}
        increaseStep={() => {}}
        clearAttachmentError={clearOnlineFormAttachmentError}
        uploadOptInImageAttachment={uploadOnlineFormOptInImageAttachment}
        seeApprovedExampleClick={() => {
          openApprovedExampleModal(
            [optInOnlineFormApproved],
            ['Opt-in / Online Form / Approved example']
          )
        }}
        approvedExampleClick={() =>
          openApprovedExampleModal(
            [optInOnlineFormApproved, optInOnlineFormRejected],
            ['Opt-in / Online Form / Approved example', 'Opt-in / Online Form / Rejected example']
          )
        }
        rejectedExampleClick={() =>
          openApprovedExampleModal(
            [optInOnlineFormRejected, optInOnlineFormApproved],
            ['Opt-in / Online Form / Rejected example', 'Opt-in / Online Form / Approved example']
          )
        }
        firstBlock={{
          title: 'Include compliance language to your Online Form',
          desc: 'Include links to your Terms of Service and Privacy Policy in the compliance language.',
          copyTitle: 'Compliance language',
          copyText:
            'I agree to receive recurring automated text messages at the phone number provided. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our Terms of Service and Privacy Policy.',
        }}
        secondBlock={{
          title: 'Include this paragraph to your Privacy Policy',
          desc: 'Sharing data is not allowed. If text messaging data/consent is not shared, include the paragraph below to your Privacy Policy.',
          copyText:
            'Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the Text Message services.',
        }}
      >
        <div className={styles.infoBlock}>
          <div className={styles.blockNumber}>4</div>
          <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
            Online Form URL
          </Typography>
          <Typography
            ariaLabel='infoBlockTitle'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
            className={styles.textDesc}
          >
            Enter the full, valid URL that points to your online form. It must be publicly
            accessible, or your submission may be rejected.
          </Typography>

          <TextFieldWithLabel
            marginBottom={1}
            errorMessage={onlineFormUrlLocalError}
            warningText={similarityDomainsWarning}
            textFieldProps={{
              disabled: noPublishedOnlineFormUrl,
              value: onlineFormUrl || '',
              error: !!onlineFormUrlLocalError,
              onChange: setOnlineFormUrl,
              onBlur: (value: string) => {
                checkEmail(value)
              },
              InputProps: {
                placeholder: 'e.g. https://www.example.com/contact-us',
              },
            }}
          />
          <InputCheckbox
            label='My online form is not published yet'
            onChecked={handleNoPublishedOnlineFormUrl}
            checked={noPublishedOnlineFormUrl}
          />
        </div>

        <div className={styles.infoBlock}>
          <div className={styles.blockNumber}>5</div>
          <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
            Terms and Privacy
          </Typography>
          <InputCheckbox
            className={styles.termsOfServiceDesc}
            variant='text-input-field-r'
            color='var(--content-primary-tertiary)'
            label='I confirm that my website footer contains a link to my Terms of Service and Privacy Policy'
            onChecked={setTermsAndPrivacyAgreement}
            checked={termsAndPrivacyAgreement}
          />
        </div>
      </OptInConsent>
    </div>
  )
})
