import { makeAutoObservable } from 'mobx'
import type { ReactNode } from 'react'
import { TagsControl } from 'entities/Tags'
import type { IKeywordCreateField } from './type'
import { KeywordSMSStore } from './keywordSMSStore'

export class KeywordViewStore {
  private _isLoading = true
  private _name = ''
  private _description = ''
  private _errorsMap = new Map<IKeywordCreateField, ReactNode>()
  private _isDragging = false

  private _keywordSMSStore = new KeywordSMSStore({
    onFocus: () => {
      this.removeError('smsMessage')
    },
    placeholder: 'Write your auto-reply message...',
  })
  private _tagsControlStore = new TagsControl()

  constructor() {
    makeAutoObservable(this)
  }

  get isLoading() {
    return this._isLoading
  }

  get name() {
    return this._name
  }

  get description() {
    return this._description
  }

  get keywordSMSStore() {
    return this._keywordSMSStore
  }

  get isDraggingCondition() {
    return this._isDragging
  }

  get tagsControlStore() {
    return this._tagsControlStore
  }

  setName = (name: string) => {
    this._name = name
  }

  setDescription = (description: string) => {
    this._description = description
  }

  setError = (key: IKeywordCreateField, error: ReactNode) => {
    this._errorsMap.set(key, error)
  }

  removeError = (key: IKeywordCreateField) => {
    this._errorsMap.delete(key)
  }

  get errorsMap() {
    return this._errorsMap
  }

  setDraggingCondition = (condition: boolean) => {
    this._isDragging = condition
  }
}
