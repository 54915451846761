import { observer } from 'mobx-react-lite'

import { useLocation } from 'react-router'
import { Button, ChoiceTile, IIconsVector, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import styles from './styles.module.scss'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { IOptInMethodTypes } from '../store/OptInMethodStore'
import { ILocalNumbersRoutesEnum } from '../types'

export const OptInMethod = observer(() => {
  const { optInMethodStore, stepLoading, campaignOptInMethodNextClick, getLastPathSegment } =
    useLocalNumbersContext()
  const { isStep3Disabled, setOptInMethods, isOptInMethodActive } = optInMethodStore

  const handleSetOptInMethod = (value: string) => setOptInMethods(value as IOptInMethodTypes)
  const { pathname } = useLocation()
  const lastPathSegment = getLastPathSegment(pathname) as ILocalNumbersRoutesEnum

  const optInMethods: { icon: IIconsVector; title: string; value: IOptInMethodTypes }[] = [
    {
      icon: 'monitor01',
      title: 'Online Form',
      value: IOptInMethodTypes.onlineForm,
    },
    {
      icon: 'testSMS',
      title: 'via Text',
      value: IOptInMethodTypes.text,
    },
    {
      icon: 'qrCode02',
      title: 'QR Code',
      value: IOptInMethodTypes.qrCode,
    },
    {
      icon: 'file02',
      title: 'Paper Form',
      value: IOptInMethodTypes.paperForm,
    },
  ]

  return (
    <div className={styles.contentWrap}>
      <Typography
        variant='body-md-regular'
        ariaLabel='Business_info_line'
        className={styles.pageDesc}
      >
        Select the option(s) around how your contacts opt-in to receive text messages, therefore
        giving consent to receive your messages.
        <a
          href={links.localNumbersContainerLearnMoreLink}
          target='_blank'
          rel='noreferrer'
          className={styles.learnMoreLink}
        >
          <Typography
            ariaLabel='TermsAndPrivacy_link'
            variant='body-md-regular'
            color='var(--content-brand-primary)'
            className={styles.learnMoreLink}
          >
            Learn more
          </Typography>
        </a>
      </Typography>

      <div className={styles.optInMethodWrap}>
        {optInMethods.map((method) => (
          <ChoiceTile
            key={method.value}
            iconProps={{
              icon: method.icon,
              color: isOptInMethodActive(method.value)
                ? 'var(--content-brand-primary)'
                : 'var(--content-primary-tertiary)',
            }}
            title={method.title}
            value={method.value}
            checked={isOptInMethodActive(method.value)}
            setChoiceTile={handleSetOptInMethod}
          />
        ))}
      </div>

      <Button
        fullWidth
        loading={stepLoading}
        disabled={isStep3Disabled || stepLoading}
        className={styles.nextButton}
        text='Next'
        onClick={() => {
          campaignOptInMethodNextClick(lastPathSegment)
        }}
      />
    </div>
  )
})
