import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FullPageWrapper, IconButton, Typography } from 'shared/ui'
import { integrationsTextDataMap } from 'entities/Integrations/lib/integrationsData'
import { useIntegrationDetailsContext } from 'pages/settings/pages/integrations/context/integrationDetailsContext'

import styles from './styles.module.scss'

export const IntegrationDescription = observer(() => {
  const { integration } = useIntegrationDetailsContext()
  const swiperRef = useRef<SwiperCore | null>(null)
  if (!integration) return null

  const integrationData = integrationsTextDataMap[integration.key]

  return (
    <FullPageWrapper className={styles.container}>
      {!!integrationData?.images.length && (
        <div className={styles.slider}>
          <div className={styles.prevButtonContainer}>
            <IconButton
              icon='chevronLeft'
              size='small'
              color='secondary'
              ariaLabel='IntegrationSliderLeftButton'
              onClick={() => swiperRef?.current?.slidePrev()}
            />
          </div>
          <div className={styles.nextButtonContainer}>
            <IconButton
              icon='chevronRight'
              size='small'
              color='secondary'
              ariaLabel='IntegrationSliderRightButton'
              onClick={() => swiperRef?.current?.slideNext()}
            />
          </div>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            speed={500}
            onInit={(swiper) => (swiperRef.current = swiper)}
          >
            {integrationData.images.map((image, index) => (
              <SwiperSlide key={index} className={styles.sliderImage}>
                <img src={image} alt={`${integration.name}_image_${index}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {integrationData?.overview && (
        <section>
          <Typography
            tag={'div'}
            variant='body-rg-medium'
            ariaLabel='IntegrationDescription_overview_title'
            marginBottom={8}
          >
            Overview
          </Typography>
          <Typography
            variant='body-rg-regular'
            ariaLabel={'IntegrationDescription_overview'}
            color='tertiary'
          >
            {integrationData.overview}
          </Typography>
        </section>
      )}
      {!!integrationData?.keyBenefits.length && (
        <section>
          <Typography
            tag={'div'}
            variant='body-rg-medium'
            ariaLabel='IntegrationDescription_KeyBenefits_title'
            marginBottom={8}
          >
            Key Benefits
          </Typography>
          <ul className={styles.list}>
            {integrationData.keyBenefits.map((benefit, index) => (
              <li key={benefit}>
                <Typography
                  variant='body-rg-regular'
                  ariaLabel={`IntegrationDescription_KeyBenefits_${index}`}
                  color={'tertiary'}
                >
                  {benefit}
                </Typography>
              </li>
            ))}
          </ul>
        </section>
      )}
    </FullPageWrapper>
  )
})
