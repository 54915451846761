import { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { MuiDivider } from 'shared/ui/MuiDivider/Divider'
import { Alert, Button, Typography, Error, Tooltip } from 'shared/ui'
import { Warning } from 'shared/ui/Warning'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import {
  IInputComponent,
  makeTextFieldAutoFocus,
  makeTextFieldHighlight,
  makeTextFieldHighlightLimit,
  TextField,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { links } from 'shared/constants/links'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'shared/constants/auth'
import { authStore } from 'entities/Auth'
import { RegisterStore } from 'widgets/Register'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
}

export const SignUpForm = observer(({ store }: IFormProps) => {
  const {
    stepSignUpStore: {
      signUp,
      setEmail,
      email,
      setPassword,
      password,
      googleAuthCallback,
      error,
      loading,
      blurPasswordField,
      focusPasswordField,
      isActivePasswordField,
      passwordLength,
      emailError,
      triggerEmailValidation,
      passwordError,
      triggerPasswordValidation,
      isMaxPassword,
      isValidEmail,
      isValidPassword,
      showConstantEmailError,
      showConstantPasswordError,
    },
  } = store

  const { googleAuthStore } = authStore

  const onKeyDownEmail = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerEmailValidation()
      if (isValidEmail && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }

  const onKeyDownPassword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
      if (isValidPassword && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }

  return (
    <div className={styles.wrap}>
      {error && (
        <Alert
          className={styles.error}
          item={{
            type: 'error',
            desc: error,
          }}
        />
      )}

      <Button
        className={styles.button}
        loading={googleAuthStore.loading}
        disabled={googleAuthStore.loading || loading}
        typeBtn='outlined'
        type='button'
        icon={'google'}
        text={'Sign up with Google'}
        contained={'secondary'}
        onClick={() => googleAuthStore.onRegister(googleAuthCallback)}
        fullWidth
      />

      <div className={styles.divider}>
        <MuiDivider>OR</MuiDivider>
      </div>

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, { [styles.error]: !!emailError })}
        variant='stroke'
        disabled={googleAuthStore.loading || loading}
        size='medium'
        error={!!emailError && showConstantEmailError}
        InputProps={{ placeholder: 'name@company.com', onKeyDown: onKeyDownEmail }}
        onChange={setEmail}
        value={email}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!emailError }),
        ]}
      />
      <Error type={'text'} error={emailError} />

      <Label
        label={{
          text: 'Password',
          className: styles.inputLabel,
          rightText: isActivePasswordField ? (
            <Tooltip label={`Limit: ${MAX_PASSWORD_LENGTH} characters`} placement='top'>
              <>{`${passwordLength} of ${MAX_PASSWORD_LENGTH}`}</>
            </Tooltip>
          ) : null,
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField, {
          [styles.error]: !!passwordError || isMaxPassword,
        })}
        variant='stroke'
        onFocus={focusPasswordField}
        onBlur={blurPasswordField}
        error={!!passwordError && showConstantPasswordError}
        InputProps={{
          placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
          onKeyDown: onKeyDownPassword,
        }}
        disabled={googleAuthStore.loading || loading}
        size='medium'
        onChange={setPassword}
        value={password}
        mainActions={[
          makeTextFieldHighlight({ mode: 'error', highlight: !!passwordError }),
          makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH }),
        ]}
      />
      <Error type={'text'} error={passwordError} />
      <Warning
        text={
          isMaxPassword && isActivePasswordField
            ? `Maximum password length is ${MAX_PASSWORD_LENGTH} characters`
            : ''
        }
      />

      <Button
        className={classNames(styles.button, styles.signUp)}
        typeBtn='contained'
        type='button'
        onClick={signUp}
        loading={loading}
        disabled={googleAuthStore.loading || loading}
        text={'Sign up'}
        contained={'primary'}
        fullWidth
      />

      <div className={styles.termsAndPolicy}>
        <Typography variant={'body-sm-medium'} ariaLabel={'termsAndPolicy'}>
          {"By continuing, you agree to Salesmsg's "}
          <a target={'_blank'} href={links.termsConditions} rel='noreferrer'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a target={'_blank'} href={links.privacyPolicy} rel='noreferrer'>
            Privacy Policy
          </a>
        </Typography>
      </div>
    </div>
  )
})

export default SignUpForm
