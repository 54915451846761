import { FC, ReactNode } from 'react'
import classnames from 'classnames'
import { AvatarGroup, IAvatarInfo, Typography } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'

type IBaseContentProps = {
  withPadding?: boolean
  avatarsInfo: IAvatarInfo[]
  name: string
  desc: string
  isHold?: boolean
  actionsTop?: ReactNode
  actionsBottom?: ReactNode
  content?: ReactNode
}

export const BaseContent: FC<IBaseContentProps> = ({
  withPadding,
  avatarsInfo,
  isHold,
  name,
  desc,
  actionsTop,
  actionsBottom,
  content,
}) => {
  return (
    <div
      className={classnames(styles.content, {
        [styles.withoutPadding]: withPadding,
      })}
    >
      <div
        className={classnames(styles.content__section, styles['content__section--info'], {
          [styles.withPadding]: withPadding,
        })}
      >
        <div className={styles.content__box}>
          <div className={styles.info}>
            <div className={styles.info__avatar}>
              <AvatarGroup items={avatarsInfo} size={40} />
            </div>
            <div className={styles.info__box}>
              <Typography
                ellipsis
                tag={'div'}
                ariaLabel='callPopUpName'
                className={styles.info__title}
                tooltipProps={{ label: name, zIndex: 5001 }}
              >
                {name && getLabelAsNumberInternationalFormat(name)}
              </Typography>
              {isHold && <div className={styles.status}>HOLD</div>}
              {!isHold && (
                <div className={styles.info__titleSmall}>
                  {desc && getLabelAsNumberInternationalFormat(desc)}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.content__actions}>{actionsTop}</div>
      </div>

      <div
        className={classnames(styles.content__section, {
          [styles.withPadding]: withPadding,
        })}
      >
        {actionsBottom}
      </div>

      {content}
    </div>
  )
}
