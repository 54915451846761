import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum, authStore } from 'entities/Auth'

export default observer(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (authStore.isLoggedIn) {
      if (authStore.closeAfterLogin) {
        setTimeout(() => {
          window.close()
        }, 100)
      } else {
        if (!authStore.hasOid) {
          if (pathname.includes('auth/login')) return

          uiStore.changeRoute({
            path: `/${AuthRoutesEnum.registerMultiOrg}`,
          })
          window.location.reload()
          return
        }

        window.location.href = '/conversations'
      }
    }
  }, [authStore.isLoggedIn])

  return <Outlet />
})
