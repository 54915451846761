import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { KeywordsRoutesEnum } from 'entities/Keywords'
import { KeywordsPageLayout } from 'pages/keywords/layout'
import { AllKeywords, CreateKeyword } from 'pages/keywords/pages'

export const KeywordsRoutes = observer(() => (
  <Routes>
    <Route element={<KeywordsPageLayout />}>
      <Route index element={<AllKeywords />} />
      <Route path={`${KeywordsRoutesEnum.create}`} element={<CreateKeyword />} />
    </Route>
  </Routes>
))
