import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

interface IOptionItemProps {
  label?: string
  desc?: string
}

export const OptionItem = ({ label, desc }: IOptionItemProps) => {
  if (!label || !desc) return null

  return (
    <div className={styles.optionWrap}>
      <Typography
        ariaLabel='name'
        variant={'body-md-regular'}
        color='var(--content-primary-primary)'
      >
        {label}
      </Typography>
      <Typography
        ariaLabel='description'
        variant={'body-sm-regular'}
        color='var(--content-primary-tertiary)'
      >
        {desc}
      </Typography>
    </div>
  )
}
