import React from 'react'
import { observer } from 'mobx-react-lite'
import { type IColumn, Typography } from 'shared/ui'
import { DayjsFormats, formatBytes } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { EmptyCell } from 'shared/ui/Table'
import type { KnowledgeBaseDocument } from 'entities/KnowledgeBase'
import { getFileLabel } from 'entities/Attachment/lib/getFileLabel'
import { FileIcon } from 'widgets/FileIcon/FileIcon'
import styles from './styles.module.scss'

export const columns: IColumn<KnowledgeBaseDocument>[] = [
  {
    field: 'name',
    name: 'Name',
    minWidth: '145px',
    maxWidth: '245px',
    renderRowCell: (row) => <NameCell file={row} />,
  },
  {
    field: 'type',
    name: 'Type',
    fullName: 'Type',
    renderRowCell: (row) => getFileLabel(row?.extension ?? ''),
    maxWidth: 120,
  },
  {
    field: 'size',
    name: 'Size',
    fullName: 'Size',
    renderRowCell: (row) => (row.size ? formatBytes(row.size) : <EmptyCell />),
    maxWidth: 80,
  },
  {
    field: 'uploadedTime',
    name: 'Uploaded time',
    fullName: 'Uploaded time',
    renderRowCell: (row) =>
      row.uploadedAt ? (
        uiStore.dayjs(row.uploadedAt).format(DayjsFormats.fullWithAtDash2)
      ) : (
        <EmptyCell />
      ),
    maxWidth: 180,
  },
]

const NameCell = observer(({ file }: { file: KnowledgeBaseDocument }) => {
  return (
    <div className={styles.nameContainer}>
      <div className={styles.iconContainer}>
        <FileIcon docType={file.extension || file.contentType} fontSize={20} />
      </div>
      <Typography
        variant='body-md-regular'
        ariaLabel={`KnowledgeBaseDocument_${file.name}`}
        className={styles.name}
      >
        {file.name}
      </Typography>
    </div>
  )
})
