// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uz3NeVMrfrUVWf_qr60H{padding:48px 20px 0;gap:32px}.aN2CIwdc0jYxkHjggXjD{margin:0;padding-left:22px}.aN2CIwdc0jYxkHjggXjD li::marker{color:var(--content-primary-tertiary)}.ntdHHkTLLAq3aNpsC0qw{position:relative;margin-bottom:16px}.ntdHHkTLLAq3aNpsC0qw img{display:block;max-width:720px;width:100%;height:auto}.ntdHHkTLLAq3aNpsC0qw .eO6slL2da_zKYIdSC_Xg,.ntdHHkTLLAq3aNpsC0qw .TmXNx3sGSixj3Aa6wbO2{position:absolute;top:50%;transform:translateY(-50%)}.ntdHHkTLLAq3aNpsC0qw .eO6slL2da_zKYIdSC_Xg{left:-44px}.ntdHHkTLLAq3aNpsC0qw .TmXNx3sGSixj3Aa6wbO2{right:-44px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationDescription/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,QAAA,CAGF,sBACE,QAAA,CACA,iBAAA,CAEA,iCACE,qCAAA,CAIJ,sBACE,iBAAA,CACA,kBAAA,CAEA,0BACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CAGF,wFAEE,iBAAA,CACA,OAAA,CACA,0BAAA,CAGF,4CACE,UAAA,CAGF,4CACE,WAAA","sourcesContent":[".container {\n  padding: 48px 20px 0;\n  gap: 32px;\n}\n\n.list {\n  margin: 0;\n  padding-left: 22px;\n\n  li::marker {\n    color: var(--content-primary-tertiary);\n  }\n}\n\n.slider {\n  position: relative;\n  margin-bottom: 16px;\n\n  img {\n    display: block;\n    max-width: 720px;\n    width: 100%;\n    height: auto;\n  }\n\n  .prevButtonContainer,\n  .nextButtonContainer {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  .prevButtonContainer {\n    left: -44px;\n  }\n\n  .nextButtonContainer {\n    right: -44px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uz3NeVMrfrUVWf_qr60H",
	"list": "aN2CIwdc0jYxkHjggXjD",
	"slider": "ntdHHkTLLAq3aNpsC0qw",
	"prevButtonContainer": "eO6slL2da_zKYIdSC_Xg",
	"nextButtonContainer": "TmXNx3sGSixj3Aa6wbO2"
};
export default ___CSS_LOADER_EXPORT___;
