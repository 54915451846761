import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IDeal,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { DealsTab } from '../../TabsView/DealsTab/DealsTab'

type IDealsProps = {
  deals: IDeal[]
  contact: Contact
}

const Deals = ({ deals, contact }: IDealsProps) => {
  const store = useContactsDetailsContext()

  const integration = integrationsStore.getIntegration(IntegrationKey.activecampaign)

  const linkProps = {
    key: IntegrationKey.activecampaign,
    contact,
    integration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = () => integrationLink(linkProps)

  const handleView = () => {
    store.openView('deals', { deals }, 'activecampaign', IntegrationKey.activecampaign)
  }

  return <DealsTab deals={deals} viewAll={handleView} link={link} rows={['value', 'owner']} />
}

export { Deals }
