import { observer } from 'mobx-react-lite'
import {
  Box,
  type IDropdownItem,
  ITooltipProps,
  type PhoneInputStore,
  PhoneWithLabelInput,
  TextFieldWithLabel,
} from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'

interface ContactFormProps {
  isSecond?: boolean
  contact: {
    firstName: string
    lastName: string
    businessTitle: string
    jobPosition: number | null
    email: string
  }
  jobPositionList: IDropdownItem[]
  phoneInputStore: PhoneInputStore
  emailErrorMessage?: string
  phoneErrorMessage?: string | null
  isErrorBorderEmail: boolean
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setBusinessTitle: (value: string) => void
  setJobPosition: (value: number) => void
  setEmail: (value: string) => void
  checkIsEmail: () => void
  setValueCallback: () => void
}

const phoneTooltip: ITooltipProps = {
  type: 'description',
  width: 290,
  label: "Your representative's mobile phone number",
  desc: 'May also be used for verification codes',
}

export const ContactForm = observer(
  ({
    isSecond,
    contact,
    jobPositionList,
    phoneInputStore,
    emailErrorMessage,
    phoneErrorMessage,
    isErrorBorderEmail,
    setFirstName,
    setLastName,
    setBusinessTitle,
    setJobPosition,
    setEmail,
    checkIsEmail,
    setValueCallback,
  }: ContactFormProps) => (
    <>
      <Box display='flex' justifyContent='space-between' marginBottom={3} gap={3}>
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'First name',
            },
          }}
          textFieldProps={{
            value: contact.firstName,
            onChange: setFirstName,
            InputProps: {
              placeholder: 'e.g. Adam',
            },
          }}
        />
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'Last name',
            },
          }}
          textFieldProps={{
            value: contact.lastName,
            onChange: setLastName,
            InputProps: {
              placeholder: 'e.g. Johnson',
            },
          }}
        />
      </Box>
      <Box display='flex' justifyContent='space-between' marginBottom={3} gap={3}>
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'Business title',
            },
            questionTooltipProps: isSecond
              ? undefined
              : {
                  type: 'description',
                  width: 247,
                  label: 'We’re just making sure you’re in a position to make important decisions.',
                  desc: 'So, president, founder, executive, manager, or even boss are legit options.',
                },
          }}
          textFieldProps={{
            value: contact.businessTitle,
            onChange: setBusinessTitle,
            InputProps: {
              placeholder: 'e.g. Founder',
            },
          }}
        />
        <SelectField
          boxWidth='100%'
          labelProps={{
            label: {
              text: 'Job position',
            },
          }}
          items={jobPositionList}
          value={contact.jobPosition}
          setValue={setJobPosition}
          btnProps={{
            size: 'large',
          }}
        />
      </Box>
      <TextFieldWithLabel
        marginBottom={0}
        errorBorder={isErrorBorderEmail}
        errorMessage={emailErrorMessage}
        labelProps={{
          marginBottom: 8,
          label: {
            text: 'Business email',
          },
        }}
        textFieldProps={{
          value: contact.email,
          onChange: setEmail,
          onBlur: checkIsEmail,
          InputProps: {
            placeholder: 'e.g. adam.johnson@example.com',
          },
        }}
      />
      <PhoneWithLabelInput
        label='Phone number'
        questionTooltipProps={isSecond ? undefined : phoneTooltip}
        item={phoneInputStore.country}
        items={phoneInputStore.countries}
        number={phoneInputStore.number}
        numberError={phoneInputStore.numberError || phoneErrorMessage || null}
        isValidNumber={phoneInputStore.isValidNumber}
        numberRequestError={phoneInputStore.numberRequestError}
        setNumber={(value) => {
          setValueCallback()
          phoneInputStore.setNumber(value)
        }}
        setCountry={phoneInputStore.setCountry}
        triggerNumberValidation={phoneInputStore.triggerNumberValidation}
      />
    </>
  )
)
