import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react'
import type { IComplianceSettingsStore } from 'entities/Compliance'
import { ComplianceMainPageStore } from '../store/complianceMainPageStore'

const ComplianceMainPageContext = createContext<ComplianceMainPageStore | null>(null)

export const useComplianceMainPageContext = () => {
  const context = useContext(ComplianceMainPageContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ComplianceMainPageProvider'
    )

  return context
}

export const ComplianceMainPageProvider = ({
  complianceSettingsStore,
  children,
}: {
  complianceSettingsStore: IComplianceSettingsStore
  children: ReactNode
}) => {
  const store = useMemo(() => new ComplianceMainPageStore(complianceSettingsStore), [])

  useEffect(() => store.dispose, [])

  return (
    <ComplianceMainPageContext.Provider value={store}>
      {children}
    </ComplianceMainPageContext.Provider>
  )
}
