import type { IResponseKnowledgeBase } from 'entities/KnowledgeBase'

export class KnowledgeBase {
  id: number
  name: string
  isDefault: boolean
  documentsCount: number
  chatbotsCount: number
  organizationId: number
  status: number
  createdAt: string
  updatedAt: string

  constructor(item: IResponseKnowledgeBase) {
    this.id = item.id
    this.name = item.name
    this.isDefault = item.is_default
    this.documentsCount = item.documents_count
    this.chatbotsCount = item.chatbots_count
    this.organizationId = item.organization_id
    this.status = item.status
    this.createdAt = item.created_at
    this.updatedAt = item.updated_at
  }
}
