export const CHAR_MAX_LENGTH = 255

export const CHATBOT_NAME_LIMIT = CHAR_MAX_LENGTH
export const WORKFLOW_NAME_LIMIT = CHAR_MAX_LENGTH
export const TRIGGER_NAME_LIMIT = CHAR_MAX_LENGTH
export const BROADCAST_NAME_LIMIT = CHAR_MAX_LENGTH
export const KEYWORD_NAME_LIMIT = 40
export const TEXT_TO_SPEECH_AUDIO_NAME = 96
export const TEXT_TO_SPEECH_VOICE_NAME = 30
export const MERGE_FIELD_FALLBACK_LIMIT = 30
export const KEYWORD_DESCRIPTION_LIMIT = 50
