import { makeAutoObservable, runInAction, reaction, IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { showToast } from 'shared/ui'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { subscriptionStore } from 'entities/Subscription'
import { IParamsFiltersListGroups } from 'entities/Broadcast/api/types'
import { IParamsContacts } from 'entities/Contacts/api/types'
import { organizationStore } from 'entities/Organization'
import { eventLogStore } from 'entities/EventLog'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { PowerDialerContactsModalContent } from 'widgets/PowerDialer/ui/PowerDialerContactsModal/PowerDialerContactsModalContent'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'
import { EnrollmentFilters, EnrollmentStore } from 'widgets/ContactEnrollment'
import { NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import { EnumNumbersInboxesDropDown } from 'widgets/NumbersInboxesList/store'

type IPowerDialerStoreType = 'contact' | 'conversation'

export class PowerDialerStore {
  private _modalId = ''
  private _showAlert = true
  private _contactsMap: Map<number, Contact> = new Map()
  private _teamId = 0
  private _numberId = 0
  private _type: IPowerDialerStoreType = 'contact'
  private _loading = false
  private _step = 1

  // enrollment
  private _enrollmentFilters = EnrollmentFilters.Empty()
  private _enrollmentStore = new EnrollmentStore({
    filterConfig: {
      makeRequest: () => {
        return ContactFiltersApi.getFilters({
          withCustomFields: true,
          workflowSource: 'core',
          skipValues: ['tag'],
        }).then(({ data }) => data)
      },
    },
  })
  private _disposeChangeTitleModal: IReactionDisposer | null = null
  private _numbersInboxesListStore = new NumbersInboxesListStore({
    variant: EnumNumbersInboxesDropDown.Call,
    excludeSIPTrunk: true,
    excludeAircall: true,
  })

  constructor() {
    makeAutoObservable(this)

    this.reactionChangeTitleModal()
  }

  loadFilters = () => this._enrollmentStore.filterEditorStore.initFilters()

  reactionChangeTitleModal = () => {
    this._disposeChangeTitleModal?.()
    this._disposeChangeTitleModal = reaction(
      () => this.titleModal,
      (value) => {
        this._enrollmentStore.setTitleModal(value)
        this.setTitleModal(value)
      }
    )
  }

  setTitleModal = ({
    title,
    titleDividerContent,
  }: {
    title: string
    titleDividerContent: string
  }) => {
    const modal = modalStore.getModal(this._modalId)

    if (modal) {
      modal.title = title
      modal.titleDividerContent = titleDividerContent
    }
  }

  onChangeShowAlert = (value: boolean) => {
    this._showAlert = value
  }

  openContactsModal = () => {
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      title: this.titleModal.title,
      titleDividerContent: this.titleModal.titleDividerContent,
      width: 560,
      showCloseButton: false,
      showCloseIcon: false,
      paddingContent: '0px 18px 24px 20px',
      ModalContent: PowerDialerContactsModalContent,
      ModalContentProps: {
        powerDialerStore: this,
      },
      onClose: () => {
        this.reset()
        modalStore.removeModal(this._modalId)
      },
    })
  }

  reset = () => {
    this._contactsMap.clear()
    this._showAlert = true
    this._teamId = 0
    this._numberId = 0
    this._step = 1
    this._enrollmentFilters = EnrollmentFilters.Empty()
    this._disposeChangeTitleModal?.()
  }

  private _fetchContactsList = async (params: IParamsContacts) => {
    try {
      const { data } = await ContactsApi.getContactsList(params)
      const contacts = contactsStore.addItems(data.data)

      return contacts
    } catch (e) {
      console.log(e)

      return []
    }
  }

  private _fetchContactsByIds = async (contactIds: number[]) => {
    try {
      return (await contactsStore.getByIds(contactIds)) ?? []
    } catch (e) {
      console.log(e)

      return []
    }
  }

  handleCheckLimit = (total: number) => {
    if (subscriptionStore.isTrial && total > 10) {
      showToast({
        title: 'You can select no more than 10 contacts for Power Dialer',
        type: 'error',
      })

      return true
    }

    if (total > 100) {
      showToast({
        title: 'You can select no more than 100 contacts for Power Dialer',
        type: 'error',
      })

      return true
    }
  }

  handleOpenModalContact = async (
    total: number,
    params: IParamsContacts,
    selectedIds: number[],
    bulkAll: boolean
  ) => {
    if (this._loading) return

    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: 'clicked in contacts',
      },
      { groupId: organizationStore.id }
    )

    const count = bulkAll ? total : selectedIds.length
    const length = bulkAll ? 100 : selectedIds.length

    if (this.handleCheckLimit(count)) return

    runInAction(() => {
      this._loading = true
    })

    const contacts = bulkAll
      ? await this._fetchContactsList({ ...params, length })
      : await this._fetchContactsByIds(selectedIds)

    this.setType('contact')
    this.setSelectedContacts(contacts)
    this.openContactsModal()

    runInAction(() => {
      this._loading = false
    })
  }

  setSelectedContacts = (contacts: Contact[]) => {
    contacts.forEach((contact) => this._contactsMap.set(contact.id, contact))
  }

  setTeamId = (id: number) => {
    this._teamId = id
  }

  setNumberId = (id: number) => {
    this._numberId = id
  }

  deleteSelectedId = (id: number) => {
    this._contactsMap.delete(id)
  }

  handleCall = () => {
    if (!this._teamId || !this._numberId) {
      this._numbersInboxesListStore.setError(true)

      return
    }
    const team = inboxesStore.getItem(this._teamId)
    const number = numbersStore.getItem(this._numberId)

    if (!team) return
    if (!(team instanceof Inbox)) return
    if (!number) return

    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: this._type === 'contact' ? 'started in contacts' : 'started in conversations',
      },
      { groupId: organizationStore.id }
    )

    callPopUpGlobalStore.addPowerDialerItem(team, number, this.contacts)
    modalStore.closeModal(this._modalId)
  }

  confirmEnrollment = (total: number, skipped: number, newFilters: EnrollmentFilters) => {
    return new Promise<boolean>(async (resolve) => {
      this._enrollmentStore.setLoading(true)
      this._enrollmentFilters = newFilters

      const params = {
        page: 1,
        length: 100,
        search: '',
        sortOrder: 'desc',
        sortBy: ['created_at'],
        filtersListGroups: this.paramsFilters.filtersListGroups,
      }

      if (this.handleCheckLimit(total + skipped)) {
        this._enrollmentStore.setLoading(false)

        return resolve(false)
      }

      const contacts = await this._fetchContactsList(params)
      this._step = 2
      this.setSelectedContacts(contacts)
      this.openContactsModal()

      this._enrollmentStore.setLoading(false)

      resolve(true)
    })
  }

  editEnrollment = () => {
    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: 'clicked in conversations',
      },
      { groupId: organizationStore.id }
    )

    this.setType('conversation')

    const filters = this._enrollmentFilters.clone()

    const cancelFilters = () => Promise.resolve(true)
    const confirmFilters = (newFilters: EnrollmentFilters, total: number, skipped: number) => {
      return this.confirmEnrollment(total, skipped, newFilters).then((isAllowed) =>
        runInAction(() => {
          if (!isAllowed) return isAllowed

          return isAllowed
        })
      )
    }

    this.loadFilters()
    this._enrollmentStore.openModal({
      filters: filters,
      action: {
        cancel: cancelFilters,
        confirm: confirmFilters,
      },
      title: this.titleModal.title,
      titleDividerContent: this.titleModal.titleDividerContent,
    })
  }

  setType = (value: IPowerDialerStoreType) => {
    this._type = value
  }

  setDefaultInbox = () => {
    const inbox = inboxesStore.currentInbox
    if (inbox?.type === 'inbox') {
      const numberId = inbox && !inbox.isSmartInbox ? inbox.numberId : 0

      if (inbox.id) {
        this._numbersInboxesListStore.setActiveNumber(inbox.id, numberId)
      }
    }
  }

  get titleModal() {
    if (this._type === 'contact')
      return {
        title: 'Power Dialer',
        titleDividerContent: '',
      }

    return {
      title: 'Power Dialer',
      titleDividerContent: `${this._step} of 2`,
    }
  }

  get showAlert() {
    if (this._type === 'conversation') return false

    return this._showAlert
  }

  get contacts() {
    return [...this._contactsMap.values()]
  }

  get count() {
    return this._contactsMap.size
  }

  get disabled() {
    return Boolean(!this.count || (this._numberId === 0 && this._teamId !== 0))
  }

  get paramsFilters(): IParamsFiltersListGroups {
    return {
      filtersListGroups: {
        contacts: this._enrollmentFilters.contacts.map((contact) => contact.id) ?? [],
        segments: this._enrollmentFilters.segments?.map((segment) => segment.id) ?? [],
        tags: this._enrollmentFilters.tags?.map((tag) => tag.id),
        advanced: this._enrollmentFilters.filters?.map((filter) => filter.filtersParams) ?? [],
      },
    }
  }

  get loading() {
    return this._loading
  }

  get numbersInboxesListStore() {
    return this._numbersInboxesListStore
  }
}
