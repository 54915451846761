import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router'
import { IconButton, Typography } from 'shared/ui'

import styles from './styles.module.scss'

import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { CampaignUseCase } from './CampaignUseCase'
import { Messages } from './Messages'
import { OptInMethod } from './OptInMethod'
import { LocalProgressInfo } from './LocalProgressInfo'
import { OptInOnlineFormContent } from './OptInOnlineFormContent'
import { ILocalNumbersRoutesEnum } from '../types'
import { OptInPaperFormContent } from './OptInPaperFormContent'
import { OptInViaTextContent } from './OptInViaTextContent'
import { OptInQrCodeFormContent } from './OptInQrCodeFormContent'
import { ForbiddenMessageTopics } from './ForbiddenMessageTopics'
import { Package } from './Package'

export const LocalNumbersContainer = observer(() => {
  const navigate = useNavigate()
  const { complianceSettingsStore, useCaseStore } = useLocalNumbersContext()

  const { settingsStep, decreaseStep } = complianceSettingsStore.stepsStore

  useEffect(() => {
    settingsStep === 1 && useCaseStore.loadUseCases()
  }, [])

  return (
    <>
      <IconButton
        className={styles.closeIcon}
        color='transparent'
        ariaLabel='close_setting'
        icon='close'
        size='medium'
        onClick={() => navigate(-1)}
      />
      <div className={styles.localNumbersContainer}>
        <div className={styles.titleStatus}>
          <Typography ariaLabel='QuestionsPage_title' variant='body-xs-regular'>
            A2P 10DLC registration
          </Typography>
        </div>
        <LocalProgressInfo />
        {settingsStep > 1 && (
          <div className={styles.backButton}>
            <IconButton
              className={styles.backButtonIcon}
              color='transparent'
              ariaLabel='back_to_lastStep'
              icon='arrowLeft'
              size='medium'
              onClick={decreaseStep}
            />
          </div>
        )}
        <Routes>
          <Route path={ILocalNumbersRoutesEnum.campaignUseCases} element={<CampaignUseCase />} />
          <Route path={ILocalNumbersRoutesEnum.messages} element={<Messages />} />
          <Route path={ILocalNumbersRoutesEnum.optInMethod} element={<OptInMethod />} />
          <Route
            path={ILocalNumbersRoutesEnum.optInOnlineFormContent}
            element={<OptInOnlineFormContent />}
          />
          <Route
            path={ILocalNumbersRoutesEnum.optInPaperFormContent}
            element={<OptInPaperFormContent />}
          />
          <Route
            path={ILocalNumbersRoutesEnum.optInViaTextContent}
            element={<OptInViaTextContent />}
          />
          <Route
            path={ILocalNumbersRoutesEnum.optInQrCodeContent}
            element={<OptInQrCodeFormContent />}
          />
          <Route
            path={ILocalNumbersRoutesEnum.forbiddenMessageTopics}
            element={<ForbiddenMessageTopics />}
          />
          <Route path={ILocalNumbersRoutesEnum.package} element={<Package />} />

          <Route path='*' element={<div>Page Not Found</div>} />
        </Routes>
      </div>
    </>
  )
})
