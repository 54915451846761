import type { IResponseKnowledgeBaseDocument } from 'entities/KnowledgeBase'

export class KnowledgeBaseDocument {
  id: number
  name: string
  knowledgeBaseId: number
  status: number
  contentType: string
  extension: string | null = null
  size: number | null = null
  uploadedAt: string | null

  constructor(document: IResponseKnowledgeBaseDocument) {
    this.id = document.id
    this.name = document.name
    this.knowledgeBaseId = document.id
    this.status = document.status
    this.contentType = document.content_type
    this.extension = document.extension
    this.size = document.size
    this.uploadedAt = document.uploaded_at
  }
}
