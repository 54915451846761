import { observer } from 'mobx-react-lite'
import { EmptyState, Table, variantActionsProps } from 'shared/ui'
import { useKeywordsListContext } from 'pages/keywords/context'
import { columns } from './columns'
import styles from './styles.module.scss'

export const KeywordsTable = observer(() => {
  const {
    limit,
    page,
    isLoading,
    total,
    keywords,
    tableStore,
    onPaginationModelChange,
    isNoSearchResults,
  } = useKeywordsListContext()

  const getRows = () => {
    return keywords.map((keyword) => {
      keyword.setActionsProps({
        ...variantActionsProps.table,
        actions: [],
      })

      return keyword
    })
  }

  if (isNoSearchResults) {
    return <EmptyState text='No keywords found' />
  }

  return (
    <div className={styles.wrap}>
      <Table
        withCheckbox
        withSelectAll
        store={tableStore}
        columns={columns}
        pinnedColumnNames={['name']}
        rows={getRows()}
        rowsCount={total}
        isLoading={isLoading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        sortBy={tableStore.sortBy}
        startPaginationSize={10}
      />
    </div>
  )
})
