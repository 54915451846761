import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { useIntegrationDetailsContext } from 'pages/settings/pages/integrations/context/integrationDetailsContext'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'

export const IntegrationSettings = observer(() => {
  const navigate = useNavigate()
  const { integration, isLoading } = useIntegrationDetailsContext()

  useEffect(() => {
    if (integration && !integration.isConnected) {
      navigate(`../${IntegrationsRoutes.description}`, { replace: true })
    }
  }, [integration])

  if (!integration) return null

  return (
    <FullPageWrapper>
      {isLoading ? <SpinnerLoader /> : <div>{integration.name} settings</div>}
    </FullPageWrapper>
  )
})
