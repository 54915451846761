import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseInfusionsoftInfoProperties,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { Actions } from './Actions'
import { ContactTab } from '../../TabsView/ContactTab/ContactTab'

type IPropertiesProps = {
  properties: IResponseInfusionsoftInfoProperties | null
  contact: Contact
}

const Properties = ({ properties, contact }: IPropertiesProps) => {
  const integration = integrationsStore.getIntegration(IntegrationKey.infusionsoft)

  const linkProps = {
    key: IntegrationKey.infusionsoft,
    contact,
    integration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  let name = null
  if (properties?.first_name) name = `${properties?.first_name} `
  if (properties?.last_name) name += `${properties?.last_name}`

  const data = properties
    ? [
        {
          label: 'Email',
          key: 'email',
          value: properties.email?.[0]?.email || null,
        },
      ]
    : null

  return (
    <ContactTab
      title={name}
      link={link}
      data={data}
      actions={<Actions id={contact.integration_vendor_id} />}
    />
  )
}

export { Properties }
