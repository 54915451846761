import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import axios, { CancelTokenSource } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IDropdownItem, toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  IResponseCannedMessage,
  CannedMessagesApi,
  SavedRepliesSortEnum,
  ISavedRepliesProps,
  IParamsGetCannedMessages,
  cannesMessageStore,
} from 'entities/CannedMessages'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { MessageData } from 'entities/Message'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { Contact } from 'entities/Contacts/model/Contact'
import { Integration } from 'entities/Integrations/model/Integration'
import { SavedRepliesContent, newSavedReplyStore } from 'widgets/SavedReplies'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { ListSavedRepliesStore } from './listSavedRepliesStore'

class SavedRepliesStore {
  private _disposeReactionLoadData: IReactionDisposer | null = null

  constructor() {
    this.reactionLoadData()

    makeAutoObservable(this)
  }

  savedRepliesMap = new Map<number, CannedMessage>()
  selectedSavedReply: CannedMessage | null = null
  selectedSavedReplyRef: React.RefObject<HTMLButtonElement> | null = null
  useScrollToElement = false

  contact: Contact | null = null
  integration: Integration | null = null
  onAddSavedReplies: ((message: MessageData) => void) | null = null

  search = ''
  _loading = false

  visibleSavedRepliesActionsId = 0

  modalId = ''

  leftContentElement: HTMLDivElement | null = null

  cancelTokenSource: CancelTokenSource | null = null

  setSavedReplay = (savedReplayResponse: IResponseCannedMessage) => {
    const savedReplay = new CannedMessage(savedReplayResponse)
    if (this.selectedSavedReply?.id === savedReplay.id) {
      this.selectedSavedReply = savedReplay
    }
    this.savedRepliesMap.set(savedReplay.id, savedReplay)
  }

  setSelectedSavedReplyRef = (ref: React.RefObject<HTMLButtonElement> | null) => {
    this.selectedSavedReplyRef = ref
  }

  setUseScrollToElement = (useScroll: boolean) => {
    this.useScrollToElement = useScroll
  }

  favoriteListStore: ListSavedRepliesStore = new ListSavedRepliesStore({
    filter: 'favorite',
    setSavedReplay: this.setSavedReplay,
  })
  unfavoriteListStore: ListSavedRepliesStore = new ListSavedRepliesStore({
    filter: 'unfavorite',
    setSavedReplay: this.setSavedReplay,
  })

  get order(): SavedRepliesSortEnum {
    return cannesMessageStore.order
  }

  get paramsGetItems(): IParamsGetCannedMessages {
    return {
      contact_id: this.contact?.id,
      integration_id: this.integration?.id,
      order: this.order,
      search: this.search,
    }
  }

  get loading() {
    return this.favoriteListStore.loading || this.unfavoriteListStore.loading || this._loading
  }

  get savedReplies() {
    const list = Array.from(this.savedRepliesMap.values())

    if (this.order === SavedRepliesSortEnum.letterDown) {
      list.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
        return 0
      })
    }

    if (this.order === SavedRepliesSortEnum.letterUp) {
      list.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) return -1
        if (a.title.toLowerCase() < b.title.toLowerCase()) return 1
        return 0
      })
    }

    if (this.order === SavedRepliesSortEnum.newest) {
      list.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp)
    }

    if (this.order === SavedRepliesSortEnum.oldest) {
      list.sort((a, b) => a.createdAtTimestamp - b.createdAtTimestamp)
    }

    return list
  }

  get hasFavorites() {
    return !!this.favoriteSavedReplies.length
  }

  get hasUnfavorites() {
    return !!this.unfavoriteSavedReplies.length
  }

  get hasSavedReplies() {
    return this.hasFavorites || this.hasUnfavorites
  }

  get favoriteSavedReplies() {
    return this.savedReplies.filter((reply) => reply.is_favorite)
  }

  get unfavoriteSavedReplies() {
    return this.savedReplies.filter((reply) => !reply.is_favorite)
  }

  get disabledVisibility() {
    return usersStore.user?.role === 'member' || usersStore.user?.role === 'manager'
  }

  nextItem = () => {
    this.useScrollToElement = true
    const fullList = [...this.favoriteSavedReplies, ...this.unfavoriteSavedReplies]

    if (!this.selectedSavedReply && fullList.length) this.selectedSavedReply = fullList[0]
    if (!this.selectedSavedReply || !fullList.length || fullList.length === 1) return

    const currentIdx = fullList.findIndex((item) => item.id === this.selectedSavedReply?.id)

    if (fullList.length > currentIdx + 1) {
      const item = fullList[currentIdx + 1]
      if (item) this.selectedSavedReply = item
    } else if (fullList.length) {
      this.selectedSavedReply = fullList[0]
    }
  }

  prevItem = () => {
    this.useScrollToElement = true
    const fullList = [...this.favoriteSavedReplies, ...this.unfavoriteSavedReplies]

    if (!this.selectedSavedReply && fullList.length) this.selectedSavedReply = fullList[0]
    if (!this.selectedSavedReply || !fullList.length || fullList.length === 1) return

    const currentIdx = fullList.findIndex((item) => item.id === this.selectedSavedReply?.id)

    if (currentIdx > 0) {
      const item = fullList[currentIdx - 1]
      if (item) this.selectedSavedReply = item
    } else {
      this.selectedSavedReply = fullList[fullList.length - 1]
    }
  }

  loadData = async () => {
    try {
      this._loading = true
      this.clear()

      await Promise.all([
        this.favoriteListStore.loadData(this.paramsGetItems),
        this.unfavoriteListStore.loadData(this.paramsGetItems),
      ])

      runInAction(() => {
        const firstSavedReplay = this.favoriteSavedReplies[0] || this.unfavoriteSavedReplies[0]
        this.selectedSavedReply = this.savedReplies.length ? firstSavedReplay : null
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  reset = () => {
    this._disposeReactionLoadData?.()
    this.contact = null
    this.integration = null
    this.onAddSavedReplies = null
    this.search = ''
    this.clear()
    this.favoriteListStore.reset()
    this.unfavoriteListStore.reset()
  }

  clear = () => {
    this.savedRepliesMap.clear()
    this.favoriteListStore.clear()
    this.unfavoriteListStore.clear()
    this.selectedSavedReply = null
  }

  init = () => {
    allMergeFieldsStore.loadAllFields()
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  openModal = ({
    contact,
    onAddSavedReplies,
    integration,
    isCurrentAirCall,
  }: ISavedRepliesProps) => {
    allMergeFieldsStore.loadAllFields()
    this.reset()
    this.contact = contact ?? null
    this.integration = integration ?? null
    this.onAddSavedReplies = onAddSavedReplies
    this.modalId = nanoid()
    this.reactionLoadData()
    this.favoriteListStore.reactionLoadData()
    this.unfavoriteListStore.reactionLoadData()

    modalStore.addModal({
      id: this.modalId,
      title: 'Saved replies',
      ModalContent: () => <SavedRepliesContent isCurrentAirCall={isCurrentAirCall} />,
      pureContent: true,
      width: 720,
    })

    this.loadData()
  }

  closeModal = () => {
    modalStore.closeModal(this.modalId)
    this.reset()
  }

  handleSelectSort = (item: IDropdownItem) => {
    cannesMessageStore.setOrder(item.id as SavedRepliesSortEnum)
  }
  setSearch = (search: string) => {
    this.search = search
  }

  setSelectSavedReply = (savedReply: CannedMessage) => {
    this.selectedSavedReply = savedReply
  }

  handleAddSelectedSavedReplies = () => {
    if (this.selectedSavedReply) this.handleAddSavedReplies(this.selectedSavedReply)
  }

  handleAddSavedReplies = (savedReply: CannedMessage | null) => {
    let isDisconnected = false
    const mergeFields = savedReply?.value?.match(/{.*?}/g)

    mergeFields?.forEach((field) => {
      const split = field.replace(/{|}/g, '').trim().split('|')

      const fullMergeField = split[0]
      const splitKey = fullMergeField.split('.')
      const integrationKey = Object.values(IntegrationKey).includes(splitKey[0] as IntegrationKey)
        ? splitKey[0]
        : IntegrationKey.salesmessage

      const integration = integrationsStore.getIntegration(integrationKey)
      if (integrationKey !== IntegrationKey.salesmessage && !integration?.isConnected) {
        isDisconnected = true
      }
    })

    if (isDisconnected) {
      const id = nanoid()
      const onReconnect = () => {
        toastStore.remove(id)
        uiStore.changeRoute({
          path: '/settings/integrations',
          type: 'vue',
        })
      }
      toastStore.add({
        id: id,
        title: 'Integration disconnected',
        type: 'error',
        desc: 'To use a saved reply that contains integration merge fields without fallback, please reconnect the integration',
        action: {
          text: 'Reconnect',
          onAction: onReconnect,
        },
      })
    } else {
      if (this.onAddSavedReplies && savedReply) {
        this.onAddSavedReplies({
          message: savedReply.value || savedReply.message,
          attachments: savedReply.attachments,
          isReset: false,
        })
        modalStore.closeModal(this.modalId)
      }
    }
  }
  handleEditSavedReplies = (savedReply: CannedMessage | null) => {
    newSavedReplyStore.handleNewSavedReply(savedReply)
  }
  handleDuplicateSavedReplies = (savedReply: CannedMessage | null) => {
    newSavedReplyStore.handleNewSavedReply(savedReply, true)
  }
  handleChangeVisibilitySavedReplies = async (
    savedReply: CannedMessage | null,
    isPublic: boolean
  ) => {
    try {
      if (savedReply) {
        const { data } = await CannedMessagesApi.updateCannedMessagesById({
          ...savedReply.updateData,
          public: isPublic,
          contact_id: this.contact?.id,
        })
        if (data) {
          this.setSavedReplay(data)
        }
        const id = nanoid()
        const onUndo = () => {
          toastStore.remove(id)
          this.handleChangeVisibilitySavedReplies(savedReply, !isPublic)
        }

        toastStore.add({
          id: id,
          title: `Visibility changed to ${data.public ? 'everyone' : 'personal'}`,
          type: 'info',
          action: {
            text: 'Undo',
            onAction: onUndo,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  handleDeleteSavedReplies = async (savedReply: CannedMessage | null) => {
    try {
      if (savedReply) {
        const index = this.savedReplies.findIndex((reply) => savedReply.id === reply.id)
        await CannedMessagesApi.deleteCannedMessages(savedReply.id)
        this.savedRepliesMap.delete(savedReply.id)

        if (this.selectedSavedReply?.id === savedReply.id) {
          this.selectedSavedReply = this.savedReplies[index] || null
        }
        toastStore.add({
          title: 'Saved reply deleted',
          type: 'info',
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  handleFavoriteSavedReplies = async (savedReply: CannedMessage) => {
    try {
      const { data } = await CannedMessagesApi.favoriteCannedMessagesById({
        id: savedReply.id,
        favorite: !savedReply.is_favorite,
      })
      if (data) {
        this.setSavedReplay(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  setVisibleSavedRepliesActionsId = (id: number) => {
    this.visibleSavedRepliesActionsId = id
  }

  setLeftContentElement = (leftContentElement: HTMLDivElement) => {
    this.leftContentElement = leftContentElement
  }

  reactionLoadData = () => {
    this._disposeReactionLoadData?.()
    this._disposeReactionLoadData = reaction(() => this.paramsGetItems, this.loadData, {
      delay: 500,
    })
  }
}

export const savedRepliesStore = new SavedRepliesStore()
