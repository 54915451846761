import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ErrorPage } from 'shared/ui'
import { BroadcastRoutesEnum } from 'entities/Broadcast'
import { TriggerRouts } from 'entities/Trigger'
import { AuthRoutesEnum } from 'entities/Auth'
import { ConversationRoutesEnum } from 'entities/Conversation'
import { KeywordsRoutesEnum } from 'entities/Keywords'
import { ConversationsRouters } from 'pages/conversations'
import { WorkflowRoute, WorkflowsRoutes } from 'pages/workflows'
import { BroadcastRoutes } from 'pages/broadcasts'
import { TriggersRouter } from 'pages/triggers'
import { ChatbotRoute, ChatbotRoutes } from 'pages/chatbot'
import { ContactsRouters } from 'pages/contacts'
import { AdminRoutesEnum } from 'pages/admin'
import AdminRoutes from 'pages/admin/route/adminRoutes'
import { SettingsRoutesEnum } from 'pages/settings/route/type'
import { SettingsRoutes } from 'pages/settings/route/SettingsRoutes'
import Register from 'pages/register'
import Login from 'pages/login'
import ForgotPassword from 'pages/forgotPassword'
import ResetPassword from 'pages/resetPassword'
import RegisterMultiorg from 'pages/registerMultiorg'
import MemberInvite from 'pages/memberInvite'
import AgencyInvite from 'pages/agencyInvite'
import { KeywordsRoutes } from 'pages/keywords'
import UnAuthLayout from 'app/layouts/unAuthLayout'
import AuthLayoutVue from 'app/layouts/authLayoutVue'
import AuthRefreshLayout from 'app/layouts/authRefreshLayout'
import RootLayout from 'app/layouts/rootLayout'

const routersContent = createRoutesFromElements(
  <Route element={<RootLayout />} errorElement={<ErrorPage router='appReact' />}>
    <Route element={<AuthRefreshLayout />}>
      <Route path={`/${AuthRoutesEnum.resetPassword}/:token`} element={<ResetPassword />} />
      <Route path={`/${AuthRoutesEnum.memberInvite}/:token`} element={<MemberInvite />} />
      <Route path={`/${AuthRoutesEnum.agencyInvite}/:token`} element={<AgencyInvite />} />
      <Route element={<AuthLayoutVue type='default' />}>
        <Route path={`/${AuthRoutesEnum.registerMultiOrg}`} element={<RegisterMultiorg />} />
      </Route>
      <Route element={<AuthLayoutVue />}>
        <Route
          path={`/${ConversationRoutesEnum.conversations}/*`}
          element={<ConversationsRouters />}
        />
        <Route path={`/${WorkflowRoute.root}/*`} element={<WorkflowsRoutes />} />
        <Route path={`/${BroadcastRoutesEnum.broadcasts}/*`} element={<BroadcastRoutes />} />
        <Route path={`/${TriggerRouts.triggers}/*`} element={<TriggersRouter />} />;
        <Route path={`/${ChatbotRoute.root}/*`} element={<ChatbotRoutes />} />
        <Route path='/contacts/*' element={<ContactsRouters />} />
        <Route path={`/${AdminRoutesEnum.admin}/*`} element={<AdminRoutes />} />
        <Route path={`/${SettingsRoutesEnum.root}/*`} element={<SettingsRoutes />} />
        <Route path={`/${KeywordsRoutesEnum.root}/*`} element={<KeywordsRoutes />} />
        <Route path='/*' element={<div />} />
      </Route>

      <Route element={<UnAuthLayout />}>
        <Route path={`/${AuthRoutesEnum.register}`} element={<Register />} />
        <Route path={`/${AuthRoutesEnum.login}`} element={<Login />} />
        <Route path={`/${AuthRoutesEnum.forgotPassword}`} element={<ForgotPassword />} />
      </Route>
    </Route>
  </Route>
)

const appRouterContent = wrapCreateBrowserRouter(createBrowserRouter)(routersContent)

export const AppRouterContent = () => <RouterProvider router={appRouterContent} />
