import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Badge, IMenuItemProps, Menu, PageLayout, Typography } from 'shared/ui'
import { setHideIntercom } from 'entities/Intercom/lib/Intercom'
import { AdminRoutesEnum } from 'pages/admin/route/type'
import { adminComplianceStore } from 'pages/admin/pages/compliance/store/adminComplianceStore'
import styles from 'pages/chatbot/layout/styles.module.scss'

export const AdminLayout = observer(() => {
  const { inReviewCount, loadData } = adminComplianceStore

  useEffect(() => {
    loadData()
    setHideIntercom(true)
    return () => {
      setHideIntercom(false)
    }
  }, [])

  const items: IMenuItemProps[] = [
    {
      type: 'nav',
      to: AdminRoutesEnum.organizations,
      label: 'Organizations',
      icon: 'building',
    },
    {
      type: 'nav',
      to: AdminRoutesEnum.compliance,
      label: (
        <Typography
          variant={'body-md-regular'}
          className={styles.label}
          ariaLabel={'ComplianceQueue'}
        >
          Compliance queue <Badge content={inReviewCount} variant={'blue'} showAll />
        </Typography>
      ),
      icon: 'users',
    },
    {
      type: 'nav',
      to: AdminRoutesEnum.status,
      label: 'Status',
      icon: 'settings',
    },
    {
      type: 'nav',
      to: AdminRoutesEnum.customPrices,
      label: 'Custom Prices',
      icon: 'dollar',
    },
  ]

  return <PageLayout leftContent={<Menu title='Admin Panel' menuItems={items} />} />
})
