import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { dropDownStore, ITabItem } from 'shared/ui'
import { integrationsStore } from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { IParamsCreateContact } from 'entities/Contacts/api/types'
import { CreateContactErrors } from 'entities/Contacts/errorHandler'
import { ContactsSearchStore } from 'widgets/ContactsSearch'
import { contactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'

export enum ContactsSearchVariantEnum {
  default = 'default',
  checkbox = 'checkbox',
}

export enum ContactsSearchListVariantEnum {
  list_contacts = 'list_contacts',
  help = 'help',
  create_contact = 'create_contact',
  selected_contacts = 'selected_contacts',
}

export enum IntegrationsTabEnum {
  salesmsg = 'salesmsg',
  pipedrive = 'pipedrive',
  hubspot = 'hubspot',
  activecampaign = 'activecampaign',
  infusionsoft = 'infusionsoft',
  salesforce = 'salesforce',
  selected = 'selected',
}

export interface IIntegrationsTab extends ITabItem {
  key: IIntegrationsTabKey
}

export type IIntegrationsTabKey =
  | IntegrationsTabEnum.salesmsg
  | IntegrationsTabEnum.pipedrive
  | IntegrationsTabEnum.hubspot
  | IntegrationsTabEnum.activecampaign
  | IntegrationsTabEnum.infusionsoft
  | string

type IAllowedIntegrations = {
  key: IIntegrationsTabKey
  store: ContactsSearchStore
}

type IInitSearchParent = {
  disabledHelp: boolean
  disabledAddNumber: boolean
  paramsCreateContact: {
    forLink: boolean
    fromConversation: boolean
  }
  hiddenIntegrationsTabs: IIntegrationsTabKey[]
}

export class ContactsSearchParentStore {
  searchNumber = ''
  creatingNumberLoading = false
  tabsMap: Map<string, IIntegrationsTab> = new Map()
  selectedMap: Map<number, Contact> = new Map()
  tabsLoading = false
  activeTab: IIntegrationsTabKey = IntegrationsTabEnum.salesmsg
  allowedIntegrations: IAllowedIntegrations[] = [
    {
      key: IntegrationsTabEnum.salesmsg,
      store: new ContactsSearchStore(IntegrationsTabEnum.salesmsg),
    },
    {
      key: IntegrationsTabEnum.hubspot,
      store: new ContactsSearchStore(IntegrationsTabEnum.hubspot),
    },
    {
      key: IntegrationsTabEnum.salesforce,
      store: new ContactsSearchStore(IntegrationsTabEnum.salesforce),
    },
    {
      key: IntegrationsTabEnum.activecampaign,
      store: new ContactsSearchStore(IntegrationsTabEnum.activecampaign),
    },
    {
      key: IntegrationsTabEnum.infusionsoft,
      store: new ContactsSearchStore(IntegrationsTabEnum.infusionsoft),
    },
    {
      key: IntegrationsTabEnum.pipedrive,
      store: new ContactsSearchStore(IntegrationsTabEnum.pipedrive),
    },
    {
      key: IntegrationsTabEnum.selected,
      store: new ContactsSearchStore(IntegrationsTabEnum.selected),
    },
  ]
  debounceFetchContacts
  loadingContactsLocal = false
  loadingContactsLocalSetTimeout: ReturnType<typeof setTimeout> | null = null
  disabledHelp = false
  disabledAddNumber = false
  paramsCreateContact = {
    forLink: true,
    fromConversation: true,
  }
  hiddenIntegrationsTabs: IIntegrationsTabKey[] = []
  disposeReactionInitContacts: ReturnType<typeof reaction> | null = null
  disposeReactionInitSearchNumber: ReturnType<typeof reaction> | null = null

  constructor() {
    this.reactionInitContacts()

    this.debounceFetchContacts = debounce(this.initContacts, 500)

    makeAutoObservable(this)
  }

  get selectedCount() {
    return this.selectedMap.size
  }

  get hasSelected() {
    return !!this.selectedCount
  }

  get selectedList() {
    return Array.from(this.selectedMap.values())
  }

  isSelected(item: Contact) {
    return this.selectedMap.has(item.id)
  }

  init = (props: IInitSearchParent) => {
    this.disabledHelp = props.disabledHelp
    this.disabledAddNumber = props.disabledAddNumber
    this.hiddenIntegrationsTabs = props.hiddenIntegrationsTabs
    this.paramsCreateContact = props.paramsCreateContact
    this.allowedIntegrations = this.allowedIntegrations.filter(
      (integration) => !props.hiddenIntegrationsTabs.includes(integration.key)
    )
  }

  initSelectedMap = (contacts: Map<number, Contact>) => {
    this.selectedMap = new Map(contacts)
  }

  initTabs = async (hiddenIntegretionsTabs: IIntegrationsTabKey[]) => {
    runInAction(() => {
      this.tabsLoading = true
    })

    await integrationsStore.fetchIntegrations()

    this.allowedIntegrations.forEach(({ key }) => {
      if (hiddenIntegretionsTabs.includes(key)) {
        return
      }

      if (key === IntegrationsTabEnum.salesmsg) {
        this.tabsMap.set(IntegrationsTabEnum.salesmsg, {
          key: IntegrationsTabEnum.salesmsg,
          name: 'Salesmsg',
          count: 0,
        })
      }

      const integration = integrationsStore.getIntegration(key)

      if (integration?.isConnected) {
        if (hiddenIntegretionsTabs.includes(this.activeTab)) {
          this.activeTab = integration.key
        }

        this.tabsMap.set(integration.key, {
          key: integration.key,
          name: integration.name,
          count: 0,
        })
      }
    })

    runInAction(() => {
      this.tabsLoading = false
    })
  }

  updateTabItem = (key: string, props = {}) => {
    const tabItem = this.tabsMap.get(key)

    if (tabItem) {
      this.tabsMap.set(key, {
        ...tabItem,
        ...props,
      })
    }
  }

  debounceInitContacts = () => {
    this.initCancelTokenSource()

    if (!this.canSearch) return

    runInAction(() => {
      this.loadingContactsLocal = true

      if (this.loadingContactsLocalSetTimeout) {
        clearTimeout(this.loadingContactsLocalSetTimeout)
      }
    })

    this.debounceFetchContacts()

    this.loadingContactsLocalSetTimeout = setTimeout(() => {
      runInAction(() => {
        this.loadingContactsLocal = false
      })
    }, 500)
  }

  initContacts = () => {
    this.allowedIntegrations.forEach(async (item) => {
      const integration = integrationsStore.getIntegration(item.key)

      if (item.key === IntegrationsTabEnum.salesmsg) {
        await item.store.fetch({ search: this.searchNumber })
        this.updateTabItem(item.key, {
          count: this.getTotal(item.key),
        })
      }

      if (integration?.isConnected) {
        await item.store.fetch({ search: this.searchNumber })
        this.updateTabItem(item.key, {
          count: this.getTotal(item.key),
        })
      }
    })
  }

  handleSelectTab = (tab: IIntegrationsTab) => {
    this.activeTab = tab.key
  }

  toggleSelectedTab = () => {
    if (this.activeTab === IntegrationsTabEnum.selected) {
      this.activeTab = IntegrationsTabEnum.salesmsg
      return
    }

    this.activeTab = IntegrationsTabEnum.selected
  }

  handleCreateContactSearchNumber = async (
    callback: (item: Contact, params?: { isNew?: boolean }) => void = () => {},
    onError?: () => void
  ) => {
    if (this.creatingNumberLoading) return

    runInAction(() => {
      this.creatingNumberLoading = true
    })

    try {
      const contact = await contactsStore.createContact(
        {
          number: this.searchNumber || '',
          ...this.paramsCreateContact,
        },
        this.paramsCreateContact.fromConversation
          ? {
              catchError: CreateContactErrors.trialLimit,
              onError,
            }
          : undefined
      )

      if (contact) callback(contact, { isNew: true })
    } catch (e) {
      console.error(e)
    }

    runInAction(() => {
      this.creatingNumberLoading = false
    })
  }

  handleAddCheckboxItem = async (item: Contact, callback: (item: Contact) => void = () => {}) => {
    if (this.selectedMap.has(item.id)) {
      this.selectedMap.delete(item.id)
    } else {
      this.selectedMap.set(item.id, item)
    }
    return callback(item)
  }

  handleAddDefaultItem = async (
    item: Contact,
    callback: (item: Contact, options?: { isNew?: boolean }) => void = () => {}
  ) => {
    if (this.creatingNumberLoading) return
    if (item.hasIntegrationType) {
      runInAction(() => {
        this.creatingNumberLoading = true
      })
      const info = item.integrationInfo
      const email = Array.isArray(info?.email) ? info?.email?.[0] || null : info?.email || null

      // Open a modal window if the contact has two phone numbers.
      if (item.phoneCount > 1) {
        contactChoosePhoneModalStore.openModal({
          contact: item,
          callback: callback,
          params: this.paramsCreateContact,
        })

        runInAction(() => {
          this.creatingNumberLoading = false
          dropDownStore.allHide()
        })
        return
      }

      const params: IParamsCreateContact = {
        email,
        first_name: item.integrationInfo?.first_name,
        last_name: item.integrationInfo?.last_name,
        number: item.integrationInfo?.phone || item.integrationInfo?.mobile_phone,
        forLink: this.paramsCreateContact.forLink,
        fromConversation: this.paramsCreateContact.fromConversation,
      }

      if (item.hasIntegrationType) {
        const integrration = integrationsStore.getIntegration(item.integrationType)

        params.integration_id = integrration?.id
        params.contact_integration_id = `${item?.id}`
      }

      if (!this.paramsCreateContact.forLink) {
        callback(item)
        return
      }

      const isContactExist = await contactsStore.getContactByNumber(item.phone ?? '')

      const contact = await contactsStore.createContact(params)

      if (contact) callback(contact, { isNew: !isContactExist })

      runInAction(() => {
        this.creatingNumberLoading = false
      })

      return
    }

    callback(item)
  }

  handleAddItem = async (
    item: Contact,
    callback: (item: Contact) => void = () => {},
    type: ContactsSearchVariantEnum
  ) => {
    switch (type) {
      case ContactsSearchVariantEnum.checkbox:
        return this.handleAddCheckboxItem(item, callback)

      case ContactsSearchVariantEnum.default:
        return this.handleAddDefaultItem(item, callback)

      default:
        return this.handleAddDefaultItem(item, callback)
    }
  }

  handleInputSearch = (search: string) => {
    this.searchNumber = search
  }

  handleClearSelected = () => {
    if (this.activeTab === IntegrationsTabEnum.selected) {
      this.activeTab = IntegrationsTabEnum.salesmsg
    }
    this.selectedMap.clear()
  }

  initCancelTokenSource = () => {
    this.allowedIntegrations.forEach(({ store }) => {
      store.initCancelTokenSource()
    })
  }

  reset = () => {
    this.disposeReactionInitContacts?.()
    this.disposeReactionInitSearchNumber?.()
    this.resetCancelTokenSource()
  }

  resetCancelTokenSource = () => {
    this.allowedIntegrations.forEach(({ store }) => {
      store.resetCancelTokenSource()
    })
  }

  reactionInitContacts = () => {
    this.disposeReactionInitContacts?.()
    this.disposeReactionInitContacts = reaction(
      () => this.searchNumber,
      () => {
        this.debounceInitContacts()
      }
    )
  }

  getTotal = (key: string) => {
    const allowedIntegration = this.allowedIntegrations.find((item) => item.key === key)

    if (allowedIntegration) {
      return allowedIntegration.store.total
    }

    return 0
  }

  get loadingContacts() {
    return Boolean(
      this.allowedIntegrations.map((item) => item.store.loading).filter((item) => item).length ||
        this.loadingContactsLocal
    )
  }

  get hasTotalContacts() {
    return Boolean(
      this.allowedIntegrations.map((item) => item.store.total).filter((item) => item).length
    )
  }

  get showTypeView() {
    if (this.activeTab === IntegrationsTabEnum.selected)
      return ContactsSearchListVariantEnum.selected_contacts
    if (!this.searchNumber.length) return false
    if (this.loadingContacts) return ContactsSearchListVariantEnum.list_contacts
    if (!this.disabledHelp && this.canSearchHelp) return ContactsSearchListVariantEnum.help
    if (!this.disabledAddNumber && !this.hasTotalContacts && this.searchNumber)
      return ContactsSearchListVariantEnum.create_contact

    return ContactsSearchListVariantEnum.list_contacts
  }

  get canSearchHelp() {
    if (!this.searchNumber.length) return false

    return this.searchNumber.length <= 2
  }

  get canSearch() {
    return this.searchNumber.length >= 3
  }

  get tabs() {
    return Array.from(this.tabsMap.values())
  }
}
