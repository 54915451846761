// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FN69gDkE3RlSRI6Yiw7Q{display:flex;flex-direction:column}.mM7A8Npso6cIgnbNm9iw{margin-bottom:32px;border-radius:12px;overflow:hidden;min-height:276px}.mM7A8Npso6cIgnbNm9iw img{display:block;max-width:492px}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/all/ui/EmptyStateKeywords/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,0BACE,aAAA,CACA,eAAA","sourcesContent":[".title {\n  display: flex;\n  flex-direction: column;\n}\n\n.emptyPreview {\n  margin-bottom: 32px;\n  border-radius: 12px;\n  overflow: hidden;\n  min-height: 276px;\n\n  img {\n    display: block;\n    max-width: 492px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "FN69gDkE3RlSRI6Yiw7Q",
	"emptyPreview": "mM7A8Npso6cIgnbNm9iw"
};
export default ___CSS_LOADER_EXPORT___;
