import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBaseView/context/knowledgeBaseViewContext'

export const DocumentsBulkActions = observer(() => {
  const { tableStore, onDeleteDocuments, onBulkDownloadDocuments } = useKnowledgeBaseViewContext()

  return (
    <>
      <Button
        text='Download'
        icon='download'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={() => onBulkDownloadDocuments(tableStore.selectedIds as number[])}
      />
      <Button
        text='Delete'
        icon='delete'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={() => onDeleteDocuments(tableStore.selectedIds as number[])}
      />
    </>
  )
})
