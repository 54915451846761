import { observer } from 'mobx-react-lite'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TextareaWithLabel } from 'shared/ui/Textarea'
import { Button, Typography } from 'shared/ui'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'

import styles from './styles.module.scss'
import { useCaseDescMaxTextLength, useCaseDescMinTextLength } from '../store/UseCaseStore'

export const CampaignUseCase = observer(() => {
  const { stepLoading, useCaseStore } = useLocalNumbersContext()
  const {
    useCaseAsItems,
    useCaseId,
    useCaseDesc,
    isStep1Disabled,
    setUseCase,
    setUseCaseDesc,
    openUseCaseExampleModal,
    campaignUseCaseNextClick,
  } = useCaseStore

  return (
    <div className={styles.contentWrap}>
      <Typography
        variant='body-md-regular'
        ariaLabel='Business_info_line'
        className={styles.pageDesc}
      >
        Make sure this information is an exact match to your official paperwork
      </Typography>
      <SelectField
        labelText='Use case'
        items={useCaseAsItems}
        setValue={setUseCase}
        value={useCaseId}
        btnProps={{ size: 'extraLarge' }}
      />
      {useCaseId && (
        <TextareaWithLabel
          className={styles.useCaseDesc}
          value={useCaseDesc}
          limit={useCaseDescMaxTextLength}
          minLimit={useCaseDescMinTextLength}
          errorType='bottomText'
          name='description'
          placeholder='Add a description of how the selected use case applies to your business'
          onChange={setUseCaseDesc}
          labelProps={{
            label: {
              text: 'Use case description',
              rightNode: (
                <Button
                  onClick={openUseCaseExampleModal}
                  text={'See example'}
                  size='small'
                  typeBtn='informative'
                />
              ),
            },
          }}
        />
      )}

      <Button
        fullWidth
        loading={stepLoading}
        disabled={isStep1Disabled || stepLoading}
        className={styles.nextButton}
        text='Next'
        onClick={campaignUseCaseNextClick}
      />
    </div>
  )
})
