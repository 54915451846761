import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import optInPaperFormApproved from 'shared/assets/images/optInPaperFormApproved.png'
import optInPaperFormRejected from 'shared/assets/images/optInPaperFormRejected.png'
import styles from './styles.module.scss'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { OptInConsent } from './OptInConsent'

export const OptInPaperFormContent = observer(() => {
  const { optInConsentStore, stepLoading } = useLocalNumbersContext()
  const {
    isPaperFormNextDisabled,
    optInPaperFormAttachmentError,
    openApprovedExampleModal,
    uploadPaperFormOptInImageAttachment,
    clearPaperFormAttachmentError,
  } = optInConsentStore
  return (
    <div className={classNames(styles.contentWrap, styles.optInConsentWrap)}>
      <OptInConsent
        stepLoading={stepLoading}
        isNextDisabled={isPaperFormNextDisabled}
        attachmentError={optInPaperFormAttachmentError}
        increaseStep={() => {}}
        clearAttachmentError={clearPaperFormAttachmentError}
        uploadOptInImageAttachment={uploadPaperFormOptInImageAttachment}
        seeApprovedExampleClick={() => {
          openApprovedExampleModal(
            [optInPaperFormApproved],
            ['Opt-in / Paper Form / Approved example']
          )
        }}
        approvedExampleClick={() =>
          openApprovedExampleModal(
            [optInPaperFormApproved, optInPaperFormRejected],
            ['Opt-in / Paper Form / Approved example', 'Opt-in / Paper Form / Rejected example']
          )
        }
        rejectedExampleClick={() =>
          openApprovedExampleModal(
            [optInPaperFormRejected, optInPaperFormApproved],
            ['Opt-in / Paper Form / Rejected example', 'Opt-in / Paper Form / Approved example']
          )
        }
        firstBlock={{
          title: 'Include compliance language to your Paper Form',
          desc: 'Include the full URL of your Terms of Service and Privacy Policy in the compliance language. (e.g. “https:/example.com/terms-of-service/”).',
          copyTitle: 'Compliance language',
          copyText:
            'I agree to receive recurring automated text messages at the phone number provided. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our terms of service - https:/example.com/terms-of-service and privacy policy - https:/example.com/privacy-policy/.',
        }}
        secondBlock={{
          title: 'Include this paragraph to your Privacy Policy',
          desc: 'Sharing data is not allowed. If text messaging data/consent is not shared, include the paragraph below to your Privacy Policy.',
          copyText:
            'Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the Text Message services.',
        }}
      />
    </div>
  )
})
