import hubspot1 from 'shared/assets/images/integrations/hubspot/hubspot1.png'
import hubspot2 from 'shared/assets/images/integrations/hubspot/hubspot2.png'
import hubspot3 from 'shared/assets/images/integrations/hubspot/hubspot3.png'
import hubspot4 from 'shared/assets/images/integrations/hubspot/hubspot4.png'
import hubspot5 from 'shared/assets/images/integrations/hubspot/hubspot5.png'

import { getCDNAssetURL } from 'shared/lib'
import { links } from 'shared/constants/links'
import type { IIntegrationKey } from 'entities/Integrations/api/types'

export type IntegrationTextData = {
  subheadline: string
  overview: string
  keyBenefits: string[]
  images: string[]
  docs: string
}

export const CATEGORY_LABELS: Record<string, string> = {
  all: 'All integrations',
  my: 'My integrations',
  crm: 'CRM',
  marketing: 'Marketing automation',
  communication: 'Communication',
  productivity: 'Productivity',
  workflow: 'Workflow automation',
}

export const integrationsTextDataMap: Partial<Record<IIntegrationKey, IntegrationTextData>> = {
  hubspot: {
    subheadline: 'Text, call and build automated SMS workflows inside HubSpot.',
    overview:
      'Salesmsg integrates directly with HubSpot, allowing your team to send and receive text messages from within your HubSpot account. This streamlines communication and improves customer engagement by enabling SMS and calling capabilities directly from the CRM.',
    keyBenefits: [
      'Build SMS marketing workflows to enhance outreach efficiency.',
      'Sync contacts automatically from HubSpot to Salesmsg.',
      'Send and receive text messages directly from HubSpot.',
      'Manage two-way messaging for personalized communication.',
      'Automatically log messages and calls into HubSpot for easy tracking.',
    ],
    images: [hubspot1, hubspot2, hubspot3, hubspot4, hubspot5].map((image) =>
      getCDNAssetURL(image)
    ),
    docs: links.hubspotGuide,
  },
  activecampaign: {
    subheadline:
      'Text back and forth with your contacts, handle all calls, and build automated SMS campaigns inside ActiveCampaign.',
    overview:
      'Salesmsg integrates with ActiveCampaign to provide two-way text messaging and calling functionality. This integration allows users to send, receive, and manage text messages directly from ActiveCampaign, place all calls, and build automated SMS campaigns to enhance customer communication.',
    keyBenefits: [
      'Automate bulk text messaging within ActiveCampaign automations.',
      'Send personalized SMS/MMS from local or toll-free numbers.',
      'Automatically log messages and calls for easy tracking.',
      'Use SMS in drip campaigns and appointment reminders.',
    ],
    images: [],
    docs: '',
  },
  infusionsoft: {
    subheadline:
      'Text back and forth and streamline all customer communication directly from Keap.',
    overview:
      'Salesmsg integrates with Keap to offer seamless two-way text messaging with your leads and customers. This allows your team to sync Keap contacts and send personalized messages directly from inside the CRM. Your team can also build automated SMS workflows. This streamlines all customer engagement and follow-ups.',
    keyBenefits: [
      'Set up automated text message workflows for efficient communication.',
      'Sync Keap contacts automatically with Salesmsg.',
      'Personalize communications with two-way text messaging.',
      'Track message and call history within Keap.',
    ],
    images: [],
    docs: '',
  },
  pipedrive: {
    subheadline:
      'Boost lead engagement with texting, calling, and voicemail directly inside Pipedrive.',
    overview:
      'Salesmsg integrates with Pipedrive to provide powerful two-way text messaging, calling, and ringless voicemail inside one platform. This integration streamlines communication directly from the Pipedrive platform, enabling your teams to engage with leads and customers more efficiently.',
    keyBenefits: [
      'Send and receive text messages and calls from within Pipedrive.',
      'Log texts and calls automatically for easy tracking.',
      'Access new features like video, audio texting, and attachments.',
      'Drop ringless voicemails directly into customer inboxes.',
    ],
    images: [],
    docs: '',
  },
  salesforce: {
    subheadline: 'Streamline your texting and calling with this seamless Salesforce integration.',
    overview:
      'Salesmsg integrates seamlessly with Salesforce. This allows your team to manage text messaging and calling within your Salesforce account. This integration enhances customer communication by enabling two-way messaging directly from the Salesforce platform, making it easier for your team to engage with contacts and track interactions.',
    keyBenefits: [
      'Send and receive text messages and calls from within Salesforce.',
      'Automatically sync message data and logs into Salesforce.',
      'Personalize communications with two-way messaging one-on-one or in bulk',
      'Create automated workflows to improve outreach efficiency.',
    ],
    images: [],
    docs: '',
  },
  aircall: {
    subheadline: 'Combine voice and text messaging into one platform with Aircall integration.',
    overview:
      'Salesmsg integrates with Aircall to combine voice and text messaging capabilities for your business into one platform. This creates one seamless communication experience for all your teams. Your entire team can manage calls and text messages from a single platform, to streamline customer interactions and improving efficiency.',
    keyBenefits: [
      'Use Aircall for calls and Salesmsg for texting inside one platform.',
      'Send and receive text messages directly from the Aircall dashboard.',
      'Automate and log all communications for easy tracking.',
      'Improve team collaboration with voice and text capabilities.',
    ],
    images: [],
    docs: '',
  },
  slack: {
    subheadline:
      'Text back and forth with customers and streamline all your team communication from inside Slack.',
    overview:
      'Salesmsg integrates with Slack to streamline text messaging directly within Slack channels. This allows your team to send, receive, and manage text messages without leaving the Slack platform. This enhances team collaboration and ensures fast responses to customer inquiries.',
    keyBenefits: [
      'Send and receive text messages directly within Slack.',
      'Receive instant notifications of incoming messages.',
      'Collaborate and respond quickly to customer inquiries.',
      'Manage message history and track conversations across teams.',
      'Streamline communication workflows within a single platform.',
    ],
    images: [],
    docs: '',
  },
  google: {
    subheadline:
      'Simplify onboarding and boost team collaboration with Google Workspace integration.',
    overview:
      'Salesmsg integrates with Google Workspace to allow you to sign up for Salesmsg through Google, and seamlessly invite all your Google Workspace users into your Salesmsg account. This simplifies your Salesmsg onboarding and boosts team collaboration.',
    keyBenefits: [],
    images: [],
    docs: '',
  },
  intercom: {
    subheadline:
      'Text back and forth with your customers and automate SMS workflows from inside Intercom.',
    overview:
      'Salesmsg integrates with Intercom to allow you two-way texting with your customers from directly inside the Intercom platform. This integration allows teams to manage customer communications more effectively by syncing Intercom to texting conversations and automating workflows for faster responses.',
    keyBenefits: [
      'Text back and forth with customers within Intercom conversations.',
      'Automatically sync and log messages between Salesmsg and Intercom.',
      'Personalize customer interactions with two-way text messaging.',
      'Set up automated SMS workflows to improve efficiency.',
      'Easily manage and track all conversations in one place.',
    ],
    images: [],
    docs: '',
  },
  zapier: {
    subheadline:
      'Automate your text messaging workflows by connecting Salesmsg with 7,000+ apps via Zapier.',
    overview:
      'Salesmsg integrates with Zapier to automate text messaging workflows by connecting Salesmsg with over 7,000 apps. This allows users to create custom triggers and actions, making communication more efficient and automated across multiple platforms.',
    keyBenefits: [
      'Automate text messaging workflows with Zapier.',
      'Connect Salesmsg with 7,000+ apps for seamless integration.',
    ],
    images: [],
    docs: '',
  },
  webhook: {
    subheadline: 'Add text messaging into any custom workflows with Salesmsg.',
    overview: '',
    keyBenefits: [],
    images: [],
    docs: '',
  },
  make: {
    subheadline: 'Build automated SMS workflows by connecting Salesmsg with 2,000+ apps via Make.',
    overview:
      'Salesmsg integrates with Make | Automation Software | Connect Apps & Design Workflows to automate SMS workflows by connecting Salesmsg with over 2,000 apps. This integration allows you to build custom, multi-step automations to save time and cut down on manual tasks across multiple communication channels.',
    keyBenefits: [
      "Automate complex workflows with Make's visual, drag-and-drop editor.",
      'Connect Salesmsg to over 2,000 apps for powerful integrations.',
      'Create multi-step automations to streamline communication.',
      'Reduce manual work and boost efficiency with automated processes.',
    ],
    images: [],
    docs: '',
  },
}
