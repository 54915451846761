import { AxiosError, CancelTokenSource } from 'axios'
import { type IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import type { IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ComplianceApi } from 'entities/Compliance'
import type {
  IBusinessInformation,
  ICompanyLocation,
  IComplianceSettingsStore,
  ICountryState,
  IPointOfContact,
  ITwilioServiceError,
} from 'entities/Compliance/api/type'
import { organizationStore } from 'entities/Organization'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { EinStore } from './einStore'
import { BusinessInfoStore } from './businessInfoStore'
import { PeopleToContactStore } from './peopleToContactStore'

export class BusinessProfileStore {
  constructor(private _complianceSettingsStore: IComplianceSettingsStore) {
    makeAutoObservable(this)

    this.init()
    this.reactionChangeStep()
  }

  einStore = new EinStore(this)
  businessInfoStore = new BusinessInfoStore(this)
  peopleToContactStore = new PeopleToContactStore(this)

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _companyLocation: ICompanyLocation | null = null
  private _states: ICountryState[] = []
  private _generalInfoActiveTab = 1
  private _isTermsAndPrivacyChecked = false
  private _fieldsError: Record<string, string> | null = null

  private _modalIdDoNotHaveEI = 'modal_id_do_not_have_EIN'

  private _stepLoading = false

  private _errors: ITwilioServiceError[] = []

  get complianceSettingsStore() {
    return this._complianceSettingsStore
  }

  get generalInfoActiveTab() {
    return this._generalInfoActiveTab
  }

  get companyLocation() {
    return this._companyLocation
  }

  get states(): IDropdownItem[] {
    return this._states.map((item) => ({ label: item.name, id: item.id }))
  }

  get isTermsAndPrivacyChecked() {
    return this._isTermsAndPrivacyChecked
  }

  get stepLoading() {
    return this._stepLoading
  }

  get errors() {
    return this._errors
  }

  get errorMessage() {
    return this.errors.map((error) => error.error_message).join('\n')
  }

  get isUsaLocation() {
    return this._companyLocation === 'US'
  }

  get fieldsError() {
    return this._fieldsError
  }

  get fieldsErrorCommonText() {
    if (this.fieldsError && Object.keys(this.fieldsError).length) {
      return Object.values(this.fieldsError).map((error, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          {error}
        </div>
      ))
    }

    return null
  }

  init = () => {
    this.complianceSettingsStore.stepsStore.setTotal(5)
    const { businessInformation } = this.complianceSettingsStore
    this.einStore.setEinSearch(businessInformation?.registration_number || '')
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  deleteErrorValue = (keyName: string) => {
    if (this.fieldsError) {
      delete this.fieldsError[keyName]
    }
  }

  setFieldsError = (fieldError: Record<string, string> | null) => {
    this._fieldsError = fieldError
  }

  setStepLoading = (value: boolean) => {
    this._stepLoading = value
  }

  handleClose = (modalId: string) => {
    modalStore.removeModal(modalId)
  }

  openDoNotHaveEINModal = () => {
    const title = this.isUsaLocation ? 'No United States EIN?' : 'No Canadian Business Number?'
    const desc = this.isUsaLocation
      ? 'If you don’t have a United States EIN, your only eligible option is to use toll-free numbers'
      : 'If you don’t have a Canadian Business Number, your only eligible option is to use toll-free numbers'

    modalStore.addModal({
      id: this._modalIdDoNotHaveEI,
      type: ModalTypeList.INFO,
      onClose: () => {
        this.handleClose(this._modalIdDoNotHaveEI)
      },
      title,
      desc,
      primaryAction: {
        text: 'Continue with toll-free',
        onAction: async () => {
          modalStore.removeModal(this._modalIdDoNotHaveEI)
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(this._modalIdDoNotHaveEI),
      },
    })
  }

  setCompanyLocation = (value: ICompanyLocation) => {
    this._companyLocation = value
  }

  setStates = (states: ICountryState[]) => {
    this._states = states
  }

  loadBusinessProfileStates = async () => {
    try {
      const { data } = await ComplianceApi.getBpStates()

      const states = data.filter((item) => item.country_id === this._companyLocation)

      this.setStates(states)
    } catch (e) {
      console.error(e)
    }
  }

  setGeneralInfoActiveTab = (value: number) => {
    this._generalInfoActiveTab = value
  }

  changeGeneralInfoTab = (value: number) => {
    const isEinNumberExist = this.complianceSettingsStore.businessInformation?.registration_number

    if (!isEinNumberExist && value === 1) {
      this.einStore.resetData()
    }
    this.setGeneralInfoActiveTab(value)
  }

  toggleIsTermsAndPrivacyChecked = () => {
    this._isTermsAndPrivacyChecked = !this._isTermsAndPrivacyChecked
  }

  getBusinessInformation = (step: number) => {
    const { einItem } = this.einStore

    const { id: organizationId } = organizationStore || {}

    if (!einItem || !organizationId || !this.companyLocation) return null

    const {
      websiteUrl,
      noWebsiteUrl,
      activeRegionOfOperationIds,
      activeBusinessTypeId,
      socialMediaUrl,
    } = this.businessInfoStore

    const getFormStep = (): string => {
      return `form_step_${step}`
    }

    return {
      business_information: {
        business_name: einItem.name,
        business_type_id: activeBusinessTypeId,
        industry_id: this.businessInfoStore.activeBusinessIndustryId || 0,
        city: einItem.city,
        country: this.companyLocation,
        organization_id: organizationId,
        regions_of_operation: activeRegionOfOperationIds,
        reg_progress_status: getFormStep(),
        registration_number: this.einStore.einFromSearch,
        irs_ein_document: this.einStore.irsEinAttachment,
        registration_type_id: this.isUsaLocation ? 2 : 1,
        state_id: einItem.state,
        street_address_1: einItem.street1,
        street_address_2: einItem.street2 || null,
        website_url: noWebsiteUrl ? null : websiteUrl,
        without_website: noWebsiteUrl,
        social_media_url: noWebsiteUrl ? socialMediaUrl : null,
        zip_code: einItem.zip,
      },
      step,
    }
  }

  prepareFieldsErrorAndSave = (data: Record<string, string>) => {
    const result = {} as Record<string, string>

    Object.keys(data).forEach((key) => {
      let value = data[key]

      if (Array.isArray(value)) {
        value = value[0]
      }

      if (key.startsWith('business_information.') || key.startsWith('points_of_contact')) {
        const newKey = key.replace('business_information.', '').replace('points_of_contact.', '')
        result[newKey] = value
      } else {
        result[key] = value
      }
    })

    this.setFieldsError(result)
  }

  submitGeneralInfo = async (step: number, callBack: () => void) => {
    const businessInformation = this.getBusinessInformation(step)

    if (!businessInformation) return

    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpBusinessInformation(businessInformation)

      this.setDataFromBusinessProfileRequest(data)
      callBack()
    } catch (e) {
      if (e instanceof AxiosError) {
        const { data } = e.response || {}
        this.prepareFieldsErrorAndSave(data)
      }

      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  submitPointsOfContact = async (callBack: () => void) => {
    const { primaryContact, secondaryContact, contactsConfirmed } = this.peopleToContactStore

    if (!primaryContact.jobPosition) return

    const pointsOfContacts = [
      {
        business_title: primaryContact.businessTitle,
        email: primaryContact.email,
        first_name: primaryContact.firstName,
        job_position_id: String(primaryContact.jobPosition),
        last_name: primaryContact.lastName,
        phone_number: primaryContact.phoneNumber,
      },
    ]

    if (this.peopleToContactStore.isSecondContact) {
      pointsOfContacts.push({
        business_title: secondaryContact.businessTitle,
        email: secondaryContact.email,
        first_name: secondaryContact.firstName,
        job_position_id: String(secondaryContact.jobPosition),
        last_name: secondaryContact.lastName,
        phone_number: secondaryContact.phoneNumber,
      })
    }

    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpPointsOfContact({
        contacts_confirmed: contactsConfirmed,
        points_of_contact: pointsOfContacts,
        step: 3,
      })

      this.complianceSettingsStore.setDataFromRequest(data)

      callBack()
    } catch (e) {
      if (e instanceof AxiosError) {
        const { data } = e.response || {}
        this.prepareFieldsErrorAndSave(data)
      }

      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  submitTermsAndPrivacy = async () => {
    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpTerms({
        terms_accepted: this.isTermsAndPrivacyChecked,
        step: 4,
      })

      this.complianceSettingsStore.setDataFromRequest(data)

      uiStore.changeRoute({
        path: 'settings/organization/compliance',
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  setDataFromBusinessProfileRequest = ({
    businessInformation,
    pointsOfContact = [],
  }: {
    businessInformation: IBusinessInformation
    pointsOfContact?: IPointOfContact[] | null
  }) => {
    this.complianceSettingsStore.setBusinessInformation(businessInformation)
    this.setCompanyLocation(businessInformation.country as ICompanyLocation)
    this.einStore.setEinItem({
      city: businessInformation.city,
      ein: businessInformation.registration_number,
      ein_formatted: businessInformation.registration_number,
      name: businessInformation.business_name,
      state: businessInformation.state_id,
      street1: businessInformation.street_address_1,
      street2: businessInformation.street_address_2 || '',
      zip: businessInformation.zip_code,
    })

    this.einStore.setEinSearch(businessInformation.registration_number)
    this.einStore.setIrsEinAttachment(businessInformation.irs_ein_document)
    businessInformation.irs_ein_document &&
      businessInformation.irs_ein_document.source &&
      this.einStore.setAttachmentData(
        new Attachment({ responseMedia: businessInformation.irs_ein_document })
      )

    this.businessInfoStore.setBusinessIndustry(businessInformation.industry_id)
    this.businessInfoStore.setBusinessType(businessInformation.business_type_id)
    this.businessInfoStore.setActiveRegionOfOperationIds(
      businessInformation.regions_of_operation.map(({ id }) => id)
    )
    this.businessInfoStore.setWebsiteUrl(businessInformation.website_url)
    this.businessInfoStore.setNoWebsiteUrl(businessInformation.without_website || false)
    this.businessInfoStore.setSocialMediaUrl(businessInformation.social_media_url || null)

    if (!pointsOfContact) return

    const firstContact = pointsOfContact[0]
    const secondContact = pointsOfContact[1]

    if (firstContact) {
      this.peopleToContactStore.setPrimaryFirstName(firstContact.first_name)
      this.peopleToContactStore.setPrimaryLastName(firstContact.last_name)
      this.peopleToContactStore.setPrimaryBusinessTitle(firstContact.business_title)
      this.peopleToContactStore.setPrimaryJobPosition(Number(firstContact.job_position_id))
      this.peopleToContactStore.setPrimaryEmail(firstContact.email)
      this.peopleToContactStore.phoneInputStore.setNumber(firstContact.phone_number)
    }

    if (secondContact) {
      this.peopleToContactStore.toggleSecondContact()
      this.peopleToContactStore.setSecondaryFirstName(secondContact.first_name)
      this.peopleToContactStore.setSecondaryLastName(secondContact.last_name)
      this.peopleToContactStore.setSecondaryBusinessTitle(secondContact.business_title)
      this.peopleToContactStore.setSecondaryJobPosition(Number(secondContact.job_position_id))
      this.peopleToContactStore.setSecondaryEmail(secondContact.email)
      this.peopleToContactStore.secondPhoneInputStore.setNumber(secondContact.phone_number)
    }
  }

  setErrors = (errors: ITwilioServiceError[] | undefined) => {
    if (errors) {
      this._errors = errors
    }
  }

  reactionChangeStep = () => {
    reaction(
      () => this._complianceSettingsStore.stepsStore.settingsStep,
      () => {
        this.setFieldsError(null)
      }
    )
  }
}
