import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { isEmail } from 'shared/lib'
import { ILocalNumbersRoutesEnum, IPlaneTypes } from '../types'
import { stockExchanges } from './consts'
import type { LocalNumbersStore } from './LocalNumbersStore'

const maxStockTickerLength = 10

export class PackageStore {
  constructor(private _localNumbersStore: LocalNumbersStore) {
    makeAutoObservable(this)

    this.init()
  }

  private _activePlaneType: IPlaneTypes | null = null
  private _businessType: number | null = null
  private _stockExchanges = stockExchanges.map((item) => ({ id: item.id, label: item.title }))
  private _stockTicker = ''
  private _activeStockExchange: number | null = null
  private _verificationEmail = ''
  private _emailLocalError = ''

  get activePlaneType() {
    return this._activePlaneType
  }

  get businessType() {
    return this._businessType
  }

  get stockExchanges() {
    return this._stockExchanges
  }

  get stockTicker() {
    return this._stockTicker
  }

  get activeStockExchange() {
    return this._activeStockExchange
  }

  get verificationEmail() {
    return this._verificationEmail
  }

  get emailLocalError() {
    return this._emailLocalError
  }

  get isNextDisabled() {
    if (!this.businessType) return true
    if (this.businessType === 1) return false
    if (this.businessType === 2) {
      if (
        !this.verificationEmail.length ||
        !this.activeStockExchange ||
        !this.stockTicker.length ||
        this.emailLocalError.length
      ) {
        return true
      }
    }

    return false
  }

  init = () => {
    const { pointsOfContact } = this._localNumbersStore.complianceSettingsStore || {}
    const initEmail = pointsOfContact ? pointsOfContact[0].email : ''

    this.setVerificationEmail(initEmail)
  }

  setActivePlaneType = (activePlaneType: IPlaneTypes) => {
    this._activePlaneType = activePlaneType
  }

  setBusinessType = (businessType: number) => {
    this._businessType = businessType
  }

  setStockTicker = (stockTicker: string) => {
    if (maxStockTickerLength < stockTicker.length) return

    this._stockTicker = stockTicker
  }

  setStockExchange = (value: number) => {
    this._activeStockExchange = value
  }

  setVerificationEmail = (value: string) => {
    this.setEmailLocalError('')

    this._verificationEmail = value
  }

  setEmailLocalError = (value: string) => {
    this._emailLocalError = value
  }

  checkIsEmail = (email: string) => {
    const isCorrect = isEmail(email)

    const errorText = isCorrect ? '' : 'Please enter a valid email address'

    this.setEmailLocalError(errorText)
  }

  increaseStep = () => {
    uiStore.changeRoute({
      path: `/settings/organization/compliance/local-numbers/${ILocalNumbersRoutesEnum.orderSummary}`,
    })
  }
}
