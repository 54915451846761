export enum SettingsRoutesEnum {
  root = 'settings',
  organization = 'organization',
  general = 'organization/settings/double-opt-in',
  members = 'organization/members/active',
  teams = 'organization/teams',
  inboxes = 'organization/inboxes/all-inboxes',
  numbers = 'organization/numbers',
  integrations = 'integrations',
  compliance = 'organization/compliance',
  planAndBilling = 'billing/overview',
  addSubscription = 'personal/settings/profile?add-subscription=true',

  tags = 'organization/tags',
  customFields = 'organization/custom-fields',
  urlShortener = 'organization/custom-url-shortener',
  websiteChatWidget = 'widget/chat',
  knowledgeBase = 'knowledge-base',

  account = 'personal/settings/profile',
  referrals = 'organization/referrals',

  oauthApplications = 'developer/applications',
  personalAccessTokens = 'developer/access-tokens',
  publicApiTokens = 'public_api/tokens',
  authorizedApps = 'personal/authorized-apps',
}
