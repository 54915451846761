import { CSSProperties, ReactNode, FC } from 'react'
import classnames from 'classnames'
import { IIconsVector, Icon, Typography, Tooltip, ITooltipProps } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type ILabel = {
  text?: string | ReactNode
  secondaryText?: string | ReactNode
  rightText?: string | ReactNode
  className?: string
  textClassName?: string
  width?: number
  leftIcon?: IIconsVector
  rightIcon?: IIconsVector
  horizontal?: boolean
  rightNode?: ReactNode
}

export type ILabelProps = {
  label?: ILabel
  tertiary?: boolean
  height?: CSSProperties['height']
  boxAlignItems?: CSSProperties['alignItems']
  questionTooltipProps?: ITooltipProps
  marginBottom?: CSSProperties['marginBottom']
  marginTop?: CSSProperties['marginTop']
}

export const Label: FC<ILabelProps> = ({
  label,
  questionTooltipProps,
  boxAlignItems,
  height,
  tertiary = true,
  marginBottom,
  marginTop,
}) => {
  if (!label) return null

  const {
    text,
    secondaryText,
    rightText,
    width,
    leftIcon,
    rightIcon,
    horizontal,
    textClassName,
    rightNode,
  } = label

  const rightContent = () => {
    if (rightNode) {
      return rightNode
    }

    return (
      <Typography variant={'text-input-field'} className={'tertiary'} ariaLabel={'rightText'}>
        {rightText}
      </Typography>
    )
  }

  return (
    <div
      className={classnames(styles.wrapLabel, label.className)}
      style={{ minWidth: width, height, marginBottom, marginTop }}
      aria-label={getAriaLabel('Label')}
    >
      <div
        className={styles.boxLabel}
        style={{ alignItems: boxAlignItems }}
        aria-label={getAriaLabel('Label', 'boxLabel')}
      >
        {leftIcon && <Icon icon={leftIcon} fontSize={16} tertiary={tertiary} />}
        <Typography
          variant={horizontal ? 'text-input-field' : 'text-input-label-small'}
          className={classnames(horizontal ? '' : tertiary && 'tertiary', textClassName)}
          ariaLabel={'text'}
        >
          {text}
        </Typography>
        {secondaryText && (
          <Typography
            variant={'text-input-label-small'}
            color={'var(--content-neutral-primary)'}
            ariaLabel={'secondaryText'}
          >
            {secondaryText}
          </Typography>
        )}
        {rightIcon && <Icon icon={rightIcon} fontSize={16} tertiary={tertiary} />}
        {questionTooltipProps && (
          <Tooltip placement={'top'} {...questionTooltipProps}>
            <Icon icon={'question'} color={'var(--content-neutral-primary)'} fontSize={14} />
          </Tooltip>
        )}
      </div>
      <div className={styles.boxLabel} aria-label={getAriaLabel('Label', 'boxLabel')}>
        {rightText && rightContent()}
      </div>
    </div>
  )
}
