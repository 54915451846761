import { makeAutoObservable } from 'mobx'
import type { IActionsProps } from 'shared/ui'
import type { IResponseMediaShort } from 'entities/Attachment'
import type { IResponseNumber } from 'entities/Phone/api/types'
import type { IKeywordStatus, IResponseKeyword } from 'entities/Keywords/api/type'
import { KeywordStatistics } from './KeywordStatistics'

export type IKeyword = Keyword

export class Keyword {
  createdAt: string
  deletedAt: string | null = null
  disabledAt: string | null = null
  id: number
  status: IKeywordStatus
  media_url: IResponseMediaShort[] = []
  message = ''
  name: string
  numbers: IResponseNumber[] | null
  organizationId: number | null
  ownerId: number | null
  statistics: KeywordStatistics
  title = ''
  updatedAt: string
  actionsProps: IActionsProps | null = null

  constructor(item: IResponseKeyword) {
    this.id = item.id
    this.name = item.name
    this.ownerId = item.owner_id
    this.status = item.is_active ? 'active' : 'disabled'
    this.statistics = new KeywordStatistics(item.statistics)
    this.createdAt = item.created_at
    this.deletedAt = item.deleted_at
    this.disabledAt = item.disabled_at
    this.updatedAt = item.updated_at
    this.message = item.message || ''
    this.title = item.title || ''
    this.media_url = item.media_url || []
    this.organizationId = item.organization_id
    this.numbers = item.numbers

    makeAutoObservable(this)
  }

  setActionsProps = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }
}
