import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {
  Button,
  FullPageWrapper,
  Icon,
  Tabs,
  type ITabItem,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { uiStore } from 'shared/store/uiStore'
import { integrationsTextDataMap } from 'entities/Integrations/lib/integrationsData'
import { IntegrationStatus } from 'widgets/Integrations'
import {
  IntegrationDetailsProvider,
  useIntegrationDetailsContext,
} from 'pages/settings/pages/integrations/context/integrationDetailsContext'
import { SettingsRoutesEnum } from 'pages/settings/route/type'
import styles from './styles.module.scss'

const tabs: ITabItem[] = [
  {
    name: 'Settings',
    key: 'settings',
  },
  {
    name: 'Description',
    key: 'description',
  },
]

const IntegrationDetails = observer(() => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { integration, isLoading, isNotFound } = useIntegrationDetailsContext()

  useEffect(() => {
    uiStore.setCustomPathLabel(
      `/${SettingsRoutesEnum.root}/${SettingsRoutesEnum.integrations}/${integration?.key}`,
      'Integrations'
    )
  }, [integration])

  if (isLoading) return <SpinnerLoader />
  if (isNotFound) {
    return (
      <Navigate to={`/${SettingsRoutesEnum.root}/${SettingsRoutesEnum.integrations}`} replace />
    )
  }
  if (!integration) return null

  const segments = pathname.split('/').filter(Boolean)
  const activeTab = segments[segments.length - 1]
  const handleSelectTab = (tab: ITabItem) => {
    navigate(tab.key)
  }
  const integrationData = integrationsTextDataMap[integration.key]

  return (
    <FullPageWrapper className={styles.container}>
      <div className={styles.content}>
        <Breadcrumbs
          customPath={`/${SettingsRoutesEnum.root}/${SettingsRoutesEnum.integrations}`}
        />
        <header>
          <Icon fontSize={48} icon={integration.icon} />
          <div className={styles.integrationDetailsHeader}>
            <div className={styles.title}>
              <Typography
                variant='heading-lg'
                ariaLabel='IntegrationDetails_title'
                marginBottom={4}
              >
                {integration.name}
              </Typography>
              <IntegrationStatus integration={integration} />
            </div>
            {integrationData?.subheadline && (
              <Typography
                variant='body-md-regular'
                color='var(--content-primary-tertiary)'
                ariaLabel='IntegrationDetails_desc'
              >
                {integrationData?.subheadline}
              </Typography>
            )}
          </div>
          <div className={styles.actions}>
            {integrationData?.docs && (
              <Button
                tag='a'
                text='Docs'
                size='medium'
                contained='tertiary'
                iconProps={{ icon: 'link' }}
                href={integrationData?.docs}
                target='_blank'
                rel='noreferrer'
                className={styles.docsButton}
              />
            )}
            {integration.isConnected ? (
              <Button
                text='Disconnect'
                typeBtn='outlined'
                contained='secondary'
                iconProps={{ icon: 'unlink' }}
                size='medium'
              />
            ) : (
              <Button text='Connect' iconProps={{ icon: 'plug' }} size='medium' />
            )}
          </div>
        </header>
        {integration.isConnected && (
          <div className={styles.navBar}>
            <Tabs
              variant='default'
              tabs={tabs}
              activeTabKey={activeTab}
              handleSelectTab={handleSelectTab}
            ></Tabs>
          </div>
        )}
        <Outlet />
      </div>
    </FullPageWrapper>
  )
})

export const IntegrationDetailsLayout = () => (
  <IntegrationDetailsProvider>
    <IntegrationDetails />
  </IntegrationDetailsProvider>
)
