export const isUrlDomainsSimilar = (url1: string, url2: string): boolean => {
  try {
    const domain1 = new URL(url1).hostname.split('.').slice(-2).join('.')
    const domain2 = new URL(url2).hostname.split('.').slice(-2).join('.')

    return domain1 === domain2
  } catch (error) {
    console.error('Invalid URL provided:', error)
    return false
  }
}
