import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import type { LocalNumbersStore } from './LocalNumbersStore'
import { ExampleModalContent } from '../ui/ExampleModalContent'
import { ILocalNumbersRoutesEnum } from '../types'

export const sampleMessageMinTextLength = 20
export const sampleMessageMaxTextLength = 1024

export class MessagesStore {
  constructor(private _localNumbersStore: LocalNumbersStore) {
    makeAutoObservable(this)
  }

  private _includeEmbeddedLinks = false
  private _includePhoneNumbers = false
  private _includeAgeGatedContent = false
  private _sampleMessage1 = ''
  private _sampleMessage2 = ''

  get includeEmbeddedLinks() {
    return this._includeEmbeddedLinks
  }

  get includePhoneNumbers() {
    return this._includePhoneNumbers
  }

  get includeAgeGatedContent() {
    return this._includeAgeGatedContent
  }

  get sampleMessage1() {
    return this._sampleMessage1
  }

  get sampleMessage2() {
    return this._sampleMessage2
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  get isStep2Disabled() {
    const isWithinLimits = (message: string) =>
      message.length >= sampleMessageMinTextLength && message.length <= sampleMessageMaxTextLength

    return !isWithinLimits(this._sampleMessage1) || !isWithinLimits(this._sampleMessage2)
  }

  campaignMessagesNextClick = () => {
    uiStore.changeRoute({
      path: `/settings/organization/compliance/local-numbers/${ILocalNumbersRoutesEnum.optInMethod}`,
    })
  }

  setIncludeEmbeddedLinks = () => {
    this._includeEmbeddedLinks = !this.includeEmbeddedLinks
  }

  setIncludePhoneNumbers = () => {
    this._includePhoneNumbers = !this.includePhoneNumbers
  }

  setIncludeAgeGatedContent = () => {
    this._includeAgeGatedContent = !this.includeAgeGatedContent
  }

  setSampleMessage1 = (sampleMessage1: string) => {
    this._sampleMessage1 = sampleMessage1
  }

  setSampleMessage2 = (sampleMessage2: string) => {
    this._sampleMessage2 = sampleMessage2
  }

  openSampleMessageModal = () => {
    const id = 'sampleMessageModal'
    const { activeUseCase, useCaseDescExample } = this.localNumbersStore.useCaseStore

    modalStore.addModal({
      id,
      width: 540,
      title: 'Sample message example',
      hiddenContent: true,
      ModalContent: () => (
        <ExampleModalContent
          activeUseCase={activeUseCase || ''}
          example={useCaseDescExample}
          withMustInclude
          includesLinks={this.includeEmbeddedLinks}
          includesPhoneNumbers={this.includePhoneNumbers}
        />
      ),
      primaryAction: {
        text: 'Done',
        onAction: () => modalStore.closeModal(id),
      },
      showCloseButton: false,
    })
  }
}
