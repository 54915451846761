import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import styles from 'widgets/Sidebar/ui/MainDropdown/styles.module.scss'
import RouterLink from 'widgets/Sidebar/ui/MainDropdown/RouterLink'

export const UpgradeSubscriptionBtn = observer(() => {
  const isAdminOrOwnerRole = usersStore.user?.isAdminOrOwnerRole
  if (!isAdminOrOwnerRole) {
    return null
  }
  return (
    <div className={styles.blockWrapper} aria-label={getAriaLabel('UpgradeSubscriptionBtn')}>
      <RouterLink route='/settings/billing/overview/upgrade'>
        <Button text='Upgrade' fullWidth />
      </RouterLink>
    </div>
  )
})
