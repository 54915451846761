export enum ILocalNumbersRoutesEnum {
  campaignUseCases = 'campaign-use-cases',
  messages = 'messages',
  optInMethod = 'opt-in-method',
  optInOnlineFormContent = 'opt-in-online-form-consent',
  optInPaperFormContent = 'opt-in-paper-form-consent',
  optInViaTextContent = 'opt-in-via-text-consent',
  optInQrCodeContent = 'opt-in-qr-code-consent',
  forbiddenMessageTopics = 'forbidden-message-topics',
  package = 'package',
  orderSummary = 'order-summary',
}

export enum IPlaneTypes {
  STANDARD = 'STANDARD',
  LOW_VOLUME_STANDARD = 'LOW_VOLUME_STANDARD',
}
