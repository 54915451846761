// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CT6bAm0yWH9E2OXBaB9c{display:flex;align-items:center;gap:12px;padding:12px 0}.frupGF_Kq9x0rppeDkfU{display:flex;align-items:center;justify-content:center;width:32px;height:32px;min-width:32px;border-radius:6px;background-color:var(--background-neutral-subtle)}.cEVSMjmWxdMBLE9_qE2n{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBaseView/ui/DocumentsList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,iDAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".nameContainer {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 12px 0;\n}\n\n.iconContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  min-width: 32px;\n  border-radius: 6px;\n  background-color: var(--background-neutral-subtle);\n}\n\n.name {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameContainer": "CT6bAm0yWH9E2OXBaB9c",
	"iconContainer": "frupGF_Kq9x0rppeDkfU",
	"name": "cEVSMjmWxdMBLE9_qE2n"
};
export default ___CSS_LOADER_EXPORT___;
