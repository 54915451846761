import { ActionItem } from 'shared/ui'
import {
  BroadcastOneTime,
  BroadcastRecurring,
  BroadcastRecurringChild,
  BroadcastRoutesEnum,
  IBroadcast,
} from 'entities/Broadcast'
import { IContactsStatisticFilter } from 'entities/ContactStatistic'
import { broadcastActionsStore } from 'widgets/BroadcastActions/store/BroadcastActionsStore'

const broadcastActions = {
  detail: (broadcast: BroadcastOneTime): ActionItem => ({
    icon: 'eye',
    onAction: () => broadcastActionsStore.onDetails(broadcast),
    text: 'Details',
  }),
  edit: (broadcast: IBroadcast): ActionItem => ({
    icon: 'edit',
    navLink: `/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.edit}/${broadcast.id}`,
    text: 'Edit',
  }),
  editRecurringChild: (broadcast: BroadcastRecurringChild): ActionItem => ({
    icon: 'edit',
    onAction: () => broadcastActionsStore.onEditRecurringChild(broadcast),
    text: 'Edit',
  }),
  pause: (broadcast: IBroadcast): ActionItem => ({
    icon: 'pause',
    onAction: () => broadcastActionsStore.onPause(broadcast),
    text: 'Pause',
  }),
  resume: (broadcast: IBroadcast): ActionItem => ({
    icon: 'play',
    onAction: () => broadcastActionsStore.onResume(broadcast),
    text: 'Resume',
  }),
  stop: (broadcast: IBroadcast): ActionItem => ({
    icon: 'stop',
    onAction: () => broadcastActionsStore.onStop(broadcast),
    text: 'Stop',
  }),
  stopRecurringChild: (broadcast: BroadcastRecurringChild): ActionItem => ({
    icon: 'stop',
    onAction: () => broadcastActionsStore.onStopRecurringChild(broadcast),
    text: 'Stop',
  }),
  export: (broadcast: IBroadcast, filter?: IContactsStatisticFilter): ActionItem => ({
    icon: 'download',
    onAction: () => broadcastActionsStore.onExport(broadcast, filter),
    text: 'Export report',
  }),
  duplicate: (broadcast: IBroadcast): ActionItem => ({
    icon: 'copy',
    text: 'Duplicate',
    navLink: `/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.clone}/${broadcast.id}`,
  }),
  delete: (broadcast: IBroadcast, onSuccess?: () => void): ActionItem => ({
    icon: 'delete',
    onAction: () => broadcastActionsStore.onDelete(broadcast, onSuccess),
    text: 'Delete',
  }),
  rename: (broadcast: BroadcastRecurring): ActionItem => ({
    icon: 'edit',
    onAction: () => broadcastActionsStore.onRename(broadcast),
    text: 'Rename',
  }),
}

export const getBroadcastActions = ({
  broadcast,
  onSuccessDelete,
  filter,
}: {
  broadcast: IBroadcast
  onSuccessDelete: () => void
  filter?: IContactsStatisticFilter
}): ActionItem[] => {
  if (broadcast instanceof BroadcastRecurringChild) {
    const statusActions: {
      [key in BroadcastOneTime['status']]: ActionItem[]
    } = {
      scheduled: [
        broadcastActions.editRecurringChild(broadcast),
        broadcastActions.detail(broadcast),
        broadcastActions.stopRecurringChild(broadcast),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
      in_progress: [
        broadcastActions.detail(broadcast),
        broadcastActions.pause(broadcast),
        broadcastActions.stopRecurringChild(broadcast),
      ],
      pause: [
        broadcastActions.detail(broadcast),
        broadcastActions.resume(broadcast),
        broadcastActions.stopRecurringChild(broadcast),
      ],
      sent: [
        broadcastActions.detail(broadcast),
        broadcastActions.export(broadcast, filter),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
      stopped: [
        broadcastActions.detail(broadcast),
        broadcastActions.export(broadcast, filter),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
      failed: [
        broadcastActions.detail(broadcast),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
      skipped: [
        broadcastActions.detail(broadcast),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
      draft: [
        broadcastActions.editRecurringChild(broadcast),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
    }

    return [...statusActions[broadcast.status]]
  }
  if (broadcast instanceof BroadcastOneTime) {
    const statusActions: {
      [key in BroadcastOneTime['status']]: ActionItem[]
    } = {
      scheduled: [
        broadcastActions.edit(broadcast),
        broadcastActions.detail(broadcast),
        broadcastActions.stop(broadcast),
      ],
      in_progress: [
        broadcastActions.detail(broadcast),
        broadcastActions.pause(broadcast),
        broadcastActions.stop(broadcast),
      ],
      pause: [
        broadcastActions.detail(broadcast),
        broadcastActions.resume(broadcast),
        broadcastActions.stop(broadcast),
      ],
      sent: [broadcastActions.detail(broadcast), broadcastActions.export(broadcast, filter)],
      stopped: [broadcastActions.detail(broadcast), broadcastActions.export(broadcast, filter)],
      failed: [broadcastActions.detail(broadcast)],
      skipped: [broadcastActions.detail(broadcast)],
      draft: [broadcastActions.edit(broadcast)],
    }

    return [
      ...statusActions[broadcast.status],
      broadcastActions.duplicate(broadcast),
      broadcastActions.delete(broadcast, onSuccessDelete),
    ]
  }
  if (broadcast instanceof BroadcastRecurring) {
    const statusActions: {
      [key in BroadcastRecurring['status']]: ActionItem[]
    } = {
      active: [
        broadcastActions.rename(broadcast),
        broadcastActions.pause(broadcast),
        broadcastActions.stop(broadcast),
        broadcastActions.duplicate(broadcast),
      ],
      inactive: [
        broadcastActions.rename(broadcast),
        broadcastActions.resume(broadcast),
        broadcastActions.stop(broadcast),
        broadcastActions.duplicate(broadcast),
      ],
      finished: [
        broadcastActions.duplicate(broadcast),
        broadcastActions.delete(broadcast, onSuccessDelete),
      ],
      draft: [broadcastActions.edit(broadcast)],
    }
    return [...statusActions[broadcast.status]]
  }
  return []
}
