import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'

type ITrialAlertWatermarkProps = {
  isHideTrialAlert: boolean
  onCloseTrialAlert: () => void
}

export const TrialAlertWatermark: FC<ITrialAlertWatermarkProps> = observer(
  ({ isHideTrialAlert, onCloseTrialAlert }) => {
    if (!subscriptionStore.isTrial) {
      return null
    }
    if (usersStore.user?.isAdminOrOwnerRole) {
      return (
        <Alert
          item={{
            type: 'infoLight',
            desc: 'Upgrade to remove Salesmsg Trial watermark',
          }}
          rightAction={
            <Button
              text={'Upgrade'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={() =>
                uiStore.changeRoute({
                  path: '/settings/billing/overview/upgrade',
                  type: 'vue',
                })
              }
              data-type={'upgrade-button'}
            />
          }
        />
      )
    }

    if (!isHideTrialAlert) {
      return (
        <Alert
          item={{
            type: 'infoLight',
            desc: 'During trial, your messages have Salesmsg Trial watermark',
          }}
          onClose={onCloseTrialAlert}
        />
      )
    }

    return null
  }
)
