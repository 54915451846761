import { ChangeEvent, useRef } from 'react'

import classNames from 'classnames'
import { Icon } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'
import DragAndDropFile from 'shared/ui/DragAndDropFile/DragAndDropFile'

import styles from './styles.module.scss'

interface IUploadDocProps {
  uploadTitle?: string
  uploadTypeText: string
  className?: string
  onChange: (files: File[] | FileList) => void
}

export const UploadDoc = ({
  uploadTitle,
  uploadTypeText,
  className,
  onChange,
}: IUploadDocProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      onChange(files)
    }
  }
  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  const dropZone = (isActive?: boolean) => (
    <div className={styles.dropZone}>
      <Icon
        fontSize={20}
        icon='upload'
        color={isActive ? 'var(--action-contained-primary-normal)' : 'var(--gray-60)'}
        className={styles.uploadIcon}
      />
      <Typography
        ariaLabel='DragAndDrop_line'
        variant='body-sm-medium'
        color='var(--content-primary-primary)'
      >
        {uploadTitle || 'Upload or drag & drop the file here'}
      </Typography>
      <Typography
        ariaLabel='DragAndDrop_line'
        variant='body-sm-medium'
        color='var(--content-primary-tertiary)'
      >
        {uploadTypeText}
      </Typography>
    </div>
  )
  return (
    <div className={classNames(styles.dragAndDropContainer, className)} onClick={handleUploadClick}>
      {dropZone()}
      <input type={'file'} className={styles.fileInput} onChange={onUploadChange} ref={inputRef} />
      <DragAndDropFile dropZoneOverlayInfo={dropZone(true)} onDrop={(files) => onChange(files)} />
    </div>
  )
}
