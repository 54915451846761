import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import type { IComplianceSettingsStore } from 'entities/Compliance'
import { LocalNumbersStore } from '../store/LocalNumbersStore'

const LocalNumbersContext = createContext<LocalNumbersStore | null>(null)

export const useLocalNumbersContext = () => {
  const context = useContext(LocalNumbersContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with LocalNumbersProvider')

  return context
}

export const LocalNumbersProvider = ({
  complianceSettingsStore,
  children,
}: {
  complianceSettingsStore: IComplianceSettingsStore
  children: ReactNode
}) => {
  const store = useMemo(() => new LocalNumbersStore(complianceSettingsStore), [])

  useEffect(() => store.dispose, [])

  return <LocalNumbersContext.Provider value={store}>{children}</LocalNumbersContext.Provider>
}
