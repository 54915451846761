import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, type CancelTokenSource } from 'axios'
import { nanoid } from 'nanoid'
import { TableStore } from 'shared/ui/Table'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { DayjsFormats, getBlob } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import {
  KnowledgeBaseApi,
  KnowledgeBase,
  KnowledgeBaseDocument,
  type IParamsKnowledgeBaseDocuments,
  type IResponseKnowledgeBaseDocuments,
} from 'entities/KnowledgeBase'
import { downloadWithToast } from 'features/FileDownload'

export class KnowledgeBaseViewStore {
  private _page = 1
  private _total = 0
  private _limit = 10
  private _search = ''
  private _isKnowledgeBaseDocumentsLoading = true
  private _isKnowledgeBaseLoading = true
  private _isInitialLoading = true

  private _knowledgeBase: KnowledgeBase | null = null
  private _disposeLoadKnowledgeBaseDocuments: IReactionDisposer | null = null
  private _cancelTokenSource: CancelTokenSource | null = null
  private _documentsMap = new Map<number, KnowledgeBaseDocument>()

  tableStore = new TableStore<KnowledgeBaseDocument>({
    element: 'document',
    withoutDefaultManageColumns: true,
  })

  constructor() {
    makeAutoObservable(this)

    this._reactionLoadKnowledgeBaseDocuments()
  }

  private _reactionLoadKnowledgeBaseDocuments = () => {
    this._disposeLoadKnowledgeBaseDocuments?.()
    this._disposeLoadKnowledgeBaseDocuments = reaction(
      () => this._knowledgeBaseDocumentsRequestParams,
      () => this._loadKnowledgeBaseDocuments(),
      { delay: 500 }
    )
  }

  loadKnowledgeBaseInitialData = async () => {
    runInAction(() => {
      this._isInitialLoading = true
    })
    try {
      await Promise.all([this._loadKnowledgeBase(), this._loadKnowledgeBaseDocuments()])
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this._isInitialLoading = false
      })
    }
  }

  private _loadKnowledgeBase = async () => {
    runInAction(() => {
      this._isKnowledgeBaseLoading = true
    })
    try {
      const { data } = await KnowledgeBaseApi.getKnowledgeBase()
      runInAction(() => {
        this._knowledgeBase = new KnowledgeBase(data)
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this._isKnowledgeBaseLoading = false
      })
    }
  }

  private _initCancelPageSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _setDocuments = ({ data, meta }: IResponseKnowledgeBaseDocuments) => {
    this._documentsMap.clear()

    data.forEach((documentResponse) =>
      this._documentsMap.set(documentResponse.id, new KnowledgeBaseDocument(documentResponse))
    )

    this._page = meta.current_page
    this._total = meta.total
  }

  private _loadKnowledgeBaseDocuments = async () => {
    runInAction(() => {
      this._isKnowledgeBaseDocumentsLoading = true
    })
    this._initCancelPageSource()

    try {
      const { data } = await KnowledgeBaseApi.getKnowledgeBaseDocuments(
        this._knowledgeBaseDocumentsRequestParams,
        {
          ...(this._cancelTokenSource ? { cancelToken: this._cancelTokenSource.token } : null),
        }
      )

      this._setDocuments(data)
    } catch (error) {
      runInAction(() => {
        this._isKnowledgeBaseDocumentsLoading = error instanceof CanceledError
      })
      console.error(error)
    } finally {
      runInAction(() => {
        this._isInitialLoading = false
        this._isKnowledgeBaseDocumentsLoading = false
      })
    }
  }

  onDeleteDocuments = async (ids: Array<number>) => {
    const deleteModalId = nanoid()

    const handleCloseDeleteModal = () => {
      modalStore.removeModal(deleteModalId)
    }

    const onDeleteDocuments = async () => {
      try {
        await KnowledgeBaseApi.delete(ids)
        toastStore.add({
          type: 'success',
          title: ids.length > 1 ? ids.length + ' files deleted' : 'File deleted',
        })
        ids.forEach((id) => {
          const document = this._documentsMap.get(id)
          document && this.tableStore.toggleSelected(document, false)
          this._documentsMap.delete(id)
        })
        void this._loadKnowledgeBaseDocuments()
      } catch (e) {
        console.error(e)
      } finally {
        handleCloseDeleteModal()
      }
    }

    modalStore.addModal({
      id: deleteModalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      width: 280,
      type: ModalTypeList.ALERT,
      title: ids.length > 1 ? `Delete ${ids.length} files?` : 'Delete file?',
      desc: this._getDescriptionForDeleteModal(ids.length > 1),
      primaryAction: {
        text: 'Delete',
        onAction: onDeleteDocuments,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: handleCloseDeleteModal,
      },
      onClose: handleCloseDeleteModal,
    })
  }

  private _getDescriptionForDeleteModal = (isMultipleFiles: boolean) => {
    if (this._chatbotsCount === 0) {
      return 'This action cannot be undone.'
    }

    if (isMultipleFiles) {
      if (this._chatbotsCount > 1) {
        return 'These files are used by multiple AI textbots in the knowledge base. Deleting them may affect their responses. This action cannot be undone.'
      } else {
        return 'These files are used by AI textbot. Deleting them may affect the responses provided. This action cannot be undone.'
      }
    } else {
      if (this._chatbotsCount > 1) {
        return 'This file is used by multiple AI textbots in the knowledge base. Deleting it may affect their responses. This action cannot be undone.'
      } else {
        return 'This file is used by AI textbot. Deleting it may affect the responses provided. This action cannot be undone.'
      }
    }
  }

  onDownloadDocument = async (id: number) => {
    await downloadWithToast(
      async () => {
        const { data } = await KnowledgeBaseApi.getDownloadLink(id)

        const document = this._documentsMap.get(id)
        const name = document?.name || id.toString()
        const blob = await getBlob(data.link)

        if (!blob) {
          throw new Error('Failed to download Blob from ' + data.link)
        }

        return { blob, name }
      },
      () => this.onDownloadDocument(id)
    )
  }

  onBulkDownloadDocuments = async (ids: Array<number>) => {
    await downloadWithToast(
      async () => {
        const { data } = await KnowledgeBaseApi.getBulkDownloadLink(ids)

        const name =
          ids.length === 1
            ? this._documentsMap.get(ids[0])?.name || 'file'
            : 'Salesmsg_' + uiStore.dayjs(new Date()).format(DayjsFormats.downloadFile)
        const blob = await getBlob(data.link)

        if (!blob) {
          throw new Error('Failed to download Blob from ' + data.link)
        }

        return { blob, name }
      },
      () => this.onBulkDownloadDocuments(ids)
    )
  }

  onSearchChange = (value: string) => {
    this._page = 1
    this._search = value
  }

  resetSearch = () => {
    this._page = 1
    this._search = ''
  }

  onPaginationChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  private _clearReactions = () => {
    this._disposeLoadKnowledgeBaseDocuments?.()
  }

  dispose = () => {
    this._clearReactions()
  }

  private get _knowledgeBaseDocumentsRequestParams(): IParamsKnowledgeBaseDocuments {
    return {
      page: this._page,
      limit: this._limit,
      'filters[name]': this._search,
    }
  }

  private get _chatbotsCount() {
    return this._knowledgeBase?.chatbotsCount ?? 0
  }

  get hasSearchParams() {
    return !!this._search
  }

  get isEmpty() {
    if (this._isInitialLoading || this._isKnowledgeBaseDocumentsLoading || !!this._search.length)
      return false

    return !this._documentsMap.size
  }

  get documents() {
    return Array.from(this._documentsMap.values())
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  get total() {
    return this._total
  }

  get isKnowledgeBaseDocumentsLoading() {
    return this._isKnowledgeBaseDocumentsLoading
  }

  get search() {
    return this._search
  }

  get isInitialLoading() {
    return this._isInitialLoading
  }

  get isNoSearchResults() {
    return !this._isKnowledgeBaseDocumentsLoading && !this._documentsMap.size && this._search
  }
}
