import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosResponse } from 'axios'
import {
  IntegrationKey,
  IntegrationsApi,
  integrationsStore,
  IResponseMergeField,
} from 'entities/Integrations'
import { MergeField } from 'widgets/MergeField/types/MergeField'

class AllMergeFieldsStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  getAllFields: Promise<AxiosResponse<{ [key in IntegrationKey]: IResponseMergeField[] }>> | null =
    null

  mergeFieldsIntegrationsMap = new Map<IntegrationKey | string, Map<string, MergeField>>()

  get mergeFieldsIntegrationsList() {
    return Array.from(this.mergeFieldsIntegrationsMap.values()).flatMap((integration) => {
      return Array.from(integration.values())
    })
  }

  get allFields(): MergeField[] {
    let fields: MergeField[] = []
    this.mergeFieldsIntegrationsMap.forEach((map) => {
      if (map) {
        fields = [...fields, ...Array.from(map.values())]
      }
    })
    return fields.filter((field) =>
      field.integrationKey === IntegrationKey.salesmessage
        ? true
        : !!integrationsStore.getIntegration(field.integrationKey)?.isConnected
    )
  }

  isMergeField = (match: string) => {
    const integrationKeyList = Object.values(IntegrationKey)

    integrationKeyList.forEach((key) => {
      if (match.includes(key)) return true
    })

    return this.mergeFieldsIntegrationsList.find((mergeField) => {
      const matchKey = match.split(' ')?.[1]
      if (!matchKey) return false
      return mergeField.template === `{ ${matchKey} }`
    })
  }

  loadAllFields = async () => {
    try {
      if (this.getAllFields) return this.getAllFields.then()
      this.loading = true
      this.getAllFields = IntegrationsApi.getAllFields()
      const { data } = await this.getAllFields
      runInAction(() => {
        this.mergeFieldsIntegrationsMap = new Map()
        Object.entries(data).forEach(([key, fields]) => {
          const map = new Map(
            fields.map((field) => {
              const newField = new MergeField(field)
              return [newField.name, newField]
            })
          )
          this.mergeFieldsIntegrationsMap.set(key as IntegrationKey, map)
        })
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getMergeFieldInfoByTemplate = (
    template: string
  ): {
    mergeField: MergeField | null
    fallback: string
    mergeFieldKey: string
    integrationKey: string
    fullMergeField: string
  } => {
    const newText = template.replace(/{|}/g, '').trim()
    const split = newText.split('|')

    const fullMergeField = split[0].trim()

    const fallback = (split[1] || '').replace(/&#39;/g, '').trim()
    const splitKey = fullMergeField.split('.')
    const integrationKey = Object.values(IntegrationKey).includes(splitKey[0] as IntegrationKey)
      ? splitKey[0]
      : IntegrationKey.salesmessage

    const mergeFieldKey = (splitKey[1] || splitKey[0] || '').trim()
    const integrationMergeFieldMap =
      integrationKey && this.mergeFieldsIntegrationsMap.get(integrationKey as IntegrationKey)

    const mergeField =
      integrationMergeFieldMap &&
      (integrationMergeFieldMap.get(mergeFieldKey) || integrationMergeFieldMap.get(fullMergeField))

    return {
      mergeField: mergeField || null,
      fallback,
      mergeFieldKey,
      integrationKey,
      fullMergeField,
    }
  }
}

export default new AllMergeFieldsStore()
