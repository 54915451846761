// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JQLiE7S19dawvj8FJm71{padding:0 64px;overflow-y:auto;overflow-x:hidden}.HE0rWrDywIEREEzgmlj5{display:flex;flex-direction:column;flex:1;padding:48px 20px 0}.dk4HeX3xutMvGfQAFD0I{display:flex;flex-direction:column}.zqEG9NK9Mrg5mLMNb17j{display:flex;align-items:flex-end;margin-bottom:32px;border-radius:16px;overflow:hidden;min-height:276px}.zqEG9NK9Mrg5mLMNb17j img{display:block;max-width:492px;width:100%;height:auto}.U65sDuBZxTQuVTUagADO{display:none}.xcq4m4T0V48EmIFosnQq{text-decoration:none;line-height:normal}.I9RQ4ln8rfPYq0XVoZZm{display:flex;align-items:center;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBaseView/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,0BACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CAIJ,sBACE,YAAA,CAGF,sBACE,oBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".container {\n  padding: 0 64px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 48px 20px 0;\n}\n\n.title {\n  display: flex;\n  flex-direction: column;\n}\n\n.previewImageContainer {\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 32px;\n  border-radius: 16px;\n  overflow: hidden;\n  min-height: 276px;\n\n  img {\n    display: block;\n    max-width: 492px;\n    width: 100%;\n    height: auto;\n  }\n}\n\n.fileInput {\n  display: none;\n}\n\n.helpButton {\n  text-decoration: none;\n  line-height: normal;\n}\n\n.actionsContainer {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JQLiE7S19dawvj8FJm71",
	"content": "HE0rWrDywIEREEzgmlj5",
	"title": "dk4HeX3xutMvGfQAFD0I",
	"previewImageContainer": "zqEG9NK9Mrg5mLMNb17j",
	"fileInput": "U65sDuBZxTQuVTUagADO",
	"helpButton": "xcq4m4T0V48EmIFosnQq",
	"actionsContainer": "I9RQ4ln8rfPYq0XVoZZm"
};
export default ___CSS_LOADER_EXPORT___;
