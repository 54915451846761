import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { Select } from 'shared/ui/Select/Select'
import { Error, IButtonProps, IDropdownItem, IDropdownProps, IDropdownListProps } from 'shared/ui'
import { ILabelProps, Label } from 'shared/ui/Label'
import { ISelectDefaultProps } from 'shared/ui/Select/SelectDefault/SelectDefault'
import { getAriaLabel } from 'shared/lib'

type SelectFieldProps<T = string> = {
  labelText?: string
  labelProps?: ILabelProps
  boxWidth?: string
  items: IDropdownItem[]
  value?: T
  item?: IDropdownItem | null
  placeholder?: string
  setValue: (value: T, item: IDropdownItem) => void
  selectProps?: Partial<ISelectDefaultProps>
  btnProps?: IButtonProps
  labelGap?: string
  dropdownProps?: IDropdownProps
  onSearch?: IDropdownListProps['onSearch']
  search?: IDropdownListProps['search']
  onLoadMore?: IDropdownListProps['onLoadMore']
  loading?: IDropdownListProps['loading']
  error?: ReactNode
  withSearch?: boolean
}

export const SelectField = ({
  labelText = '',
  labelProps = {},
  boxWidth,
  items,
  placeholder,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  value,
  item,
  setValue,
  selectProps,
  btnProps,
  labelGap = '4px',
  dropdownProps,
  onSearch,
  search,
  withSearch = true,
  onLoadMore,
  loading,
  error,
}: SelectFieldProps<typeof value>) => (
  <Box
    display={'grid'}
    gap={labelGap}
    aria-label={getAriaLabel(`SelectField${labelText}`)}
    {...(boxWidth && { width: boxWidth })}
  >
    {(labelText || labelProps) && (
      <Label
        label={{
          text: labelText,
        }}
        {...labelProps}
      />
    )}
    <Select
      type={'default'}
      text={placeholder}
      value={value}
      item={item}
      items={items}
      onSelectValue={setValue}
      error={error}
      fullWidth
      btnProps={{
        fullWidth: true,
        typeBtn: 'stroke',
        justifyContent: 'space-between',
        contained: undefined,
        fontWeight: 400,
        style: {
          color: !value && !item ? 'var(--content-neutral-primary)' : undefined,
          background: 'var(--background-primary-inverted-primary)',
        },
        ...btnProps,
      }}
      dropdownProps={{
        margin: 0,
        ariaLabel: 'SelectField',
        PopperProps: {
          modifiers: [
            {
              name: 'offset',
              options: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                offset: ({ reference }) => {
                  return [-4, -reference.height]
                },
              },
            },
          ],
        },
        ...dropdownProps,
        propsDropdownList: {
          ...dropdownProps?.propsDropdownList,
          onSearch,
          search,
          onLoadMore,
          loading,
          withSearch,
        },
      }}
      {...selectProps}
    />
    {error && <Error type={'text'} error={error} />}
  </Box>
)
