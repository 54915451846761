import classNames from 'classnames'
import { Icon, type IIconProps } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'

import styles from './styles.module.scss'

interface IChoiceTileProps {
  iconProps: IIconProps
  title: string
  value: string
  checked: boolean
  setChoiceTile: (value: string) => void
}

export const ChoiceTile = ({
  iconProps,
  title,
  value,
  checked,
  setChoiceTile,
}: IChoiceTileProps) => {
  return (
    <button
      className={classNames(styles.wrap, checked && styles.checked)}
      onClick={() => setChoiceTile(value)}
    >
      <div className={styles.iconWrap}>
        <Icon {...iconProps} />
      </div>
      <Typography ariaLabel='ChoiceTile' variant='body-md-regular'>
        {title}
      </Typography>
    </button>
  )
}
