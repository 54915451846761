import { observer } from 'mobx-react-lite'
import { Box, Button, SearchDropdownInput, TextFieldWithLabel } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import einExample from 'shared/assets/images/einExample.png'

import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { getCDNAssetURL } from 'shared/lib'
import styles from './styles.module.scss'
import { useBusinessProfileContext } from '../context/businessProfileContext'

export const AutoBusinessFind = observer(() => {
  const { einStore, companyLocation, openDoNotHaveEINModal } = useBusinessProfileContext()
  const { einItem, einSearch, setFirstEinItem } = einStore
  const isUsaLocation = companyLocation === 'US'
  const linkToInfo = getCDNAssetURL(einExample)

  return (
    <Box>
      <Label
        label={{
          text: isUsaLocation
            ? 'Employer Identification Number (EIN)'
            : 'Canadian Business Number (BN)',
          rightNode: isUsaLocation && (
            <Button
              text={'Where can I find it?'}
              size='small'
              typeBtn='informative'
              tag='a'
              href={linkToInfo}
              target='_blank'
            />
          ),
        }}
      />
      <SearchDropdownInput
        items={einStore.einItemList}
        search={einStore.einSearch}
        tipTitle='Search by EIN, e.g. 12-3456789'
        width={485}
        errorMessageValue={einStore.errorMessage}
        onChangeSearch={einStore.setEinSearch}
        onSetValue={setFirstEinItem}
        inputProps={{
          onBlur: einStore.checkEinNumber,
        }}
      />
      <Button
        text={`I don’t have a ${
          isUsaLocation ? 'United States EIN number' : 'Canadian Business Number'
        }`}
        typeBtn='informative'
        contained='secondary'
        onClick={openDoNotHaveEINModal}
      />

      {einStore.einItem?.ein && (
        <div className={styles.einFieldContainer}>
          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: isUsaLocation
                  ? 'Employer Identification Number (EIN)'
                  : 'Canadian Business Number (BN)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einSearch,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Legal business name',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.name,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street1,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address 2 (optional)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street2,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'City',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.city,
              className: styles.numberOfCreditsInput,
            }}
          />

          <Box display='flex' justifyContent='space-between' gap={3}>
            <Box flex={1}>
              <SelectField
                items={[]}
                setValue={() => {}}
                value={einItem?.state}
                btnProps={{
                  size: 'large',
                  disabled: true,
                  text: einItem?.state,
                }}
                dropdownProps={{
                  disabled: true,
                }}
                labelProps={{
                  label: {
                    text: isUsaLocation ? 'State' : 'Province',
                  },
                }}
              />
            </Box>
            <Box flex={1}>
              <TextFieldWithLabel
                marginBottom={0}
                labelProps={{
                  label: {
                    text: isUsaLocation ? 'Zip code' : 'Postal code',
                  },
                }}
                textFieldProps={{
                  disabled: true,
                  value: einItem?.zip,
                  className: styles.numberOfCreditsInput,
                }}
              />
            </Box>
          </Box>
        </div>
      )}
    </Box>
  )
})
