import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { makeObservable, observable, computed, action } from 'mobx'
import { DayjsFormats, downloadBlob, downloadFile, isLink } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { Base } from 'models'
import {
  type IParamsCreateMessage,
  type IParamsUpdateMessage,
  type IResponseCallConferenceMessage,
  type IResponseMessage,
  type IResponseMessageIcon,
  type IResponseRecord,
  type IResponseRecordTranscriptStatus,
} from 'entities/Message/api/types'
import { type IConversationsHistoryInfo, type IMessageType } from 'entities/Message'
import { MessagesApi } from 'entities/Message/api/messages'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'
import { type IResponseUser } from 'entities/Users/api/types'
import { MessagePendingMention } from 'entities/Message/model/MessagePendingMention'

export class Message extends Base {
  id: number
  conversation_id?: number
  client_id: number | string
  status?: 'record' | 'transcript' | 'received' | 'failed_trial' | string = ''
  failed_reason?: 'failed' | 'undelivered' | string | null
  received_at?: string
  sent_at?: string
  send_at?: string
  created_at: string
  sorted_date?: string
  body = ''
  user_id?: number
  contact_id: number
  sending = false
  undoPending = false
  is_voicemail?: boolean
  type?: IMessageType
  attachment_type?: string | null
  attachments_count?: number
  source?: 'broadcast' | 'opt_in_confirmation' | string
  broadcast_id?: number
  icon?: IResponseMessageIcon
  media: Attachment[]
  record?: IResponseRecord
  callConference?: IResponseCallConferenceMessage
  has_tagging?: boolean
  stop_on_response?: boolean
  is_contact_timezone?: boolean
  is_sent_automatically?: boolean
  pending_mentions_map: Map<number, MessagePendingMention> = new Map()
  conversations_history_info?: IConversationsHistoryInfo
  delivered_at = ''
  failed_at = ''

  constructor(
    message?: IResponseMessage,
    payload?: {
      requestMessage: {
        body: string
        send_at?: string
        sent_at?: string
        media_url: RequestAttachment[]
        type?: IMessageType
        sending?: boolean
        created_at?: string
        undoPending?: boolean
        conversation_id?: number
      }
      client_id: string
    } | null,
    conversationsHistoryInfo?: IConversationsHistoryInfo
  ) {
    super()

    if (message) {
      this.id = message.id
      this.client_id = message.client_id || message.id
      this.received_at = message.received_at
      this.sent_at = message.sent_at
      this.send_at = message.send_at
      this.created_at = message.created_at
      this.user_id = message.user_id
      this.contact_id = message.contact_id
      this.media =
        message.media?.map(
          (item) =>
            new Attachment({
              responseMedia: item,
              fromMediaUrl: true,
              isSave: !this.isOutbound,
            })
        ) || []
      this.body = this.handleInitBody(message)
      this.status = message.status
      this.failed_reason = message.failed_reason
      this.type = message.type
      this.icon = message.icon
      this.record = message.record
      this.source = message.source
      this.callConference = message.callConference
      this.is_voicemail = message.is_voicemail
      this.broadcast_id = message.broadcast_id
      this.has_tagging = message.has_tagging
      this.sorted_date = message.sorted_date || ''
      this.stop_on_response = message.stop_on_response
      this.is_contact_timezone = message.is_contact_timezone
      this.conversation_id = message.conversation_id
      this.is_sent_automatically = message.is_sent_automatically
      this.sending = false
      this.attachment_type = this.handleInitAttachmentType(message)
      this.attachments_count = this.handleInitAttachmentCount(message)
      this.pending_mentions_map = this.handleInitPendingMentions(message.pending_mentions)
      this.delivered_at = message.delivered_at
      this.failed_at = message.failed_at
    } else {
      this.client_id = payload?.client_id || ''
      this.id = 0
      this.media =
        payload?.requestMessage.media_url.map(
          (item) =>
            new Attachment({
              requestAttachment: item,
            })
        ) || []
      this.body = payload?.requestMessage.body || ''
      this.send_at = payload?.requestMessage.send_at || ''
      this.contact_id = 0
      this.sending = payload?.requestMessage.sending ?? true
      this.undoPending = payload?.requestMessage.undoPending || false
      this.created_at = payload?.requestMessage.created_at || ''
      this.sent_at = payload?.requestMessage.sent_at || ''
      this.type = payload?.requestMessage.type
      this.conversation_id = payload?.requestMessage.conversation_id
    }

    if (conversationsHistoryInfo) {
      this.conversations_history_info = conversationsHistoryInfo
    }

    makeObservable(this, {
      sending: observable,
      body: observable,
      status: observable,
      pending_mentions_map: observable,
      pending_mentions: computed,
      transcriptions: computed,
      isFailed: computed,
      isFailedTrial: computed,
      setTranscript: action.bound,
    })
  }

  get pending_mentions() {
    return Array.from(this.pending_mentions_map.values())
  }

  handleInitPendingMentions = (pending_mentions?: IResponseUser[]) => {
    if (!Array.isArray(pending_mentions)) return this.pending_mentions_map

    pending_mentions.forEach((item) => {
      this.pending_mentions_map.set(item.id, new MessagePendingMention(item))
    })

    return this.pending_mentions_map
  }

  handleInitAttachmentType = (message: IResponseMessage) => {
    if (message.attachment_type) return message.attachment_type

    return this.media.length ? this.media[0].content_type : null
  }

  handleInitAttachmentCount = (message: IResponseMessage) => {
    if (message.attachments_count) return message.attachments_count

    return this.media.length
  }

  setTranscript = (message: IResponseMessage) => {
    this.body = message.body
    this.status = message.status
  }

  setTranscriptStatus = (status: string) => {
    this.status = status
  }

  setRecordTranscriptStatus = (status: IResponseRecordTranscriptStatus) => {
    if (!this.record) return
    this.record.transcript_status = status
  }

  get recordTranscriptStatus() {
    return this.record?.transcript_status
  }

  get contact_tz() {
    return contactsStore.getItem(this.contact_id)?.timezone
  }

  get first_name() {
    if (this.isOutbound) {
      return usersStore.getItem(this.user_id)?.first_name
    }
    const first_name = contactsStore.getItem(this.contact_id)?.first_name
    return first_name?.startsWith('+') ? undefined : first_name
  }

  get phone_number() {
    if (this.isOutbound) {
      return usersStore.getItem(this.user_id)?.number
    }

    return contactsStore.getItem(this.contact_id)?.number
  }

  get isCallConference() {
    return Boolean(this.callConference)
  }

  get isAirCall() {
    return this.body.startsWith('<a href') && this.body.includes('aircall.io/calls')
  }

  get airCallLink() {
    if (this.isAirCall) {
      const match = this.body.match(/href=".*?"/)
      if (match?.length) {
        return match[0].replace('href=', '').replace(/"/g, '')
      }
    }
  }

  get statusDisplayName() {
    if (this.status === 'faked') return ''
    if (this.status === 'failed_trial') return 'Failed'

    return capitalize(this.status)
  }

  get isOutbound() {
    return !this.received_at
  }

  get sortDateTime() {
    return dayjs(this.sorted_date || this.date).valueOf()
  }

  get date() {
    if (this.failed_at) return this.failed_at
    if (this.delivered_at) return this.delivered_at
    if (this.sorted_date) return this.sorted_date
    if (this.isOutbound) return this.sent_at || this.send_at || this.created_at

    return this.received_at
  }

  get day() {
    return uiStore.dayjs(this.date).format(DayjsFormats.dateSort)
  }

  get images() {
    return this.media.filter((item) => item.type === 'image' || item.type === 'gif')
  }

  async downloadMedia(...types: Attachment['type'][]) {
    const mediaAttachments = this.media.filter((attachment) => types.includes(attachment.type))

    if (mediaAttachments.length > 1) {
      const { data } = await MessagesApi.getMessageMediaArchive(this.id)
      downloadBlob(data, `Salesmsg_${dayjs().format('YYYY-MM-DD')}.zip`)
    } else if (mediaAttachments[0].source) {
      downloadFile(mediaAttachments[0].source)
    }
  }

  get previewGifs() {
    return this.media.filter((item) => item.type === 'previewGif')
  }

  get videos() {
    return this.media.filter((item) => item.type === 'video')
  }

  get otherAttachments() {
    return this.media.filter(
      (item) =>
        item.type !== 'image' &&
        item.type !== 'gif' &&
        item.type !== 'previewGif' &&
        item.type !== 'video'
    )
  }

  get transcriptions() {
    if (this.status === 'record') {
      return 'in_progress'
    }

    if (this.status === 'transcript') {
      return this.body
    }

    return ''
  }

  get forwardInfo() {
    if (this.type === 'call' && this.body.includes('Call forwarded to')) {
      // "Call forwarded to (844) 571-0440 - 1 minute"
      const rightPart = this.body.split('Call forwarded to ')[1]
      const info = rightPart?.split(' - ')
      if (info) {
        return {
          phoneNumber: info[0],
          duration: info[1]?.replace('minute', 'min'),
        }
      }
    }

    return null
  }

  get isOnlyRecord() {
    if (this.status === 'record' || this.status === 'transcript') {
      return !!this.record
    }
    return false
  }

  get isHideAvatar() {
    if (this.isAirCall) {
      return this.icon === 'icon_voicemail'
    }

    return this.isOnlyRecord
  }

  get copyText() {
    if (this.type === 'sms' || this.type === 'mms') {
      return this.body || ''
    }
    return ''
  }

  get fromBroadcast() {
    return this.isOutbound && (this.source === 'broadcast' || this.broadcast_id)
  }

  get isAutomated() {
    return this.isOutbound && (this.source === 'opt_in_confirmation' || this.is_sent_automatically)
  }

  get isOnlyVCard() {
    return this.media.length === 1 && this.media[0].type === 'vcard'
  }

  get links() {
    const links: string[] = []
    this.body.split(/\n/).forEach((text) =>
      text.split(' ').forEach((word) => {
        if (isLink(word)) {
          links.push(word)
        }
      })
    )
    return links
  }

  get bodyParse() {
    // Added for Conference call timeline
    if (this.body.includes('Conference call ended')) {
      return 'Conference call ended'
    }

    return this.body
  }

  get is_schedule() {
    return this.send_at && !this.received_at && !this.sent_at && !this.isStatusRetry
  }

  get isStatusRetry() {
    return this.status === 'retry' || this.status === 'retry_manual'
  }

  get isFailed() {
    return (
      this.status === 'failed' ||
      this.status === 'undelivered' ||
      this.status === 'billing_declined' ||
      this.isFailedTrial
    )
  }

  get isFailedTrial() {
    return this.status === 'failed_trial'
  }

  get isDelivered() {
    return this.status === 'delivered'
  }
  get isQueued() {
    return this.status === 'queued'
  }
  get isCreated() {
    return this.status === 'created'
  }

  get isSent() {
    return this.status === 'sent'
  }

  get isRetryError() {
    if (this.failed_reason === '30006') return false

    return !this.isFailedTrial
  }

  get isNote() {
    return this.type === 'note'
  }

  get isMms() {
    return this.type === 'mms'
  }

  get messageRequestData(): IParamsCreateMessage {
    return {
      media_url: this.media.map((attachment) => new RequestAttachment(attachment)),
      message: this.body,
      send_at: this.send_at,
      stop_on_response: this.stop_on_response || false,
      is_contact_timezone: this.is_contact_timezone || false,
      type: null,
    }
  }

  get paramsUpdateMessage(): IParamsUpdateMessage {
    return {
      media_url: this.media.map((attachment) => new RequestAttachment(attachment)),
      body: this.body,
      send_at: this.send_at,
      stop_on_response: this.stop_on_response || false,
      is_contact_timezone: this.is_contact_timezone || false,
      manual_created: true,
    }
  }

  get isServerCommand() {
    // List of server commands
    // ['STOP', 'STOPALL', 'UNSUBSCRIBE', 'CANCEL', 'END', 'QUIT', 'TESTQUIT'];
    // ['START', 'YES', 'UNSTOP', 'Y'];
    // ['HELP', 'INFO'];

    const status = this.body.toLowerCase()

    return Boolean(
      status === 'start' ||
        status === 'stop' ||
        status === 'stopall' ||
        status === 'unsubscribe' ||
        status === 'cancel' ||
        status === 'end' ||
        status === 'quit' ||
        status === 'testquit' ||
        status === 'yes' ||
        status === 'unstop' ||
        status === 'y' ||
        status === 'help' ||
        status === 'info'
    )
  }

  get isConversationsHistory() {
    return Boolean(this.conversations_history_info)
  }
  get isExternalConversation() {
    return Boolean(this.conversations_history_info?.is_external)
  }
  get teamName() {
    return this.conversations_history_info?.teamName || ''
  }
  get teamId() {
    return this.conversations_history_info?.teamId
  }

  handleInitBody = (message: IResponseMessage) => {
    if (message.type === 'note') return message.body_template || message.body || ''

    return message.body_raw || message.body || ''
  }
}
