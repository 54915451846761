import { observer } from 'mobx-react-lite'
import type { IComplianceSettingsStore } from 'entities/Compliance'
import { LocalNumbersProvider } from './context/LocalNumbersContext'
import { LocalNumbersContainer } from './ui/LocalNumbersContainer'

interface ILocalNumbersProps {
  complianceSettingsStore: IComplianceSettingsStore
}
const LocalNumbersContent = observer(() => {
  return <LocalNumbersContainer />
})

export const LocalNumbers = ({ complianceSettingsStore }: ILocalNumbersProps) => {
  return (
    <LocalNumbersProvider complianceSettingsStore={complianceSettingsStore}>
      <LocalNumbersContent />
    </LocalNumbersProvider>
  )
}
