import React from 'react'
import { observer } from 'mobx-react-lite'
import { EmptyState, Typography } from 'shared/ui'
import knowledgeBasePreviewImg from 'shared/assets/images/knowledgeBase/knowledge_base_preview.png'
import { links } from 'shared/constants/links'
import { getCDNAssetURL } from 'shared/lib'
import { KnowledgeBaseActions } from 'pages/settings/pages/knowledgeBaseView/ui/KnowledgeBaseActions'
import styles from './styles.module.scss'

export const KnowledgeBaseViewEmpty = observer(() => {
  return (
    <EmptyState
      title={
        <span className={styles.title}>
          <a
            href={links.knowledgeBaseHelpGuide}
            target='_blank'
            rel='noreferrer'
            className={styles.previewImageContainer}
          >
            <img
              alt='Knowledge base preview'
              width='492px'
              height='276px'
              src={getCDNAssetURL(knowledgeBasePreviewImg)}
            />
          </a>
          <Typography variant={'heading-md'} ariaLabel={'KnowledgeBaseTitle'}>
            Build your knowledge base
          </Typography>
        </span>
      }
      text='Help your AI deliver smarter, faster responses'
      actions={<KnowledgeBaseActions />}
      typographyProps={{
        variant: 'body-rg-regular',
        ariaLabel: 'KnowledgeBaseText',
        marginTop: '4px',
      }}
      boxProps={{
        gap: '20px',
      }}
      noIcon
    />
  )
})
