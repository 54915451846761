import { observer } from 'mobx-react-lite'
import { KEYWORD_DESCRIPTION_LIMIT } from 'shared/constants/limits'
import { TextInput } from 'shared/ui'
import { useKeywordViewContext } from 'pages/keywords/context'

import styles from './styles.module.scss'

export const KeywordDescription = observer(() => {
  const { setDescription, removeError, description, errorsMap } = useKeywordViewContext()

  const error = errorsMap.get('description')

  return (
    <TextInput
      size='small'
      placeholder={'Add internal description'}
      value={description}
      setValue={setDescription}
      error={error}
      onFocus={() => removeError('description')}
      limit={KEYWORD_DESCRIPTION_LIMIT}
      className={styles.keywordDescription}
    />
  )
})
