import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class KeywordPageStore {
  pageLayoutStore = new PageLayoutStore()
  isKeywordsActionShown = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsKeywordsActionShown = (isKeywordsActionShown: boolean) => {
    this.isKeywordsActionShown = isKeywordsActionShown
  }
}
