import { observer } from 'mobx-react-lite'
import { Tabs, Typography } from 'shared/ui'

import styles from './styles.module.scss'
import { AutoBusinessFind } from './AutoBusinessFind'
import { ManuallyBusinessFind } from './ManuallyBusinessFind'
import { useBusinessProfileContext } from '../context/businessProfileContext'
import { ErrorBanner } from './ErrorBanner'

export const GeneralInfo = observer(() => {
  const { generalInfoActiveTab, isUsaLocation, changeGeneralInfoTab } = useBusinessProfileContext()

  return (
    <>
      <div className={styles.bannerWrap}>
        <Typography
          variant='body-md-regular'
          ariaLabel='Business_info_line'
          color='var(--content-primary-tertiary)'
        >
          Make sure this information is an exact match to your official paperwork
        </Typography>

        <ErrorBanner />
      </div>

      <div className={styles.generalInfoTabsContainer}>
        {isUsaLocation && (
          <>
            <Tabs
              tabs={[
                { name: 'Find your business', key: 1 },
                { name: 'Enter manually', key: 2 },
              ]}
              activeTabKey={generalInfoActiveTab}
              handleSelectTab={(tab) => changeGeneralInfoTab(tab.key)}
              margin={'0 0 24px 0'}
            />
            {generalInfoActiveTab === 1 && <AutoBusinessFind />}
            {generalInfoActiveTab === 2 && <ManuallyBusinessFind />}
          </>
        )}

        {!isUsaLocation && <ManuallyBusinessFind />}
      </div>
    </>
  )
})
