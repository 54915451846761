import { makeAutoObservable, runInAction } from 'mobx'
import {
  type IIntegrationKey,
  Integration,
  IntegrationsApi,
  isValidIntegrationKey,
} from 'entities/Integrations'

export class IntegrationDetailsStore {
  private readonly _integrationKey: IIntegrationKey | null = null
  private _integration: Integration | null = null
  private _isLoading = true
  private _isNotFound = false

  constructor(integrationKey: string | undefined) {
    if (integrationKey && isValidIntegrationKey(integrationKey)) {
      this._integrationKey = integrationKey
    } else {
      this._isNotFound = true
      this._isLoading = false
    }
    makeAutoObservable(this)
  }

  init = async () => {
    if (!this._integrationKey) return

    try {
      const { data } = await IntegrationsApi.getIntegrationByKey(this._integrationKey)

      runInAction(() => {
        if (!data) {
          this._isNotFound = true
          return
        }
        this._integration = new Integration(data)
      })
    } catch (e) {
      console.error(e)
      runInAction(() => {
        this._isNotFound = true
      })
    } finally {
      runInAction(() => {
        this._isLoading = false
      })
    }
  }

  get isLoading() {
    return this._isLoading
  }

  get isNotFound() {
    return this._isNotFound
  }

  get integration() {
    return this._integration
  }

  dispose = () => {}
}
