import classnames from 'classnames'
import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DropdownCard,
  dropdownStyles,
  EnumDropdownItemVariant,
  Icon,
  IDropdownItem,
  ITooltipProps,
  Status,
  Tooltip,
} from 'shared/ui'
import { links } from 'shared/constants/links'
import { uiStore } from 'shared/store/uiStore'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { PowerDialerStore } from 'widgets/PowerDialer/store/powerDialerStore'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'
import styles from './styles.module.scss'

type IPowerDialerActionDropdownItemProps = {
  onClick?: () => void
}

export const PowerDialerActionDropdownItem: FC<IPowerDialerActionDropdownItemProps> = observer(
  ({ onClick }) => {
    const [store] = useState(() => new PowerDialerStore())
    const inbox = inboxesStore.currentInbox
    const sharedInboxes = inboxesStore.sharedInboxes
    const sharedInbox = inbox?.type === 'inbox' ? inbox : null

    const countInActiveOutboundCallsInboxes = sharedInboxes.filter(
      (item) => !item.isNumberOutboundCalls
    )
    const countAircallInboxes = sharedInboxes.filter(
      (item) => item.isCallViaAircall || numbersStore.getItem(item.numberId)?.is_aircall
    )
    const hasAllAircall = countAircallInboxes.length === sharedInboxes.length
    const hasAllInActiveOutboundCalls =
      countInActiveOutboundCallsInboxes.length === sharedInboxes.length
    const hasCurrentAircallInbox =
      sharedInbox?.isCallViaAircall || !!numbersStore.getItem(sharedInbox?.numberId)?.is_aircall
    const hasCurrentInActiveOutboundCalls = !sharedInbox?.isNumberOutboundCalls

    const hideButton = Boolean(
      usersStore.user?.isViewOnlyRole || hasCurrentAircallInbox || hasAllAircall
    )

    if (hideButton) return null

    const isCallOutboundDisabled = sharedInbox
      ? hasCurrentInActiveOutboundCalls
      : hasAllInActiveOutboundCalls
    const disabled =
      isCallOutboundDisabled ||
      callPopUpGlobalStore.hasPowerDialer ||
      !featureFlagsStore.inteliquent_calling

    const getTooltipLabel = () => {
      if (isCallOutboundDisabled) {
        if (
          usersStore.user?.isAdminRole ||
          usersStore.user?.isOwnerRole ||
          (inbox?.type === 'inbox' && inbox?.ownerId === usersStore.user?.id)
        ) {
          return (
            <>
              Outbound calling is disabled
              <br />
              <button
                onClick={(event) => {
                  event.preventDefault()

                  uiStore.changeRoute({
                    path: `/settings/organization/inboxes/all-inboxes/${inbox?.id}/calling`,
                    type: 'vue',
                  })
                }}
              >
                Enable it here
              </button>
            </>
          )
        }

        return 'Outbound calling is disabled. Contact your admin to enable it.'
      }

      return (
        <>
          Autodial multiple contacts <br />
          <a href={`${links.powerDialer}`} target='_blank' rel='noreferrer'>
            Learn more
          </a>
        </>
      )
    }
    const item: IDropdownItem = {
      id: 'power_dialer',
      iconL: 'phoneRightArrows',
      label: 'Power Dialer',
      variant: EnumDropdownItemVariant.Custom,
      iconQuestionProps: {
        fontSize: 14,
      },
      renderOption: (item) => {
        const label = getTooltipLabel()
        const tooltipProps: ITooltipProps = {
          placement: 'right',
          type: isCallOutboundDisabled ? 'default' : 'description',
          margin: 18,
          label: label,
        }

        return (
          <div className={styles.dropdownCard}>
            <div className={styles.dropdownCard__content}>
              <div
                className={classnames(dropdownStyles.icon, {
                  [dropdownStyles.icon__disabled]: disabled,
                })}
              >
                <Icon icon={item.iconL} />
              </div>
              <div
                className={classnames(dropdownStyles.title, {
                  [dropdownStyles.title__disabled]: disabled,
                })}
              >
                {item.label}
              </div>
              <div className={styles.dropdownCard__status}>
                <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
              </div>
            </div>
            <div className={classnames(dropdownStyles.tooltip, styles.dropdownCard__tooltip)}>
              <Tooltip {...tooltipProps}>
                <Icon icon={'question'} {...item.iconQuestionProps} />
              </Tooltip>
            </div>
          </div>
        )
      },
      disabled: disabled,
      className: styles.dropdown,
    }

    return (
      <DropdownCard
        item={item}
        onChange={() => {
          store.editEnrollment()
          onClick?.()
        }}
      />
    )
  }
)
