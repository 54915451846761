import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import classNames from 'classnames'
import { Button, IconButton, ProgressInfo, Typography } from 'shared/ui'
import { CompanyLocation } from './CompanyLocation'

import styles from './styles.module.scss'
import { BusinessInfo } from './BusinessInfo'
import { PeopleToContact } from './PeopleToContact'
import { GeneralInfo } from './GeneralInfo'
import { TermsAndPrivacy } from './TermsAndPrivacy'
import { useBusinessProfileContext } from '../context/businessProfileContext'

const titleMap: Record<number, string> = {
  1: 'Company location',
  2: 'General info',
  3: 'Business info',
  4: 'People to contact',
  5: 'Terms and Privacy',
}

export const BusinessProfileContainer = observer(() => {
  const navigate = useNavigate()
  const {
    companyLocation,
    einStore,
    generalInfoActiveTab,
    businessInfoStore,
    peopleToContactStore,
    isTermsAndPrivacyChecked,
    stepLoading,
    isUsaLocation,
    complianceSettingsStore,
    submitGeneralInfo,
    submitPointsOfContact,
    submitTermsAndPrivacy,
  } = useBusinessProfileContext()

  const { settingsTotal, settingsStep, setTitle, decreaseStep, increaseStep } =
    complianceSettingsStore.stepsStore

  useEffect(() => {
    setTitle('Business profile')
  }, [])

  const isNextDisabled = useMemo(() => {
    switch (settingsStep) {
      case 1:
        return !companyLocation
      case 2:
        if (generalInfoActiveTab === 2 || !isUsaLocation) {
          return !einStore.isManuallyEinItemFulled
        }
        if (generalInfoActiveTab === 1) {
          return !einStore.isAutoModeFullEin
        }
        return false
      case 3:
        return businessInfoStore.isNextDisabled
      case 4:
        return peopleToContactStore.isNextDisabled
      case 5:
        return !isTermsAndPrivacyChecked
      default:
        return false
    }
  }, [
    settingsStep,
    companyLocation,
    einStore.einItem,
    einStore.isManuallyEinItemFulled,
    generalInfoActiveTab,
    businessInfoStore.isNextDisabled,
    peopleToContactStore.isNextDisabled,
    isTermsAndPrivacyChecked,
  ])

  return (
    <>
      <IconButton
        className={styles.closeIcon}
        color='transparent'
        ariaLabel='close_setting'
        icon='close'
        size='medium'
        onClick={() => navigate(-1)}
      />
      <div
        className={classNames(styles.complianceSettingContainer, {
          [styles.noFullHeight]: settingsStep === 3,
        })}
      >
        <div className={styles.titleStatus}>
          <Typography ariaLabel='QuestionsPage_title' variant='body-xs-regular'>
            Business profile
          </Typography>
        </div>
        <div
          className={classNames(styles.progressWrap, {
            [styles.progressWithArrowWrap]: settingsStep > 1,
          })}
        >
          {settingsStep > 1 && (
            <div className={styles.backButton}>
              <IconButton
                className={styles.backButtonIcon}
                color='transparent'
                ariaLabel='back_to_lastStep'
                icon='arrowLeft'
                size='medium'
                onClick={decreaseStep}
              />
            </div>
          )}
          <ProgressInfo
            title={titleMap[settingsStep]}
            step={settingsStep}
            total={settingsTotal}
            className={styles.progressInfo}
          />
        </div>
        {settingsStep === 1 && <CompanyLocation />}
        {settingsStep === 2 && <GeneralInfo />}
        {settingsStep === 3 && <BusinessInfo />}
        {settingsStep === 4 && <PeopleToContact />}
        {settingsStep === 5 && <TermsAndPrivacy />}
        <Button
          fullWidth
          loading={stepLoading}
          disabled={isNextDisabled || stepLoading}
          className={styles.nextButton}
          text={settingsStep === 5 ? 'Submit for review' : 'Next'}
          onClick={() => {
            if (settingsStep === 1) {
              increaseStep()
            }
            if (settingsStep === 2) {
              submitGeneralInfo(1, increaseStep)
            }
            if (settingsStep === 3) {
              submitGeneralInfo(2, increaseStep)
            }
            if (settingsStep === 4) {
              submitPointsOfContact(increaseStep)
            }
            if (settingsStep === 5) {
              submitTermsAndPrivacy()
            }
          }}
        />
      </div>
    </>
  )
})
