import { makeAutoObservable, runInAction, reaction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IIconsVector } from 'shared/ui'
import { numbersStore } from 'entities/Phone'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import { OrganizationApi, organizationStore } from 'entities/Organization'
import {
  MessagingRegionModalContent,
  MessagingRegionModalActions,
} from 'widgets/MessagingRegionModal'
import { getStartedModalStore } from 'widgets/getStartedModal'

export enum IRegionsEnum {
  US = 'US',
  CA = 'CA',
  ALL = 'ALL',
}

type IRegion = {
  label: string
  icon?: IIconsVector
  key: IRegionsEnum
}

export class MessagingRegionModalStore {
  idModal = 'messagingRegionModal'
  selectedRegion: IRegionsEnum | null = null
  loading = false

  constructor() {
    makeAutoObservable(this)

    this.reactionInit()
  }

  get regions(): IRegion[] {
    return [
      {
        label: 'US only',
        icon: 'flagUSA',
        key: IRegionsEnum.US,
      },
      {
        label: 'Canada only',
        icon: 'flagCanada',
        key: IRegionsEnum.CA,
      },
      {
        label: 'US & Canada',
        key: IRegionsEnum.ALL,
      },
    ]
  }

  setRegion = (region: IRegionsEnum) => {
    this.selectedRegion = region
  }

  init = async () => {
    await Promise.all([
      usersStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
      numbersStore.initFetchOrganizationNumberPromise,
    ])

    if (
      !organizationStore.isTwilioNumberVendor &&
      numbersStore.organizationNumber &&
      usersStore.user &&
      usersStore.user.isRequirePftNumber &&
      !usersStore.user.isRequireNumber &&
      subscriptionStore.isTrial
    ) {
      if (!numbersStore.organizationNumber.isTollFree) {
        modalStore.addModal({
          id: this.idModal,
          showCloseIcon: false,
          showCloseButton: false,
          disabledOnAllClose: true,
          disabledOverlayClose: true,
          disabledOnEscClose: true,
          title: 'Messaging region',
          paddingContent: '8px 24px 0 24px',
          width: 480,
          ModalActions: MessagingRegionModalActions,
          ModalContent: MessagingRegionModalContent,
          onClose: this.closeModal,
        })
      } else {
        getStartedModalStore.init()
      }
    }
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this.idModal)
  }

  reset = () => {
    this.selectedRegion = null
    this.loading = false
  }

  handleUpdateRegion = async () => {
    if (!this.selectedRegion) return

    if (this.selectedRegion !== IRegionsEnum.CA) {
      this.closeModal()
      getStartedModalStore.init()
      return
    }

    try {
      runInAction(() => {
        this.loading = true
      })

      await OrganizationApi.updateOrganizationMessagingRegion({
        is_canada_only: true,
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
      this.closeModal()
      getStartedModalStore.init()
    }
  }

  reactionInit = () => {
    reaction(
      () => [
        numbersStore.organizationNumber,
        usersStore.user?.isRequirePftNumber,
        usersStore.user?.isRequireNumber,
        subscriptionStore.isTrial,
      ],
      () => {
        this.init()
      }
    )
  }
}

export const messagingRegionModalStore = new MessagingRegionModalStore()
