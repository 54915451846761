import { Status } from 'shared/ui'
import { IKeywordStatus } from 'entities/Keywords/api/type'

import config from './config'

export interface IKeywordStatusProps {
  status: IKeywordStatus
}

export const KeywordStatus = ({ status }: IKeywordStatusProps) => <Status {...config[status]} />
