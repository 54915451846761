import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router'
import { Icon, Typography } from 'shared/ui'
import { Integration } from 'entities/Integrations/model/Integration'
import { integrationsTextDataMap } from 'entities/Integrations/lib/integrationsData'
import { IntegrationStatus } from 'widgets/Integrations'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'
import styles from './styles.module.scss'

type IIntegrationListCardProps = {
  integration: Integration
}

export const IntegrationListCard = observer(({ integration }: IIntegrationListCardProps) => {
  const navigate = useNavigate()
  const integrationData = integrationsTextDataMap[integration.key]

  const onCardClick = () => {
    if (integration.key === 'webhook') {
      navigate('webhooks')
      return
    }
    if (integration.isConnected && integration.oauth) {
      navigate(`${integration.key}/${IntegrationsRoutes.settings}`)
      return
    }
    navigate(`${integration.key}/${IntegrationsRoutes.description}`)
  }

  return (
    <div className={styles.card} onClick={onCardClick}>
      <div className={styles.cardHeader}>
        <Icon icon={integration.icon} fontSize={26} />
        <IntegrationStatus integration={integration} />
      </div>
      <div className={styles.data}>
        <Typography variant='body-md-medium' ariaLabel={'Integration_name'}>
          {integration.name}
        </Typography>
        <Typography
          variant='body-md-regular'
          ariaLabel={'Integration_subheadline'}
          color='tertiary'
          className={styles.subheadline}
        >
          {integrationData?.subheadline}
        </Typography>
      </div>
    </div>
  )
})
