import classNames from 'classnames'
import styles from './styles.module.scss'

interface IBlockNumberProps {
  number: string
  isOpen: boolean
}

export const BlockNumber = ({ number, isOpen }: IBlockNumberProps) => {
  return (
    <div
      className={classNames(styles.blockNumber, {
        [styles.blockNumberOpen]: isOpen,
        [styles.blockNumberClosed]: !isOpen,
      })}
    >
      {number}
    </div>
  )
}
