import type { IStatusProps } from 'shared/ui/Status'

const statuses = {
  disabled: {
    title: 'Disabled',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
}

export default statuses
