import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { ProgressInfo } from 'shared/ui'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'

import styles from './styles.module.scss'
import { ILocalNumbersRoutesEnum } from '../types'

export const LocalProgressInfo = observer(() => {
  const { allStepsLength, getSettingsStep, getProgressInfoTitle, getLastPathSegment } =
    useLocalNumbersContext()
  const { pathname } = useLocation()
  const lastPathSegment = getLastPathSegment(pathname) as ILocalNumbersRoutesEnum

  return (
    <ProgressInfo
      title={getProgressInfoTitle(lastPathSegment)}
      step={getSettingsStep(lastPathSegment)}
      total={allStepsLength}
      className={styles.progressInfo}
    />
  )
})
