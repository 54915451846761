import { observer } from 'mobx-react-lite'
import { EmptyState, Typography } from 'shared/ui'
import keywordsPreviewImg from 'shared/assets/images/keywords/keywords_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import styles from './styles.module.scss'
import { KeywordNewButton, KeywordsHelpGuideButton } from '../KeywordsButtons/KeywordButtons'

export const EmptyStateKeywords = observer(() => {
  return (
    <EmptyState
      title={
        <span className={styles.title}>
          <div className={styles.emptyPreview}>
            <img alt='Keywords preview' src={getCDNAssetURL(keywordsPreviewImg)} />
          </div>

          <Typography variant={'heading-md'} ariaLabel={'KeywordsTitle'}>
            Create your first keyword
          </Typography>
        </span>
      }
      text='Use keywords to capture leads'
      actions={
        <>
          <KeywordNewButton />
          <KeywordsHelpGuideButton />
        </>
      }
      typographyProps={{
        variant: 'body-rg-regular',
        ariaLabel: 'KeywordsText',
        marginTop: '4px',
      }}
      boxProps={{
        gap: '20px',
      }}
      noIcon
    />
  )
})
