import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography } from 'shared/ui'
import { MessagePreview } from 'widgets/MessagePreview'
import { SMSMessageField } from 'widgets/MessageFormFields'
import { TagsDropdown } from 'widgets/TagsDropdown'
import {
  KeywordDescription,
  KeywordName,
} from 'pages/keywords/pages/createKeyword/ui/KeywordFields'
import { useKeywordViewContext } from 'pages/keywords/context'
import styles from './styles.module.scss'

export const KeywordViewContent = observer(() => {
  const {
    keywordSMSStore: { messageFieldStore },
    errorsMap,
    setDraggingCondition,
    isDraggingCondition,
    tagsControlStore,
  } = useKeywordViewContext()
  const smsMessageError = errorsMap.get('smsMessage')

  return (
    <div className={styles.wrap}>
      <div
        className={classnames(styles.keywordViewBox, {
          [styles.keywordViewBox__isRelative]: isDraggingCondition,
        })}
      >
        <Typography variant={'body-xs-regular'} className={styles.label} ariaLabel={'NewKeyword'}>
          New keyword
        </Typography>
        <KeywordName />
        <KeywordDescription />
        <SMSMessageField
          messageFieldStore={messageFieldStore}
          errorMessage={smsMessageError}
          setDraggingCondition={setDraggingCondition}
        />
        <div className={styles.section}>
          <div className={styles.section__box}>
            <TagsDropdown store={tagsControlStore} />
          </div>
        </div>
      </div>

      <div className={styles.keywordPreviewBox}>
        <MessagePreview type={'sms'} messageStore={messageFieldStore} />
      </div>
    </div>
  )
})
