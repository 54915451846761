import { makeAutoObservable } from 'mobx'
import type { IResponseUserCredential, IResponseUserIntegration } from 'entities/Integrations'

export class UserIntegration {
  id: number
  connection_error: number
  is_active: boolean
  url: string
  user_credential?: IResponseUserCredential

  constructor(item: IResponseUserIntegration) {
    this.id = item.id
    this.url = item.url
    this.connection_error = item.connection_error
    this.user_credential = item.user_credential
    this.is_active = item.is_active

    makeAutoObservable(this)
  }
}
