// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kY83QoEKMwFeBj0_9T3l{margin-top:26px;padding-bottom:32px;border-bottom:1px solid var(--interaction-neutral-normal)}.kY83QoEKMwFeBj0_9T3l input{color:var(--content-primary-secondary);font-weight:400}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/createKeyword/ui/KeywordFields/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,mBAAA,CACA,yDAAA,CAEA,4BACE,sCAAA,CACA,eAAA","sourcesContent":[".keywordDescription {\n  margin-top: 26px;\n  padding-bottom: 32px;\n  border-bottom: 1px solid var(--interaction-neutral-normal);\n\n  input {\n    color: var(--content-primary-secondary);\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keywordDescription": "kY83QoEKMwFeBj0_9T3l"
};
export default ___CSS_LOADER_EXPORT___;
