import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Button, Collapse, InputCheckbox, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import styles from './styles.module.scss'
import { BlockNumber } from './BlockNumber'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { forbiddenTopics } from '../constants'

export const ForbiddenMessageTopics = observer(() => {
  const { forbiddenMessageStore, stepLoading } = useLocalNumbersContext()

  const { forbiddenMessageAgreed, isNextStepDisabled, setForbiddenMessageAgreed, increaseStep } =
    forbiddenMessageStore

  return (
    <div className={classNames(styles.contentWrap, styles.forbiddenTopicsWrap)}>
      <Typography
        variant='body-md-regular'
        ariaLabel='Business_info_line'
        className={styles.pageDesc}
      >
        Certain topics are not permitted over SMS / MMS in the United States or Canada. Activity
        related to these topics may result in your messages being blocked by carriers.
        <a
          href={links.localNumbersContainerLearnMoreLink}
          target='_blank'
          rel='noreferrer'
          className={styles.learnMoreLink}
        >
          <Typography
            ariaLabel='TermsAndPrivacy_link'
            variant='body-md-regular'
            color='var(--content-brand-primary)'
            className={styles.learnMoreLink}
          >
            Learn more
          </Typography>
        </a>
      </Typography>

      {forbiddenTopics.map((topic) => (
        <Collapse
          key={topic.number}
          isOpen={false}
          title={topic.title}
          type='withHover'
          leftContent={(isOpen) => <BlockNumber number={topic.number} isOpen={isOpen} />}
        >
          <div className={styles.collapseContent}>
            {topic.content.map((paragraph, index) => (
              <Typography
                key={index}
                ariaLabel='forbiddenMessageTopics_line'
                variant='body-rg-regular'
                color='var(--content-primary-primary)'
              >
                {paragraph}
              </Typography>
            ))}
          </div>
        </Collapse>
      ))}

      <InputCheckbox
        className={styles.forbiddenMessageAgreed}
        label='I confirm I will not send messages related to these topics'
        checked={forbiddenMessageAgreed}
        onChecked={setForbiddenMessageAgreed}
      />

      <Button
        fullWidth
        loading={stepLoading}
        disabled={isNextStepDisabled || stepLoading}
        className={styles.nextButton}
        text='Next'
        onClick={increaseStep}
      />
    </div>
  )
})
