// Will delete after creating the new EP
// https://salesmsg.atlassian.net/browse/SWC-9572

export const stockExchanges = [
  {
    id: 2,
    title: 'Armenia Securities Exchange',
    abbreviation: 'AMX',
  },
  {
    id: 3,
    title: 'Australian Securities Exchange',
    abbreviation: 'ASX',
  },
  {
    id: 4,
    title: 'Brazil Stock Exchange',
    abbreviation: 'B3',
  },
  {
    id: 5,
    title: 'Bolsas y Mercados Espanoles',
    abbreviation: 'BME',
  },
  {
    id: 6,
    title: 'Bombay Stock Exchange',
    abbreviation: 'BSE',
  },
  {
    id: 7,
    title: 'Frankfurt Stock Exchange',
    abbreviation: 'FRA',
  },
  {
    id: 8,
    title: 'Indian Commodity Exchange',
    abbreviation: 'ICEX',
  },
  {
    id: 9,
    title: 'Japan Exchange Group',
    abbreviation: 'JPX',
  },
  {
    id: 10,
    title: 'Jamaica Stock Exchange',
    abbreviation: 'JSE',
  },
  {
    id: 11,
    title: 'Korea Exchange',
    abbreviation: 'KRX',
  },
  {
    id: 12,
    title: 'London Stock Exchange',
    abbreviation: 'LON',
  },
  {
    id: 13,
    title: 'NASDAQ',
    abbreviation: 'NASDAQ',
  },
  {
    id: 14,
    title: 'National Stock Exchange of India Ltd',
    abbreviation: 'NSE',
  },
  {
    id: 15,
    title: 'New York Stock Exchange',
    abbreviation: 'NYSE',
  },
  {
    id: 16,
    title: 'None',
    abbreviation: 'None',
  },
  {
    id: 17,
    title: 'NASDAQ Stockholm',
    abbreviation: 'OMX',
  },
  {
    id: 18,
    title: 'Stock Exchange of Hong Kong',
    abbreviation: 'SEHK',
  },
  {
    id: 19,
    title: 'Singapore Exchange Limited',
    abbreviation: 'SGK',
  },
  {
    id: 20,
    title: 'Shanghai Exchange Limited',
    abbreviation: 'SSE',
  },
  {
    id: 21,
    title: 'Stockholm Stock Exchange',
    abbreviation: 'STO',
  },
  {
    id: 22,
    title: 'Swiss Stock Exchange',
    abbreviation: 'SWX',
  },
  {
    id: 23,
    title: 'Shenzhen Stock Exchange',
    abbreviation: 'SZSE',
  },
  {
    id: 24,
    title: 'Toronto Stock Exchange',
    abbreviation: 'TSX',
  },
  {
    id: 25,
    title: 'Taiwan Stock Exchange',
    abbreviation: 'TWSE',
  },
  {
    id: 26,
    title: 'Vadodara Stock Exchange Limited',
    abbreviation: 'VSE',
  },
  {
    id: 27,
    title: 'Other',
    abbreviation: 'Other',
  },
  {
    id: 1,
    title: 'American Stock Exchange',
    abbreviation: 'AMEX',
  },
]
