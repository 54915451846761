import { makeAutoObservable } from 'mobx'
import { getCDNAssetURL, isLink, isUrlDomainsSimilar } from 'shared/lib'
import { toastStore } from 'shared/ui'
import type { IResponseMedia } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { ComplianceApi } from 'entities/Compliance'
import PresentationStore from 'widgets/PresentationMode/store/PresentationStore'
import type { LocalNumbersStore } from './LocalNumbersStore'

const maxAttachmentsLength = 3

enum IAttachmentType {
  onlineForm = 'onlineForm',
  paperForm = 'paperForm',
  text = 'text',
  qrCode = 'qrCode',
}

export class OptInConsentStore {
  constructor(private _localNumbersStore: LocalNumbersStore) {
    makeAutoObservable(this)
  }

  private _optInImageAttachments: Map<IAttachmentType, IResponseMedia[] | null> = new Map()
  private _attachmentsData: Map<IAttachmentType, Attachment[] | null> = new Map()
  private _attachmentError: Map<IAttachmentType, { title?: string; desc?: string } | null> =
    new Map()
  private _attachmentErrorTimeouts: Map<IAttachmentType, number | null> = new Map()
  private _onlineFormUrlLocalError = ''
  private _onlineFormUrl = ''
  private _noPublishedOnlineFormUrl = false
  private _termsAndPrivacyAgreement = false
  private _similarityDomainsWarning = ''
  get optInOnlineFormImageAttachments() {
    return this._optInImageAttachments.get(IAttachmentType.onlineForm) || null
  }

  get optInPaperFormImageAttachments() {
    return this._optInImageAttachments.get(IAttachmentType.paperForm) || null
  }

  get optInTextImageAttachments() {
    return this._optInImageAttachments.get(IAttachmentType.text) || null
  }

  get optInQrCodeImageAttachments() {
    return this._optInImageAttachments.get(IAttachmentType.qrCode) || null
  }

  get optInOnlineFormAttachmentsData() {
    return this._attachmentsData.get(IAttachmentType.onlineForm) || null
  }

  get optInPaperFormAttachmentsData() {
    return this._attachmentsData.get(IAttachmentType.paperForm) || null
  }

  get optInTextAttachmentsData() {
    return this._attachmentsData.get(IAttachmentType.text) || null
  }

  get optInQrCodeAttachmentsData() {
    return this._attachmentsData.get(IAttachmentType.qrCode) || null
  }

  get optInOnlineFormAttachmentError() {
    return this._attachmentError.get(IAttachmentType.onlineForm) || null
  }

  get optInPaperFormAttachmentError() {
    return this._attachmentError.get(IAttachmentType.paperForm) || null
  }

  get optInTextAttachmentError() {
    return this._attachmentError.get(IAttachmentType.text) || null
  }

  get optInQrCodeAttachmentError() {
    return this._attachmentError.get(IAttachmentType.qrCode) || null
  }

  get onlineFormUrlLocalError() {
    return this._onlineFormUrlLocalError
  }

  get onlineFormUrl() {
    return this._onlineFormUrl
  }

  get noPublishedOnlineFormUrl() {
    return this._noPublishedOnlineFormUrl
  }

  get termsAndPrivacyAgreement() {
    return this._termsAndPrivacyAgreement
  }

  get similarityDomainsWarning() {
    return this._similarityDomainsWarning
  }

  get complianceSettingsStore() {
    return this._localNumbersStore.complianceSettingsStore
  }

  get isOnlineFormNextDisabled() {
    return Boolean(
      !this.termsAndPrivacyAgreement ||
        this.optInOnlineFormAttachmentError ||
        !this.optInOnlineFormAttachmentsData?.length
    )
  }

  get isPaperFormNextDisabled() {
    return Boolean(!this.optInPaperFormAttachmentsData?.length)
  }

  get isTextNextDisabled() {
    return Boolean(!this.optInTextAttachmentsData?.length)
  }

  get isQrCodeNextDisabled() {
    return Boolean(!this.optInQrCodeAttachmentsData?.length)
  }

  openApprovedExampleModal = (urls: string[], name: string[]) => {
    const attachments = urls.map(
      (url, index) => new Attachment({ imageUrl: getCDNAssetURL(url), name: name[index] })
    )

    PresentationStore.setAttachments(attachments)
  }
  setOptInImageAttachments = (type: IAttachmentType, data: IResponseMedia[] | null) => {
    this._optInImageAttachments.set(type, data)
  }

  setAttachmentsData = (type: IAttachmentType, attachments: Attachment[]) => {
    this._attachmentsData.set(type, attachments)
  }

  clearAttachmentError = (type: IAttachmentType) => {
    this._attachmentError.set(type, null)

    const timeout = this._attachmentErrorTimeouts.get(type)
    if (timeout) {
      clearTimeout(timeout)
      this._attachmentErrorTimeouts.set(type, null)
    }
  }

  clearOnlineFormAttachmentError = () => {
    this.clearAttachmentError(IAttachmentType.onlineForm)
  }

  clearPaperFormAttachmentError = () => {
    this.clearAttachmentError(IAttachmentType.paperForm)
  }

  clearTextAttachmentError = () => {
    this.clearAttachmentError(IAttachmentType.text)
  }

  clearQrCodeAttachmentError = () => {
    this.clearAttachmentError(IAttachmentType.qrCode)
  }

  setAttachmentError = (type: IAttachmentType, error: { title?: string; desc?: string } | null) => {
    this.clearAttachmentError(type)
    this._attachmentError.set(type, error)

    if (error === null) return

    const timer = window.setTimeout(() => {
      this._attachmentError.set(type, null)
    }, 5000)

    this._attachmentErrorTimeouts.set(type, timer)
  }

  getUploadError = (files: File[]) => {
    const sizeLimits = {
      'image/png': 30 * 1024 * 1024, // 30 MB
      'image/jpeg': 30 * 1024 * 1024, // 30 MB
      'application/pdf': 10 * 1024 * 1024, // 10 MB
    }

    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg']

    const noCorrectType = files.every((file) => !allowedTypes.includes(file.type))

    if (files.length > maxAttachmentsLength) {
      return {
        title: 'Please upload up to 3 images per opt-in method',
      }
    }

    if (noCorrectType) {
      return {
        title: 'The file is not supported for upload',
        desc: 'Please upload only: pdf, jpg, png',
      }
    }

    const maxSizeFile = files.find(
      (file) =>
        allowedTypes.includes(file.type) &&
        file.size > sizeLimits[file.type as keyof typeof sizeLimits]
    )

    if (maxSizeFile) {
      return {
        desc: maxSizeFile.type.includes('image')
          ? 'The image file is too large. It must not exceed 30 MB'
          : 'The file is too large. It must not exceed 10 MB',
      }
    }

    return null
  }

  uploadOptInImageAttachment = async (files: File[], type: IAttachmentType) => {
    const error = this.getUploadError(files)

    if (error) {
      this.setAttachmentError(type, error)
      return
    }

    const attachmentsData = files.map((file) => new Attachment({ file }))
    this.setAttachmentsData(type, attachmentsData)

    try {
      const { data } = await ComplianceApi.setCampaignOptInFiles(files)
      this.setOptInImageAttachments(type, data)

      toastStore.add({
        title: `${files.length} file${files.length > 1 ? 's' : ''} uploaded`,
        type: 'success',
      })
    } catch (error) {
      console.error(error)
    }
  }

  uploadOnlineFormOptInImageAttachment = async (files: File[] | null) => {
    if (!files) return

    this.uploadOptInImageAttachment(files, IAttachmentType.onlineForm)
  }

  uploadPaperFormOptInImageAttachment = async (files: File[] | null) => {
    if (!files) return

    this.uploadOptInImageAttachment(files, IAttachmentType.paperForm)
  }

  uploadTextOptInImageAttachment = async (files: File[] | null) => {
    if (!files) return

    this.uploadOptInImageAttachment(files, IAttachmentType.text)
  }

  uploadQrCodeOptInImageAttachment = async (files: File[] | null) => {
    if (!files) return

    this.uploadOptInImageAttachment(files, IAttachmentType.qrCode)
  }

  setOnlineFormUrl = (url: string) => {
    this._onlineFormUrl = url
  }

  setOnlineFormUrlLocalError = (error: string) => {
    this._onlineFormUrlLocalError = error
  }

  checkEmailValidation = (url: string) => {
    const isWebsite = isLink(url)
    const errorText = 'Please enter a valid url (e.g. https://www.example.com/'

    if (!isWebsite) {
      this.setOnlineFormUrlLocalError(errorText)
    }
  }

  setSimilarityDomainsWarning = (error: string) => {
    this._similarityDomainsWarning = error
  }

  checkEmailSimilarity = (url: string) => {
    const { website_url } = this.complianceSettingsStore.businessInformation || {}

    if (!website_url) return

    const isSimilar = isUrlDomainsSimilar(website_url, url)

    if (!isSimilar) {
      this.setSimilarityDomainsWarning(
        "It looks like your Online Form URL domain doesn't match your business website domain"
      )
    }
  }

  checkEmail = (url: string) => {
    this.setSimilarityDomainsWarning('')
    this.setOnlineFormUrlLocalError('')

    this.checkEmailValidation(url)

    const isWebsite = isLink(url)

    if (isWebsite) {
      this.checkEmailSimilarity(url)
    }
  }

  setNoPublishedOnlineFormUrl = () => {
    this._noPublishedOnlineFormUrl = !this.noPublishedOnlineFormUrl
  }

  handleNoPublishedOnlineFormUrl = () => {
    if (!this.noPublishedOnlineFormUrl) {
      this._onlineFormUrl = ''
      this._onlineFormUrlLocalError = ''
    }

    this.setNoPublishedOnlineFormUrl()
  }

  setTermsAndPrivacyAgreement = () => {
    this._termsAndPrivacyAgreement = !this.termsAndPrivacyAgreement
  }
}
