// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tKcnC72y6_9Pwm4f9nEq{margin-bottom:20px}.ASNMTqlynA2j_DLAJPJg{position:absolute;top:24px;left:18px}.JRAms5bD0bNljBRmi2G3{flex:1;display:flex;align-items:center;justify-content:space-between;margin-left:8px}.JRAms5bD0bNljBRmi2G3 .NbUo_kbU6o9MGJ7f8c1G{font-size:11px;color:var(--yellow-100);border-radius:4px;padding:0 4px;border:1px solid var(--black-10);background:var(--yellow-20)}", "",{"version":3,"sources":["webpack://./src/pages/settings/layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,SAAA,CAGF,sBACE,MAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CAEA,4CACE,cAAA,CACA,uBAAA,CACA,iBAAA,CACA,aAAA,CACA,gCAAA,CACA,2BAAA","sourcesContent":[".menuGroup {\n  margin-bottom: 20px;\n}\n\n.collapseButtonContainer {\n  position: absolute;\n  top: 24px;\n  left: 18px;\n}\n\n.knowledgeBaseLabel {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-left: 8px;\n\n  .badge {\n    font-size: 11px;\n    color: var(--yellow-100);\n    border-radius: 4px;\n    padding: 0 4px;\n    border: 1px solid var(--black-10);\n    background: var(--yellow-20);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuGroup": "tKcnC72y6_9Pwm4f9nEq",
	"collapseButtonContainer": "ASNMTqlynA2j_DLAJPJg",
	"knowledgeBaseLabel": "JRAms5bD0bNljBRmi2G3",
	"badge": "NbUo_kbU6o9MGJ7f8c1G"
};
export default ___CSS_LOADER_EXPORT___;
