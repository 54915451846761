import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { cloneDeep } from 'lodash'
import { layoutStore, PageLayoutStore } from 'shared/layout'
import { uiStore } from 'shared/store/uiStore'
import type {
  ICallHistory,
  IParamsGetCallHistory,
  IParamsGetCallHistoryStatistic,
  IResponseCallHistory,
} from 'entities/CallHistory/api/types'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { CallHistoryApi } from 'entities/CallHistory/api/callHistory'
import { inboxesStore } from 'entities/Inbox'
import type { IReadMessage } from 'entities/Conversation/api/types'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import type { IResponseEventInboxCalling } from 'entities/Inbox/api/types'
import { ConversationRoutesEnum } from 'entities/Conversation'
import { FiltersStore } from 'widgets/CallHistoryDropdownFilter'
import { CallHistoryFiltersStore } from 'widgets/CallHistoryFilters'
import { CallHistoryTableStore } from 'widgets/CallHistoryTable'
import type { CallModalStore } from 'widgets/CallModal'
import type { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'
import type { IOpenConversationDetails } from 'widgets/ContactsDetails'

export class CallHistoryStore {
  callHistoryFiltersStore
  callHistoryTableStore
  filtersStore

  constructor(
    private _callModalStore: CallModalStore,
    private _contactsDetailsManageStore: ContactsDetailsManageStore,
    private _pageLayoutStore: PageLayoutStore
  ) {
    makeAutoObservable(this)
    this.callHistoryFiltersStore = new CallHistoryFiltersStore(this.loadCallHistory)
    this.callHistoryTableStore = new CallHistoryTableStore(this)
    this.filtersStore = new FiltersStore()
  }

  // === Reactions ==== ///
  private _disposeReactionParamsWithoutPage: IReactionDisposer | null = null
  private _disposeReactionParamsGetItems: IReactionDisposer | null = null
  private _disposeReactionEventFilters: IReactionDisposer | null = null

  initReactions = () => {
    this.reactionParamsWithoutPage()
    this.reactionParamsGetItems()
    this.reactionEventFilters()
  }
  dispose = () => {
    this._disposeReactionParamsWithoutPage?.()
    this._disposeReactionParamsGetItems?.()
    this._disposeReactionEventFilters?.()
  }

  reactionParamsWithoutPage = () => {
    this._disposeReactionParamsWithoutPage?.()
    this._disposeReactionParamsWithoutPage = reaction(
      () => this.paramsWithoutPage,
      () => {
        this.loading = true
        this.page = 1
      }
    )
  }
  reactionParamsGetItems = () => {
    this._disposeReactionParamsGetItems?.()
    this._disposeReactionParamsGetItems = reaction(
      () => this.paramsGetItems,
      this.loadCallHistory,
      {
        delay: 500,
      }
    )
  }
  reactionEventFilters = () => {
    this._disposeReactionEventFilters?.()
    this._disposeReactionEventFilters = reaction(() => this.eventFilters, this.sendLog)
  }
  // === Reactions-end ==== ///

  page = 1
  limit = 50
  total = 0
  search: string | null = null
  loading = true
  loadingMore = false
  loadingSearch = false
  filter = []
  itemsMap: Map<number, CallHistory> = new Map()
  cancelTokenSource: CancelTokenSource | null = null

  isInfinityList = false
  setIsInfinityList = (value: boolean) => {
    this.isInfinityList = value
  }

  get onOpenContactsDetails(): IOpenConversationDetails {
    return {
      disabled: this._contactsDetailsManageStore.disabled,
      open: (data) => {
        this._contactsDetailsManageStore.onOpenConversationDetails(data, 'call history')
      },
    }
  }

  get callModalStore() {
    return this._callModalStore
  }

  get isLoading() {
    return this.loadingSearch || this.loading
  }

  get isLoadingStatistics() {
    return this.callHistoryFiltersStore.loading
  }

  get isEmptySearchResults() {
    return (
      (Boolean(this.search) ||
        Boolean(this.callHistoryFiltersStore.filtersList.length) ||
        this.group !== 'open') &&
      !this.items.length &&
      !this.loadingSearch &&
      !this.isLoadingStatistics
    )
  }

  get isEmptyState() {
    return (
      !this.loading &&
      !this.isLoadingStatistics &&
      !this.itemsMap.size &&
      !this.search &&
      !Boolean(this.callHistoryFiltersStore.filtersList.length)
    )
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get filtersList() {
    return this.callHistoryFiltersStore.filtersList
  }

  get group() {
    return this.filtersStore.filter?.key
  }

  get groupLabel() {
    return this.filtersStore.filter?.label
  }

  get paramsWithoutPage(): Omit<IParamsGetCallHistory, 'page'> {
    return {
      search: this.search || null,
      limit: this.limit,
      team_id: inboxesStore.currentInboxTypeId,
      team_type: inboxesStore.currentInboxType || 3,
      group: this.group === 'all' ? null : this.group,
      filtersList: this.filtersList,
      sorting: [this.callHistoryTableStore.getSortingParams()],
    }
  }

  get paramsGetItems(): IParamsGetCallHistory {
    return {
      page: this.page,
      ...this.paramsWithoutPage,
    }
  }

  get eventFilters() {
    return {
      outcome: this.callHistoryFiltersStore.activeFilter?.outcome,
      conversation_type: this.group,
    }
  }

  setData = ({ data, meta }: IResponseCallHistory) => {
    this.setItems(data)
    this.total = meta.total
  }

  setItems = (items: ICallHistory[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: ICallHistory) => {
    this.itemsMap.set(item.message.id, new CallHistory(item))
  }

  updateItem = (item: CallHistory) => {
    this.itemsMap.set(item.message.id, item)
  }

  getItem = (id: number) => {
    return this.itemsMap.get(id)
  }

  updateInboxCalling = (data: IResponseEventInboxCalling) => {
    this.items.forEach((item) => {
      if (item.inbox.id === data.team_id) {
        item.updateInboxCalling(data.number)
      }
    })
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  loadCallHistory = async () => {
    if (!this.callHistoryFiltersStore.hasCalls) {
      this.reset()

      return
    }

    try {
      this.initCancelTokenSource()
      this.loading = true
      const { data } = await CallHistoryApi.getCallHistory(this.paramsGetItems, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
      if (!this.isInfinityList || (this.paramsGetItems.page && this.paramsGetItems.page === 1)) {
        this.itemsMap.clear()
      }

      this.setData(data)
      runInAction(() => {
        this.loading = false
        if (this.loadingSearch) this.loadingSearch = false
      })
    } catch (e) {
      runInAction(() => {
        this.loading = e instanceof CanceledError
        if (this.loadingSearch) this.loadingSearch = e instanceof CanceledError
      })
    } finally {
      runInAction(() => {
        this.loadingMore = false
      })
    }
  }

  handleSearch = (key: string) => {
    this.search = key
    this.loadingSearch = true
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }

  markAsReadAllList = () => {
    this.items.forEach((item) => item.makeAsRead())
  }

  setItemToBegin = (item: ICallHistory) => {
    const currentItems = cloneDeep(this.items)
    this.itemsMap.clear()
    this.setItem(item)
    this.setItems(currentItems)
  }

  setAdditionItem = (item: ICallHistory) => {
    if (!this.callHistoryFiltersStore.hasCalls) {
      const params: IParamsGetCallHistoryStatistic = {
        team_id: inboxesStore.currentInboxTypeId,
        team_type: inboxesStore.currentInboxType || 3,
        group: this.filtersStore.filter?.key === 'all' ? null : this.filtersStore.filter?.key,
      }

      this.callHistoryFiltersStore.loadData(params)

      return
    }

    if (this.callHistoryFiltersStore.isActiveAllCalls) {
      this.setItemToBegin(item)

      return
    }

    const { type, outcome } = item.call

    const activeFilter = this.callHistoryFiltersStore.activeFilter
    if (activeFilter?.type === type && outcome === activeFilter.outcome) {
      this.setItemToBegin(item)
    }
  }

  updateCallHistoryByMessageReceivedEvent = (data: ICallHistory) => {
    const { call, message } = data
    const { id, unread } = message

    if (this.getItem(id)) {
      this.setItem(data)
    } else {
      if (unread) {
        this.callHistoryFiltersStore.increaseCount(call)
      }

      this.setAdditionItem(data)
    }
  }

  updateCallHistoryByVoicemailEvent = (data: ICallHistory) => {
    const { call, message } = data
    const { id, unread } = message
    const existingItem = this.getItem(id)

    if (existingItem) {
      if (existingItem.message.unread && existingItem.call.outcome && existingItem.call.type) {
        const item: IReadMessage = {
          id: existingItem.message.id,
          type: 'call',
          direction: existingItem.call.type,
          status: existingItem.call.outcome,
        }

        this.callHistoryFiltersStore.decreaseCount([item])
      }

      this.setItem(data)
    } else {
      this.setAdditionItem(data)
    }

    if (unread) {
      this.callHistoryFiltersStore.increaseCount(call)
    }
  }

  sendLog = () => {
    const outcome = this.callHistoryFiltersStore.isActiveAllCalls
      ? 'all-calls'
      : `${this.callHistoryFiltersStore.activeFilter?.type} - ${this.callHistoryFiltersStore.activeFilter?.outcome}`

    const payload = {
      event_id: 'call_history_filter_used',
      outcome,
      conversation_type: this.groupLabel,
    }

    eventLogStore.logEvent('Call History Filter Used', payload, { groupId: organizationStore.id })
  }

  resetLoadingState = () => {
    this.loading = false
  }

  reset = () => {
    this.itemsMap.clear()
    this.loading = true
    this.loadingMore = false
    this.search = null
    this.page = 1
    this.total = 0
  }

  get isHasMore() {
    return this.total > this.itemsMap.size
  }
  handleLoadMore = () => {
    if (this.loadingMore) return
    this.loadingMore = true
    this.page += 1
  }

  onOpenConversation = (callHistory: CallHistory) => {
    if (layoutStore.isMobileView) {
      uiStore.changeRoute({
        path: `/${ConversationRoutesEnum.conversations}/${ConversationRoutesEnum.calls}/${callHistory.conversation.id}`,
      })
      this._pageLayoutStore.toEndContainer()
    } else {
      this.onOpenContactsDetails.open({
        contactId: callHistory.contact?.id,
        inboxId: callHistory.inbox.id,
        conversationId: callHistory.conversation.id,
      })
    }
  }
}
