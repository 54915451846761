import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { DayjsFormats } from 'shared/lib/dayjs'
import { Icon, NavLinkVue, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { links } from 'shared/constants/links'
import { usersStore } from 'entities/Users'
import { MessageProps } from 'widgets/MessageCard/ui/types'
import styles from './styles.module.scss'
import { TimezoneTooltip } from './TimezoneTooltip'

export const DateContent: FC<MessageProps> = observer((props) => {
  const {
    message: {
      date,
      statusDisplayName,
      sending,
      fromBroadcast,
      stop_on_response,
      is_contact_timezone,
      is_schedule,
      failed_reason,
      isFailed,
      isFailedTrial,
      isDelivered,
      isQueued,
      isOutbound,
      isCreated,
      isSent,
    },
  } = props

  const { user } = usersStore
  const isAdminOrOwnerRole = user?.isAdminOrOwnerRole

  const failedReason = failed_reason?.split('Learn more')[0]

  const labelTooltip = useMemo(() => {
    if (isFailedTrial) {
      if (isAdminOrOwnerRole) {
        return (
          <>
            <Typography
              className={'body-sm-medium'}
              color={'var(--content-primary-tertiary-inverted)'}
              ariaLabel={'FailedReason'}
            >
              You have reached the limit of 10 conversations <br /> on Trial plan. To start a new
              conversation,
              <br /> please upgrade your account.
              <br />
            </Typography>
            <NavLinkVue to={'/settings/billing/overview/upgrade'} withoutStyles>
              <Typography linkLight ariaLabel={'Upgrade'}>
                Upgrade
              </Typography>
            </NavLinkVue>{' '}
          </>
        )
      }
      return (
        <Typography
          className={'body-sm-medium'}
          color={'var(--content-primary-tertiary-inverted)'}
          ariaLabel={'FailedReason'}
        >
          You have reached the limit of 10
          <br /> conversations on Trial plan. Please ask
          <br /> one of your admins to upgrade.
        </Typography>
      )
    }
    return (
      <>
        <Typography
          className={'body-sm-medium'}
          color={'var(--content-primary-tertiary-inverted)'}
          ariaLabel={'FailedReason'}
        >
          {failedReason || ''}
        </Typography>

        <br />
        <a href={links.failedMessages} target={'_blank'} rel='noreferrer'>
          <Typography className={'body-md-medium'} linkLight ariaLabel={'LearnMore'}>
            Learn more
          </Typography>
        </a>
      </>
    )
  }, [isFailedTrial, fromBroadcast, isAdminOrOwnerRole, failedReason])

  const status = useMemo(() => {
    if (isFailed) {
      return <Icon icon={'close'} fontSize={14} />
    }

    if (isDelivered) {
      return <Icon icon={'check3'} fontSize={14} />
    }

    if (is_schedule) {
      return <Icon icon={'clockFastForward'} fontSize={14} />
    }

    if (isQueued || isSent || isCreated) {
      return <Icon icon={'check4'} fontSize={14} />
    }

    return ''
  }, [isFailed, isDelivered, isQueued, is_schedule, isSent, isCreated])

  return (
    <div data-name={'message-time'} className={classNames(styles.date)}>
      {stop_on_response && (
        <Tooltip label={'Cancel on response'}>
          <Icon icon={'messageX'} fontSize={12} tertiary />
        </Tooltip>
      )}
      {is_contact_timezone && (
        <Tooltip label={<TimezoneTooltip message={props.message} />}>
          <Icon icon={'userFilled'} fontSize={12} tertiary />
        </Tooltip>
      )}
      {fromBroadcast && <Icon icon={'broadcasts'} fontSize={12} tertiary />}
      <Tooltip
        label={
          <>
            {fromBroadcast && (
              <Typography variant={'body-md-regular'} tag={'div'} ariaLabel={'BroadcastMessage'}>
                Broadcast message
              </Typography>
            )}
            <Typography
              variant={'body-md-regular'}
              tag={'div'}
              color={fromBroadcast ? 'var(--content-primary-tertiary-inverted)' : undefined}
              ariaLabel={'DayjsFormats'}
            >
              {`${statusDisplayName} ${uiStore.dayjs(date).format(DayjsFormats.full3)}`}
            </Typography>
            {isFailed && labelTooltip}
          </>
        }
      >
        <Typography variant={'text-input-helper'} ariaLabel={'Sending'}>
          {sending ? (
            'Sending...'
          ) : (
            <div className={styles.wrap}>
              {uiStore.dayjs(date).format(DayjsFormats.time)}
              {isOutbound && (
                <div
                  className={classNames(styles.status, {
                    [styles.failed]: isFailed,
                    [styles.delivered]: isDelivered,
                  })}
                >
                  {status}
                </div>
              )}
            </div>
          )}
        </Typography>
      </Tooltip>
    </div>
  )
})
