// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AhnuBk2DR3NrdB76Y9l2{position:relative;display:flex;gap:20px}.djVYIPvQVDxkLz_AvMf3{width:100%}._PT4iId7XwtUxOLomm9f{display:flex;align-items:center;justify-content:center;height:24px;border-radius:6px;background:var(--background-neutral-default)}.AHYcc0XwhXOXXirlHAdw{margin-right:8px}.tS87PzfVzmPfSezj3tsu{margin:0 8px}.ucw2EmWXW0wABqyt84yD{display:block}.AAXbPE_A9tbTNCzR1hvx{display:block;margin-top:4px}", "",{"version":3,"sources":["webpack://./src/shared/ui/QuestionBlock/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,QAAA,CAGF,sBACE,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,iBAAA,CACA,4CAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,YAAA,CAEF,sBACE,aAAA,CAEF,sBACE,aAAA,CACA,cAAA","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  gap: 20px;\n}\n\n.questionBlock {\n  width: 100%;\n}\n\n.number {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 24px;\n  border-radius: 6px;\n  background: var(--background-neutral-default);\n}\n.leftIcon {\n  margin-right: 8px;\n}\n.plusBetweenFlags {\n  margin: 0 8px;\n}\n.header {\n  display: block;\n}\n.headerDesc {\n  display: block;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AhnuBk2DR3NrdB76Y9l2",
	"questionBlock": "djVYIPvQVDxkLz_AvMf3",
	"number": "_PT4iId7XwtUxOLomm9f",
	"leftIcon": "AHYcc0XwhXOXXirlHAdw",
	"plusBetweenFlags": "tS87PzfVzmPfSezj3tsu",
	"header": "ucw2EmWXW0wABqyt84yD",
	"headerDesc": "AAXbPE_A9tbTNCzR1hvx"
};
export default ___CSS_LOADER_EXPORT___;
