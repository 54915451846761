import classNames from 'classnames'
import { ReactNode } from 'react'
import { Alert, Button, Icon, Tooltip, Typography } from 'shared/ui'
import type { IComplianceSettingsStore } from 'entities/Compliance'
import { openIntercom } from 'entities/Intercom'
import { ModalHeader } from './ModalHeader'
import styles from './styles.module.scss'

interface IBusinessProfileModalContentProps {
  editBusinessProfile: () => void
  errorMessage?: string
  statusData: ReactNode
  closeModal: () => void
  complianceSettingsStore: IComplianceSettingsStore
}

export const BusinessProfileModalContent = ({
  errorMessage,
  statusData,
  closeModal,
  editBusinessProfile,
  complianceSettingsStore,
}: IBusinessProfileModalContentProps) => {
  const { businessInformation, pointsOfContact } = complianceSettingsStore

  if (!businessInformation) return null

  const {
    business_type,
    industry,
    website_url,
    social_media_url,
    regions_of_operation,
    business_name,
    registration_number,
    street_address_1,
    country,
  } = businessInformation

  const firstContact = pointsOfContact && pointsOfContact[0]
  const secondContact = pointsOfContact && pointsOfContact[1]

  const renderInfoRow = (label: string, value: React.ReactNode, tooltip?: string) => (
    <>
      <Typography ariaLabel={label} variant='text-input-field' className={styles.label}>
        {label}
        {tooltip && (
          <Tooltip label={tooltip}>
            <Icon icon='question' color='var(--content-neutral-primary)' />
          </Tooltip>
        )}
      </Typography>
      <div className={classNames(styles.value, styles.textValue)}>
        <Typography
          variant='text-input-field-r'
          color='var(--content-primary-primary)'
          ariaLabel={`${label}_value`}
        >
          {value}
        </Typography>
      </div>
    </>
  )

  const formatRegistrationNumber = (value: string) => `${value.slice(0, 2)}-${value.slice(2)}`

  return (
    <div className={styles.modalContentWrap}>
      <ModalHeader header={business_name} statusData={statusData} />

      <div
        className={classNames({
          [styles.topBorder]: !errorMessage,
        })}
      >
        {errorMessage && (
          <Alert
            item={{
              type: 'error',
              desc: errorMessage,
            }}
            actions={
              <>
                <Button
                  text={'Update'}
                  typeBtn={'outlined'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={() => {
                    editBusinessProfile()
                    closeModal()
                  }}
                />
                <Button
                  text={'Learn more'}
                  typeBtn={'text'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={() => {
                    openIntercom()
                    closeModal()
                  }}
                />
              </>
            }
          />
        )}

        <div>
          <Typography ariaLabel='GeneralInfo' variant='body-rg-medium' className={styles.title}>
            General info
          </Typography>
          <div className={styles.grid}>
            {renderInfoRow('Legal business name', business_name)}
            {renderInfoRow(
              'EIN',
              formatRegistrationNumber(registration_number),
              'Employer Identification Number'
            )}
            {renderInfoRow('Business address', street_address_1)}
            {renderInfoRow('Country', country)}
          </div>
        </div>

        <div>
          <Typography ariaLabel='BusinessInfo' variant='body-rg-medium' className={styles.title}>
            Business info
          </Typography>
          <div className={styles.grid}>
            {renderInfoRow('Business type', business_type.name)}
            {renderInfoRow('Business industry', industry.name)}
            {renderInfoRow(
              'Business website URL',
              website_url ? (
                <a href={website_url} target='_blank' rel='noreferrer'>
                  {website_url}
                </a>
              ) : (
                '-'
              )
            )}
            {renderInfoRow(
              'Social media profile',
              social_media_url ? (
                <a href={social_media_url} target='_blank' rel='noreferrer' className={styles.link}>
                  {social_media_url}
                </a>
              ) : (
                '-'
              )
            )}
            {renderInfoRow(
              'Business regions of operations',
              <div className={styles.regionsWrap}>
                {regions_of_operation.map(({ name }) => (
                  <span className={styles.region} key={name}>
                    {name}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <Typography ariaLabel='BusinessInfo' variant='body-rg-medium' className={styles.title}>
            People to contact
          </Typography>
          <div className={styles.grid}>
            {renderInfoRow('Name', `${firstContact?.first_name} ${firstContact?.last_name}`)}
            {renderInfoRow('Business email', firstContact?.email)}
            {renderInfoRow('Business title', firstContact?.business_title)}
            {renderInfoRow('Phone number', firstContact?.phone_number)}
          </div>
          {secondContact && (
            <>
              <div className={styles.divider} />
              <div className={styles.grid}>
                {renderInfoRow('Name', `${secondContact.first_name} ${firstContact?.last_name}`)}
                {renderInfoRow('Business email', secondContact.email)}
                {renderInfoRow('Business title', secondContact.business_title)}
                {renderInfoRow('Phone number', secondContact.phone_number)}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
