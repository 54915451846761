import { observer } from 'mobx-react-lite'

import classNames from 'classnames'
import { Button, Checkbox, Icon, TextFieldWithLabel, Tooltip, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import styles from './styles.module.scss'
import { useLocalNumbersContext } from '../context/LocalNumbersContext'
import { IPlaneTypes } from '../types'

const tooltipDesc = (
  <div className={styles.tooltipDesc}>
    <Typography ariaLabel='tooltipDesc' variant='body-md-regular'>
      SMS segment - 160 characters
    </Typography>
    <Typography ariaLabel='tooltipDesc' variant='body-md-regular'>
      MMS segment - 1600 characters
    </Typography>
  </div>
)

export const Package = observer(() => {
  const { packageStore, stepLoading } = useLocalNumbersContext()
  const {
    activePlaneType,
    businessType,
    stockExchanges,
    stockTicker,
    isNextDisabled,
    emailLocalError,
    verificationEmail,
    activeStockExchange,
    increaseStep,
    checkIsEmail,
    setStockTicker,
    setBusinessType,
    setStockExchange,
    setActivePlaneType,
    setVerificationEmail,
  } = packageStore
  const isStandardPlan = activePlaneType === IPlaneTypes.STANDARD

  return (
    <div className={classNames(styles.contentWrap, styles.package)}>
      <Typography
        variant='body-md-regular'
        ariaLabel='Business_info_line'
        className={styles.pageDesc}
      >
        Select the 10DLC registration package that fits your business. Salesmsg passes through these
        fees at cost with no markup.
        <a
          href={links.localNumbersContainerLearnMoreLink}
          target='_blank'
          rel='noreferrer'
          className={styles.learnMoreLink}
        >
          <Typography
            ariaLabel='TermsAndPrivacy_link'
            variant='body-md-regular'
            color='var(--content-brand-primary)'
            className={styles.learnMoreLink}
          >
            {' Learn more'}
          </Typography>
        </a>
      </Typography>

      <div
        className={classNames(styles.packagePriceItem, styles.packagePriceItemFirst, {
          [styles.packagePriceItemActive]: isStandardPlan,
        })}
        onClick={() => setActivePlaneType(IPlaneTypes.STANDARD)}
      >
        <Checkbox checked={isStandardPlan} />
        <div>
          <Typography ariaLabel='packagePriceItem' variant='body-lg-semibold'>
            Low volume standard
          </Typography>
          <div className={styles.packagePriceItemLine}>
            <Typography
              ariaLabel='packagePriceItem'
              variant='body-md-regular'
              color='var(--content-primary-primary)'
            >
              up to 2,000 SMS / MMS segments per day
            </Typography>
            <Tooltip label={tooltipDesc} placement='top'>
              <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
            </Tooltip>
          </div>
          <div className={styles.packagePriceItemLine}>
            <Typography
              ariaLabel='packagePriceItem'
              variant='body-md-regular'
              color='var(--content-primary-primary)'
            >
              up to 3.75 MPS
            </Typography>
            <Tooltip label='Messages per second' placement='top'>
              <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
            </Tooltip>
          </div>
        </div>
        <Typography ariaLabel='packagePriceItem' variant='body-lg-regular' className={styles.price}>
          $19
        </Typography>
      </div>
      <div
        className={classNames(styles.packagePriceItem, styles.packagePriceItemSecond, {
          [styles.packagePriceItemActive]: !isStandardPlan,
        })}
        onClick={() => setActivePlaneType(IPlaneTypes.LOW_VOLUME_STANDARD)}
      >
        <Checkbox checked={!isStandardPlan} />
        <div>
          <div className={styles.packagePriceItemLine}>
            <Typography ariaLabel='packagePriceItem' variant='body-lg-semibold' tag='div'>
              Standard
            </Typography>
            <div className={styles.recommendedLabel}>Recommended</div>
          </div>
          <Typography
            ariaLabel='packagePriceItem'
            variant='body-md-regular'
            color='var(--green-90)'
            tag='div'
            marginBottom={16}
          >
            {'82% of companies your size use the "Standard" package'}
          </Typography>
          <div className={styles.packagePriceItemLine}>
            <Typography
              ariaLabel='packagePriceItem'
              variant='body-md-regular'
              color='var(--content-primary-primary)'
              tag='div'
              marginBottom={4}
            >
              up to 600,000 SMS / MMS segments per day
            </Typography>
            <Tooltip label={tooltipDesc} placement='top'>
              <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
            </Tooltip>
          </div>
          <div className={styles.packagePriceItemLine}>
            <Typography
              ariaLabel='packagePriceItem'
              variant='body-md-regular'
              color='var(--content-primary-primary)'
            >
              up to 225 MPS
            </Typography>
            <Tooltip label='Messages per second' placement='top'>
              <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
            </Tooltip>
          </div>
        </div>
        <Typography ariaLabel='packagePriceItem' variant='body-lg-regular' className={styles.price}>
          $59
        </Typography>
      </div>

      <SelectField
        withSearch={false}
        btnProps={{
          size: 'large',
        }}
        labelProps={{
          label: {
            text: 'Business type',
          },
        }}
        value={businessType}
        setValue={setBusinessType}
        items={[
          { id: 1, label: 'Private' },
          { id: 2, label: 'Public' },
        ]}
      />

      {businessType === 2 && (
        <div>
          <div className={styles.dataLine}>
            <SelectField
              boxWidth='500px'
              withSearch={false}
              btnProps={{
                size: 'extraLarge',
              }}
              labelProps={{
                label: {
                  text: 'Stock exchange',
                },
              }}
              value={activeStockExchange}
              setValue={setStockExchange}
              items={stockExchanges}
            />
            <TextFieldWithLabel
              textFieldProps={{
                value: stockTicker,
                onChange: setStockTicker,
                InputProps: {
                  placeholder: 'e.g. XYZD',
                },
              }}
              labelProps={{ label: { text: 'Company Stock Ticker Symbol' } }}
            />
          </div>
          <TextFieldWithLabel
            textFieldProps={{
              value: verificationEmail,
              onChange: setVerificationEmail,
              onBlur: checkIsEmail,
              InputProps: {
                placeholder: 'e.g. adam.johnson@example.com',
              },
            }}
            errorMessage={emailLocalError}
            labelProps={{ label: { text: 'Business verification email' } }}
            bottomLabelProps={{
              label: {
                text: 'Make sure to check this email, as the business verification letter will be sent to it',
              },
            }}
          />
        </div>
      )}

      <Button
        fullWidth
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        className={styles.nextButton}
        text='Next'
        onClick={increaseStep}
      />
    </div>
  )
})
