import React from 'react'
import classNames from 'classnames'
import { Button } from 'shared/ui/Button/ui/Button'
import styles from '../styles.module.scss'
import { useCopyToClipboard } from '../useCopyToClipboard'

type IButtonCopyProps = {
  value: string | number | undefined
  show?: boolean
}

export const ButtonCopy: React.FC<IButtonCopyProps> = ({ value, show = true }) => {
  const { onCopy } = useCopyToClipboard(value)

  return (
    <div
      className={classNames(styles.rightIcon, {
        [styles.hidden]: !show,
      })}
    >
      <Button
        icon='copy'
        text='Copy'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={onCopy}
      />
    </div>
  )
}
