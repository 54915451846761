import { ReactNode, useState } from 'react'
import { Collapse as CollapseMui } from '@mui/material'
import classNames from 'classnames'
import { Icon } from '../Icon/Icon'
import styles from './styles.module.scss'

export interface ICollapseProps {
  isOpen?: boolean
  children: ReactNode
  title: string
  type?: 'withHover' | 'init'
  leftContent?: (isOpen: boolean) => ReactNode
}

export const Collapse = ({ children, isOpen = true, title, leftContent, type }: ICollapseProps) => {
  const [open, setOpen] = useState(isOpen)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.header, {
          [styles.withHover]: type === 'withHover',
          [styles.isOpen]: open,
        })}
        onClick={handleClick}
      >
        {leftContent?.(open)}
        <span className={styles.title}>{title}</span>
        <Icon
          icon={open ? 'chevronUp' : 'chevronDown'}
          color='var(--gray-60)'
          className={styles.toggleIcon}
        />
      </div>

      <CollapseMui in={open}>
        <div className={styles.collapseContent}>{children}</div>
      </CollapseMui>
    </div>
  )
}
