export enum WorkflowStatus {
  Active = 1,
  Inactive = 0,
}

export type IWorkflowUser = {
  id: number
  organization_id: number
}

export interface IBaseWorkflowData {
  name: string
  description: string
  multiple_executions: boolean
  definition: object
  ui_settings: { data: { children: object[] } } | null
}

export interface IFullWorkflowData extends IBaseWorkflowData {
  id: string
  user: IWorkflowUser
  enabled: boolean
  executions_count: number
  source_type: string
  created_at: string
  updated_at: string | null
}

export type IPreviewWorkflowData = Omit<IFullWorkflowData, 'ui_settings' | 'definition'>

export interface IParamsWorkflowList {
  preview?: boolean
  source_type?: 'core' | 'chatbot'
  'filters[status]'?: WorkflowStatus
  'filters[name]'?: string | null
  'page[pointer]'?: number
  'page[size]'?: number
}

export interface IResponseWorkflowListMeta {
  pagination: {
    hasPages: boolean
    isFirst: boolean
    items: number
    total: number
    pointer: {
      previous: number | null
      next: number | null
      current: number
    }
  }
  status: number
}

export interface IResponseWorkflowList<
  Payload extends IFullWorkflowData | IPreviewWorkflowData = IFullWorkflowData
> {
  payload: Payload[]
  meta: IResponseWorkflowListMeta
}

export interface IResponseWorkflowStatus {
  status: number
}

export interface IResponseWorkflowCreate {
  payload: IFullWorkflowData
  meta: IResponseWorkflowStatus
}

export interface IResponseWorkflowUpdate {
  payload: IFullWorkflowData
  meta: IResponseWorkflowStatus
}

export interface IResponseWorkflowEnable {
  payload: true
  meta: IResponseWorkflowStatus
}

export interface IResponseWorkflowDisable {
  payload: true
  meta: IResponseWorkflowStatus
}

export interface IResponseWorkflowDelete {
  payload: true
  meta: IResponseWorkflowStatus
}

export interface IParamsEnrollIntoWorkflow {
  contact_ids: number[]
  apply_trigger_filters: boolean
}

export interface IResponseEnrollCheck {
  status: string
}

export type IWorkflowExecutionState =
  | 'created'
  | 'running'
  | 'succeeded'
  | 'failed'
  | 'cancelled'
  | 'terminated'
  | 'crashed'

export type IWorkflowSourceType = 'core' | 'chatbot'

export interface IWorkflowExecution {
  id: string
  state: IWorkflowExecutionState
  conversation_ids: number[]
  workflow_id: string
  workflow_name: string
  workflow_source_type: IWorkflowSourceType
}

export interface IWorkflowExecutionPageRequest {
  size: number
  pointer: number
}

export interface IWorkflowPaginationPointer {
  previous: number | null
  next: number | null
  current: number
}

export interface IWorkflowPagination {
  hasPages: boolean
  isFirst: boolean
  items: number
  total: number
  pointer: IWorkflowPaginationPointer
}

export interface IWorkflowExecutionRequest {
  page: IWorkflowExecutionPageRequest
  state?: IWorkflowExecutionState
  workflow_source_type?: IWorkflowSourceType
}

export interface IWorkflowExecutionResponse {
  payload: IWorkflowExecution[]
  meta: {
    status: number
    pagination: IWorkflowPagination
  }
}

export interface IWorkflowTerminateExecutionResponse {
  payload: IWorkflowExecution
  meta: {
    status: number
  }
}
