// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZcjyLRTPkwExEHPUrEgM{display:flex;align-items:center;margin-bottom:16px;gap:10px}.ods4Y0lbYVSmYXzxx2TU{flex:1}.ods4Y0lbYVSmYXzxx2TU>input{font-size:16px;font-style:normal;font-weight:400;line-height:100%;height:24px}.oZ74x3Nrj9ihdW7iyHut{display:flex;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBaseView/ui/DocumentsList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,MAAA,CAEA,4BACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,WAAA,CAIJ,sBACE,YAAA,CACA,WAAA","sourcesContent":[".searchContainer {\n  display: flex;\n  align-items: center;\n  margin-bottom: 16px;\n  gap: 10px;\n}\n\n.search {\n  flex: 1;\n\n  & > input {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 100%;\n    height: 24px;\n  }\n}\n\n.table {\n  display: flex;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": "ZcjyLRTPkwExEHPUrEgM",
	"search": "ods4Y0lbYVSmYXzxx2TU",
	"table": "oZ74x3Nrj9ihdW7iyHut"
};
export default ___CSS_LOADER_EXPORT___;
