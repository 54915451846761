import { makeAutoObservable, reaction, IReactionDisposer, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { eventEmitterStore } from 'entities/EventEmitter'
import { type Contact } from 'entities/Contacts/model/Contact'
import { CreateContactErrors, CreateContactHandler } from 'entities/Contacts/errorHandler'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { openConversationByContact } from 'features/OpenConversation'
import {
  ContactsDetailsStore,
  type IContactsDetailsManageStoreConfig,
  type IOpenContactsDetailsData,
  type IOpenContactsDetailsSource,
  type IOpenConversationsDetailsData,
} from 'widgets/ContactsDetails'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store/contactsDetailsGlobalStore'
import { type ContactsTableStore } from 'widgets/ContactsTable'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export class ContactsDetailsManageStore {
  private _contactsDetailsStore: ContactsDetailsStore | null = null
  private _disposeContactsDetailsStore: IReactionDisposer | null = null
  private _disabled = false
  public emitOpenConversationSignal: (() => void) | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setConfig = (config: IContactsDetailsManageStoreConfig) => {
    this.emitOpenConversationSignal = config.emitOpenConversationSignal || null
  }

  init = () => {
    this.initEvents()
  }

  reset = () => {
    this._disposeContactsDetailsStore?.()
    this._contactsDetailsStore?.reset()
    this._contactsDetailsStore = null
    eventEmitterStore.unsubscribe('deleteContactsDetails', this.onManageContactsDetails)
  }

  initEvents = () => {
    eventEmitterStore.subscribe('deleteContactsDetails', this.onManageContactsDetails)
  }

  onManageContactsDetails = () => {
    if (this._contactsDetailsStore?.isVariantConversations) {
      this._contactsDetailsStore?.contactsDetailsGlobalStore.handleChangeCollapse(true)
      return
    }
    this.onDeleteContactsDetails()
  }

  onDeleteContactsDetails = () => {
    this._contactsDetailsStore?.reset()
    this._contactsDetailsStore?.contactsDetailsGlobalStore.reset()
    this._contactsDetailsStore = null
  }

  setDisabled = (value: boolean) => {
    this._disabled = value
  }

  existContact = async (contactId?: number, conversationId?: number) => {
    try {
      if (!contactId && conversationId) {
        const conversation = await conversationStore.getById({
          id: conversationId,
        })

        contactId = conversation?.contact_id
      }

      const contact = contactId ? await contactsStore.getById(contactId, true) : undefined

      return contact
    } catch (e) {
      console.log(e)
    }
  }

  // Conversations Call History
  onOpenConversationDetails = async (
    data: IOpenConversationsDetailsData,
    source: IOpenContactsDetailsSource
  ) => {
    runInAction(() => {
      this._disabled = true
    })

    await usersStore.initPromise

    const contact = await this.existContact(data.contactId, data.conversationId)

    if (!contact || !inboxesStore.sharedInboxes.length || usersStore.user?.isShouldRequestAccess) {
      modalStore.addModal({
        disabledOnAllClose: true,
        type: ModalTypeList.INFO,
        title: 'Content unavailable',
        id: 'content_unavailable',
        desc: "We're sorry, but you don't currently have access to this content or it was deleted. Please contact the user who shared the link with you to request access.",
      })

      runInAction(() => {
        this._disabled = false
      })

      return
    }

    const contactId = data.contactId
    const inboxId = data.inboxId
    const conversationId = data.conversationId

    eventLogStore.logEvent(
      'ConvSlideout__opened',
      {
        event_id: 'convslideout_opened',
        from: source,
      },
      {
        groupId: organizationStore.id,
      }
    )

    const sub_source = `conv_slideout_${source.replace(' ', '_')}`

    this._contactsDetailsStore = new ContactsDetailsStore({
      conversationId: conversationId,
      contactId: contactId,
      variant: 'open_conversation',
      profileCardProps: {
        onClickCall: () => {
          eventLogStore.logEvent(
            'ConvSlideout_Call_Made',
            {
              event_id: 'convslideout_call_made',
              from: source,
            },
            {
              groupId: organizationStore.id,
            }
          )
        },
        enableCall: true,
      },
      onDeleteContactsDetails: this.onDeleteContactsDetails,
      emitOpenConversationSignal: () => {
        this.emitOpenConversationSignal?.()
      },
      onCloseContactDetails: this.onDeleteContactsDetails,
      sub_source: sub_source,
      headerProps: {
        iconHide: 'close',
        textHide: 'Close details',
      },
      notesProps: {
        hideMessageFieldActions: ['ai_assistant'],
      },
    })

    if (conversationId) {
      this._contactsDetailsStore.contactsConversationOpenStore.setConversationId(conversationId)
    }

    if (inboxId) {
      this._contactsDetailsStore.contactsConversationOpenStore.open(inboxId)
    }

    runInAction(() => {
      this._disabled = false
    })
  }

  reactionConversationsDetailsStore = (
    conversationNewSearchStore: ConversationNewSearchStore,
    conversationMessagesStore: ConversationMessagesStore
  ) => {
    this._disposeContactsDetailsStore?.()
    this._disposeContactsDetailsStore = reaction(
      () => ({
        conversation: conversationStore.currentItem,
        contact_id: conversationStore.currentItem?.contact_id,
      }),
      ({ conversation }) => {
        if (this._contactsDetailsStore?.isVariantOpenConversations) return
        if (!conversation) {
          this._contactsDetailsStore?.reset()
          this._contactsDetailsStore = null

          return
        }

        const contact = contactsStore.getItem(conversation.contact_id)
        const variant = 'conversation'

        this._contactsDetailsStore = new ContactsDetailsStore({
          conversation,
          profileCardProps: {
            enableCall: true,
          },
          contact,
          variant,
          conversationNewSearchStore: conversationNewSearchStore,
          conversationMessagesStore: conversationMessagesStore,
        })
      },
      {
        fireImmediately: true,
      }
    )
  }

  // Contacts Table
  onOpenContactDetails = async (
    data: IOpenContactsDetailsData,
    source: IOpenContactsDetailsSource,
    checkInbox?: boolean,
    contactsTableStore?: ContactsTableStore
  ) => {
    runInAction(() => {
      this._disabled = true
    })

    await usersStore.initPromise
    const contact = await this.existContact(data.contactId)

    if (
      !contact ||
      (checkInbox && !data.inboxId) ||
      !inboxesStore.sharedInboxes.length ||
      usersStore.user?.isShouldRequestAccess
    ) {
      modalStore.addModal({
        disabledOnAllClose: true,
        type: ModalTypeList.INFO,
        title: 'Content unavailable',
        id: 'content_unavailable',
        desc: "We're sorry, but you don't currently have access to this content or it was deleted. Please contact the user who shared the link with you to request access.",
      })

      runInAction(() => {
        this._disabled = false
      })

      return
    }

    const contactId = data.contactId
    const inboxId = data.inboxId

    eventLogStore.logEvent(
      'ConvSlideout__opened',
      {
        event_id: 'convslideout_opened',
        from: source,
      },
      {
        groupId: organizationStore.id,
      }
    )

    const sub_source = `conv_slideout_${source.replace(' ', '_')}`
    const onUpdateContact = contactsTableStore?.updateContactLocally

    this._contactsDetailsStore = new ContactsDetailsStore({
      contactId: contactId,
      inboxId: inboxId,
      variant: 'open_conversation',
      profileCardProps: {
        onClickCall: () => {
          eventLogStore.logEvent(
            'ConvSlideout_Call_Made',
            {
              event_id: 'convslideout_call_made',
              from: source,
            },
            {
              groupId: organizationStore.id,
            }
          )
        },
        enableCall: true,
      },
      onDeleteContactsDetails: this.onDeleteContactsDetails,
      onCloseContactDetails: this.onDeleteContactsDetails,
      onUpdateContact: onUpdateContact,
      sub_source: sub_source,
      headerProps: {
        iconHide: 'close',
        textHide: 'Close details',
      },
      notesProps: {
        hideMessageFieldActions: ['ai_assistant'],
      },
    })

    this._contactsDetailsStore.contactsConversationOpenStore.open()

    runInAction(() => {
      this._disabled = false
    })
  }

  reactionContactsDetailsStore = (contactsTableStore: ContactsTableStore) => {
    this._disposeContactsDetailsStore?.()
    this._disposeContactsDetailsStore = reaction(
      () => contactsTableStore.activeContact,
      (contact) => {
        if (!contact) {
          this._contactsDetailsStore?.reset()
          this._contactsDetailsStore = null

          return
        }

        const variant = 'contacts'
        const onUpdateContact = contactsTableStore.updateContactLocally
        const onCloseContactDetails = () => {
          contactsTableStore.setActiveContact(null)
          contactsDetailsGlobalStore.handleChangeCollapse(true)
        }
        const onStopCreateNewContact = contactsTableStore.resetNewContactMode
        const addNewContactLocallyAfterCreate = (
          contact: Contact,
          params: { isNew?: boolean } = {}
        ) => {
          contactsTableStore.changePaginationToFirst()

          if (contact.isDisabled) {
            CreateContactHandler[CreateContactErrors.trialLimit]()
            contactsTableStore.resetNewContactMode()
            contactsDetailsGlobalStore.handleToggleCollapse()
          }

          if (!contact.isDisabled) {
            contactsTableStore.addNewContactLocallyAfterCreate(contact, params)
          }
        }

        this._contactsDetailsStore = new ContactsDetailsStore({
          contact,
          variant,
          profileCardProps: {
            onClickMessage: (contact: Contact) => {
              contactsTableStore.setLoading(true)
              openConversationByContact(contact).then(() => contactsTableStore.setLoading(false))
            },
            enableChat: true,
          },
          onUpdateContact,
          onCloseContactDetails,
          onStopCreateNewContact,
          addNewContactLocallyAfterCreate,
          contactsTableStore: contactsTableStore,
          notesProps: {
            hideMessageFieldActions: ['ai_assistant'],
          },
        })
      },
      {
        fireImmediately: true,
      }
    )
  }

  get contactsDetailsStore() {
    return this._contactsDetailsStore
  }

  get disabled() {
    return this._disabled
  }
}
